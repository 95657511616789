// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Excellence protection item
 * @export
 * @interface ExcellenceProtectionItem
 */
export interface ExcellenceProtectionItem {
    /**
     * 
     * @type {string}
     * @memberof ExcellenceProtectionItem
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof ExcellenceProtectionItem
     */
    tsProductId: string;
    /**
     * 
     * @type {number}
     * @memberof ExcellenceProtectionItem
     */
    protectedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ExcellenceProtectionItem
     */
    netPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ExcellenceProtectionItem
     */
    protectionDurationDays: number;
}

export function ExcellenceProtectionItemFromJSON(json: any): ExcellenceProtectionItem {
    return ExcellenceProtectionItemFromJSONTyped(json, false);
}

export function ExcellenceProtectionItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcellenceProtectionItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'tsProductId': json['tsProductId'],
        'protectedAmount': json['protectedAmount'],
        'netPrice': json['netPrice'],
        'protectionDurationDays': json['protectionDurationDays'],
    };
}

export function ExcellenceProtectionItemToJSON(value?: ExcellenceProtectionItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'tsProductId': value.tsProductId,
        'protectedAmount': value.protectedAmount,
        'netPrice': value.netPrice,
        'protectionDurationDays': value.protectionDurationDays,
    };
}

