import React from 'react';
import Head from 'next/head';
import { buildRobotoFontCSS } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { useIntl } from 'react-intl';
import { getRobotsDefinition } from './getRobotsDefinition';
import { ParsedUrlQuery } from 'querystring';
import { googleTagManager } from 'helpers/urls';
import { useRouter } from 'next/router';
import { useStore } from 'context/store';
import { determineProtocol } from 'helpers/url-mapper';
import helios from 'fonts/helios';
import { Review } from 'reviews-api';
import { generateJsonLd } from 'helpers/jsonLdGenerator';
import { reviewsStateIs } from 'helpers/reviewStateIs';
import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';
import { getGradeName } from 'helpers/getGradeName';
import {
  getCanonicalHref,
  getCanonicalMemberHref
} from 'helpers/getCanonicalHref';
import { getPaginationMetaLinks } from 'helpers/paginationMetaLinksGenerator';

export function CompanyProfileHead({
  queryParams,
  reviews
}: {
  queryParams: ParsedUrlQuery;
  reviews: Review[];
}) {
  const router = useRouter();
  const {
    host,
    profile,
    activeFilters,
    pageCount,
    isMemberProfile,
    isNonMemberProfile
  } = useStore();
  const { formatMessage, formatNumber } = useIntl();
  const metaRobotsContent = getRobotsDefinition(queryParams);
  const homeUrl = `${determineProtocol()}://${host}`;
  const pageFullUrl = `${homeUrl}${router.asPath}`;
  const canonicalHref =
    isMemberProfile && profile.tsId && profile.targetMarket !== 'ESP'
      ? getCanonicalMemberHref(router.asPath, homeUrl, profile.tsId)
      : getCanonicalHref(router.asPath, homeUrl);
  const ratingCount = profile.reviewStatistic?.yearlyReviewCount;
  const formattedRatingCount = formatNumber(ratingCount);
  const formattedAverageRating = formatNumber(profile.reviewStatistic?.grade, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const formattedMessageValues = {
    companyName: profile.name,
    ratingCount: formattedRatingCount,
    average: formattedAverageRating,
    classification: profile.reviewStatistic?.grade
      ? formatMessage({
          id: `COMPANYPROFILES.RATINGS.SUMMARY.LABEL_${getGradeName(
            profile.reviewStatistic?.grade
          )}`
        })
      : ''
  };
  const appId = '511509218944445';
  let noWidgetTranslationAddon = '';
  let reviewAmountAddon = '';
  let profileTypeAddon = '';
  const isSummaryInvisible = reviewsStateIs(
    profile,
    ReviewsVisibilityState.SummaryIsInvisible
  );

  if (isNonMemberProfile) {
    profileTypeAddon = '_NMP';
  }

  if (
    (isSummaryInvisible && !isNonMemberProfile) ||
    ratingCount === 0 ||
    !ratingCount
  ) {
    reviewAmountAddon = '_0REV';
  }

  if (ratingCount === 1) {
    reviewAmountAddon = '_1REV';
  }

  const paginationMetaLinksData = getPaginationMetaLinks({
    maxPage: pageCount,
    activePage: activeFilters.page,
    pageFullUrl
  });
  const nextPageHref = paginationMetaLinksData?.nextPageHref;
  const prevPageHref = paginationMetaLinksData?.previousPageHref;

  return (
    <Head>
      <title>
        {formatMessage(
          {
            id: `COMPANYPROFILES.META.TITLE${
              reviewAmountAddon === '' || !reviewAmountAddon
                ? ''
                : profileTypeAddon
            }${reviewAmountAddon}`
          },
          formattedMessageValues
        )}
      </title>
      <meta name="robots" content={metaRobotsContent} />
      <meta
        name="description"
        content={formatMessage(
          {
            id: `COMPANYPROFILES.META.DESCRIPTION${profileTypeAddon}${reviewAmountAddon}`
          },
          formattedMessageValues
        )}
      />
      <meta
        property="og:site_name"
        content={formatMessage(
          {
            id: `COMPANYPROFILES.META.OG_SITE_NAME${noWidgetTranslationAddon}`
          },
          formattedMessageValues
        )}
      />
      <meta
        property="og:description"
        content={formatMessage(
          {
            id: `COMPANYPROFILES.META.DESCRIPTION${profileTypeAddon}${reviewAmountAddon}`
          },
          formattedMessageValues
        )}
      />
      <meta property="fb:app_id" content={appId} />
      <meta property="og:type" content="trustedshops:reviewprofile" />
      <meta property="og:url" content={canonicalHref} />
      <meta
        property="og:title"
        content={formatMessage(
          {
            id: `COMPANYPROFILES.META.TITLE${
              reviewAmountAddon === '' || !reviewAmountAddon
                ? ''
                : profileTypeAddon
            }${reviewAmountAddon}`
          },
          formattedMessageValues
        )}
      />
      {profile.logoUrl && (
        <meta property="og:image" content={profile.logoUrl} />
      )}
      {prevPageHref && <link rel="prev" href={prevPageHref} />}
      {nextPageHref && <link rel="next" href={nextPageHref} />}
      <link rel="canonical" href={canonicalHref} />
      <link rel="preconnect" href={googleTagManager} />
      <style dangerouslySetInnerHTML={{ __html: buildRobotoFontCSS() }} />
      <style dangerouslySetInnerHTML={{ __html: helios }} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: generateJsonLd({
            profile,
            reviews,
            pageFullUrl,
            homeUrl
          })
        }}
      />
    </Head>
  );
}
