import React from 'react';
import { Category } from 'components/Category/Category';
import { Categories as CategoriesStyled } from 'components/CompanyProfile/Categories/Categories.styles';
import { CATEGORIES } from 'services/data-test-attributes';
import { Keyword, Category as CategoryType } from 'profile-api';

export const Categories = ({
  allCategories
}: {
  allCategories: (CategoryType | Keyword)[];
}) => {
  return (
    <CategoriesStyled data-test={CATEGORIES}>
      <div>
        {allCategories &&
          allCategories.map((category) => {
            return <Category key={category.label} category={category} />;
          })}
      </div>
    </CategoriesStyled>
  );
};
