import React, { useEffect, useState } from 'react';
import {
  ReportText,
  ReviewMeta as ReviewMetaStyled,
  ReviewReport
} from 'components/Review/ReviewMeta/ReviewMeta.styles';
import { ReviewLike } from 'components/ReviewLike/ReviewLike';
import { Review } from 'reviews-api';
import { ReviewLikeType } from 'like-api';
import { MODAL_DIALOGUE, REVIEW_REPORT } from 'services/data-test-attributes';
import {
  Icon,
  Modal
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { useIntl } from 'react-intl';
import { ReportReviewInfoTooltip } from 'components/ReviewArea/ReportModal/ReportReviewWizard/ReportReviewInfoTooltip/ReportReviewInfoTooltip';
import dynamic from 'next/dynamic';
import { UserType } from 'models/ReportAReview';

const ReportModal = dynamic(
  () => import('components/ReviewArea/ReportModal/ReportModal'),
  { ssr: false }
);

const ReviewMeta = ({
  review,
  justifyContent = 'left',
  isTopFlop,
  isReportPreview = false
}: {
  review: Review;
  justifyContent?: 'left' | 'space-between';
  isTopFlop?: boolean;
  isReportPreview?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportReviewReasonTitle, setReportReviewReasonTitle] = useState('');
  const [userType, setUserType] = useState('');

  function handleContinue(data: any) {
    setReportReviewReasonTitle(data.reportReviewReasonTitle);
    setUserType(data.userType);
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const companyHeader = document.getElementById('animated-company-header');
      const swipperWrapper: HTMLDivElement | any =
        document.getElementsByClassName('swiper-wrapper')[0];

      if (isModalOpen && companyHeader && swipperWrapper) {
        companyHeader.style.zIndex = '0';
        swipperWrapper.style.transform = 'none';
      } else if (!isModalOpen && companyHeader && swipperWrapper) {
        companyHeader.style.zIndex = '50';
        swipperWrapper.style.transform = 'translate3d(0px,0,0)';
      }
    }

    if (!isModalOpen) {
      setReportReviewReasonTitle('');
      setUserType('');
    }
  }, [isModalOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isReportPreview) return null;

  return (
    <>
      <ReviewMetaStyled justifyContent={justifyContent}>
        <ReviewLike review={review} likeType={ReviewLikeType.Review} />
        <ReviewReport
          onClick={() => {
            setIsModalOpen(true);
          }}
          data-test={REVIEW_REPORT}
        >
          <Icon iconName={'icon-action-report'} iconSize={'22px'} />
          {!isTopFlop && (
            <ReportText>
              {formatMessage({
                id: 'COMPANYPROFILES.REPORT_RATING.REPORT_REVIEW_CTA'
              })}
            </ReportText>
          )}
        </ReviewReport>
      </ReviewMetaStyled>
      <Modal
        setModal={setIsModalOpen}
        hasHeadingInfoTooltip={!!reportReviewReasonTitle && !!userType}
        headingInfoTooltipContent={
          <ReportReviewInfoTooltip userType={userType as UserType} />
        }
        title={reportReviewReasonTitle}
        fixedBottomElements={[]}
        open={isModalOpen}
        dataTestValue={MODAL_DIALOGUE}
      >
        {isModalOpen && (
          <ReportModal
            onContinue={handleContinue}
            review={review}
            closeModal={closeModal}
          />
        )}
      </Modal>
    </>
  );
};

export default ReviewMeta;
