export const classificationMapper: Map<
  1 | 2 | 3 | 4 | 5,
  'one' | 'two' | 'three' | 'four' | 'five'
> = new Map([
  [1, 'one'],
  [2, 'two'],
  [3, 'three'],
  [4, 'four'],
  [5, 'five']
]);
