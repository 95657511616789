export const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_12656_3765)">
        <path
          d="M23.625 0H4.375C1.95875 0 0 1.95875 0 4.375V23.625C0 26.0412 1.95875 28 4.375 28H23.625C26.0412 28 28 26.0412 28 23.625V4.375C28 1.95875 26.0412 0 23.625 0Z"
          fill="#1877F2"
        />
        <path
          d="M21 14C21 10.15 17.85 7 14 7C10.15 7 7 10.15 7 14C7 17.5 9.5375 20.3875 12.8625 20.9125V16.0125H11.1125V14H12.8625V12.425C12.8625 10.675 13.9125 9.7125 15.4875 9.7125C16.275 9.7125 17.0625 9.8875 17.0625 9.8875V11.6375H16.1875C15.3125 11.6375 15.05 12.1625 15.05 12.6875V14H16.975L16.625 16.0125H14.9625V21C18.4625 20.475 21 17.5 21 14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12656_3765">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
