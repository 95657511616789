import { minWidth } from 'helpers/buildMediaQueries';
import { css } from 'styled-components';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const selectDropdown = css`
  max-width: calc(100% - 20px);
  right: 0;

  ${minWidth('xs', 'max-width: 350px;')}

  a,
  a:not(:hover),
  a:hover {
    color: ${colorsVariables.BLACK};
  }
`;
