import React from 'react';
import { useIntl } from 'react-intl';
import { StatusText } from 'components/Review/ReviewProvider/ReviewProvider.styles';
import { Review, ReviewVerificationStatusEnum } from 'reviews-api';

export const ImportedProvider = ({ review }: { review: Review }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {review?.verificationStatus === ReviewVerificationStatusEnum.External ? (
        <StatusText>
          {formatMessage({ id: 'COMPANYPROFILES.RATINGS.SINGLE.IMPORTED' })}
        </StatusText>
      ) : null}
    </>
  );
};
