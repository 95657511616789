import React from 'react';
import { useIntl } from 'react-intl';
import { CertificateState as CertificateStateStyled } from './CertificateState.styles';
import { CertificationState } from 'models/CertificationState';
import { useCertification } from 'context/certification';

export default function CertificateState({ stateId }: any) {
  const { formatMessage } = useIntl();
  const { isCertificateProduction } = useCertification();

  if (!stateId) {
    return null;
  }

  const certificationState = CertificationState[stateId].toUpperCase();

  return (
    <CertificateStateStyled isValid={isCertificateProduction}>
      <span>
        {certificationState === 'PRODUCTION'
          ? formatMessage({
              id: 'COMPANYPROFILES.CERTIFICATE.STATE.PRODUCTION'
            })
          : formatMessage({
              id: 'COMPANYPROFILES.CERTIFICATE.SHOP_STATE.NOT_CERTIFIED'
            })}
      </span>
    </CertificateStateStyled>
  );
}
