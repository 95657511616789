import {
  RadioButton as RadioButtonStyled,
  RadioButtonSelected
} from 'components/RadioButton/RadioButton.styles';

export const RadioButton = ({ isActive }: { isActive: boolean }) => {
  return (
    <RadioButtonStyled className="cur-pointer" isActive={isActive}>
      {isActive && <RadioButtonSelected />}
    </RadioButtonStyled>
  );
};
