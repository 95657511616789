/**
 * In our interaction and visual regression tests, we used to use selectors like [class*="__ButtonWithDropdown"]
 * to query styled components having class names like "LoginButtonDesktopstyles__ButtonWithDropdown-sc-18kjeem-0".
 * This is because the styled-components Babel plugin has been configured with "displayName: true",
 * which is the case for all components in @trustedshops/tps-seo-shared-frontend-react-components.
 *
 * Alas, in the new Rust-based Next.js compiler, displayName is "disabled in production to reduce file size"
 * (see issue and my comment https://github.com/vercel/next.js/issues/30802#issuecomment-1044708250),
 * so that all our class names are cryptic.
 * Therefore, we need to add our own properties for testing (data-test="..."), and insert them when executing our VRTs,
 * see https://nextjs.org/docs/advanced-features/compiler#remove-react-properties.
 */

export const SORT_ORDER_LINK = 'sort-order-link';
export const REPORT_REVIEW_NEXT_STEP_BUTTON = 'report-review-next-step-button';
export const REPORT_REVIEW_USER_B2C_OPTION = 'report-review-user-b2c-option';
export const REPORT_REVIEW_USER_B2B_OPTION = 'report-review-user-b2b-option';
export const REVIEW_FILTER_SELECTOR = 'review-filter-selector';
export const REVIEW_FILTERS_ACTIVE = 'review-filters-active';
export const REVIEW_FILTER_SELECTOR_BOX = 'review-filter-selector-box';
export const REVIEW_FILTER_SECTION = 'review-filter-section';
export const REVIEW_SORT_SELECTOR = 'review-sort-selector';
export const REVIEW_SORT_INFO_TOOL_TIP = 'review-sort-info-tool-tip';
export const REVIEW_SORT_SELECTOR_BOX = 'review-sort-selector-box';
export const REVIEW_AREA = 'review-area';
export const DATE_AGO = 'date-ago';
export const VERIFICATION_TOOLTIP = 'verification-tooltip';
export const REVIEW_REPORT = 'review-report';
export const RATING_SUMMARY_AREA = 'rating-summary-area';
export const REVIEW_LIKE = 'review-like';
export const MODAL_DIALOGUE = 'modal-dialogue';
export const MODAL_DIALOGUE_OPENER = 'modal-dialogue-opener';
export const ABOUT_EXPANDER = 'about-expander';
export const CERTIFICATE_INFO = 'certificate-info';
export const EMAIL_TOOLTIP = 'email-tooltip';
export const CONTACT_AREA = 'contact-area';
export const PROFILE_TOOLTIP = 'profile-tooltip';
export const PROFILE_HEADER_AREA = 'profile-header-area';
export const RATING_SUMMARY_TOOLTIP = 'rating-summary-tooltip';
export const ALL_REVIEWS_BUTTON = {
  top: 'all-top-reviews',
  flop: 'all-flop-reviews'
};
export const CATEGORIES = 'categories';
export const COMPANY_LINK = 'company-link';
export const REVIEW = 'review';
export const ACCEPT_COOKIES_BUTTON = 'accept-cookies-button';
export const REVIEW_AUTHENTICITY_TEASER = 'review-authenticity-teaser';
export const MAIN_CONTENT_WRAPPER = 'main-content-wrapper';
export const SUBMIT_REVIEW = 'submit-review';
export const SUBMIT_REVIEW_STAR = 'submit-review-star';
