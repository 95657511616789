export function addHttpPrefix(value?: string): string {
  if (!value) {
    return '';
  }

  if (
    !value.toLowerCase().startsWith('http:') &&
    !value.toLowerCase().startsWith('https:')
  ) {
    return `//${value}`;
  }

  return value;
}
