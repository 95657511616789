import styled from 'styled-components';
import tsfont from '../../../../tsfont.json';
import {
  colorsVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const SortOrderLabel = styled.label`
  display: flex;
  align-items: center;
  & > * {
    display: inline;
  }

  ${tsfont.templateOptions.baseSelector}:before {
    margin-right: ${gutterVariables.GUTTER_ONE_QUARTER};
    color: ${colorsVariables.CURACAO};
  }
`;
