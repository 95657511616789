import React from 'react';
import { Trustmark } from '@trustedshops/tps-seo-shared-frontend-react-components';
import CertificateState from 'components/CertificateInfo/CertificateState/CertificateState';
import {
  CertificateHeader as CertificateHeaderStyled,
  ContentWrapper,
  CertificateStateTag,
  CertificateInfoHeading,
  CertificateInfoSubheading,
  ChevronArrow,
  CertificateInfoHeadingText,
  CertificateInfoHeadingWrapper
} from './CertificateHeader.styles';
import { useIntl } from 'react-intl';
import { useStore } from 'context/store';
import { ProfileWithOverrides } from 'models/ProfileOverrides';
import { useCertification } from 'context/certification';

export const CertificateHeader = ({
  detailsExpanded = false
}: {
  detailsExpanded?: boolean;
}) => {
  const { formatMessage, locale } = useIntl();
  const { profile } = useStore();
  const { isCertificateProduction } = useCertification();
  const { certificateFor } = profile as ProfileWithOverrides;

  return (
    <CertificateHeaderStyled>
      <ContentWrapper>
        {isCertificateProduction && <Trustmark />}
        <div>
          <CertificateInfoHeadingWrapper
            hasMarginBottom={isCertificateProduction}
          >
            <CertificateInfoHeading as="h2" lookAlike="h3">
              <CertificateInfoHeadingText>
                {certificateFor?.[locale] ||
                  formatMessage({ id: 'COMPANYPROFILES.CERTIFICATE.HEADING' })}
              </CertificateInfoHeadingText>
              <CertificateStateTag>
                <CertificateState
                  stateId={profile.memberDetails?.certificationStateId}
                />
              </CertificateStateTag>
            </CertificateInfoHeading>
          </CertificateInfoHeadingWrapper>
          {isCertificateProduction && (
            <CertificateInfoSubheading>
              {formatMessage({
                id: 'COMPANYPROFILES.CERTIFICATE.WITH_BUYER_PROTECTION'
              })}
            </CertificateInfoSubheading>
          )}
        </div>
      </ContentWrapper>
      <ChevronArrow
        iconName={'icon-arrow-chevron-down'}
        detailsExpanded={detailsExpanded}
        iconSize={'24px'}
      />
    </CertificateHeaderStyled>
  );
};
