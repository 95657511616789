import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Context,
  Size
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { Review } from 'reviews-api';
import { ReviewLikeType } from 'like-api';
import {
  ReviewLike as ReviewLikeStyled,
  PlusOneLikeWrapper
} from './ReviewLike.styles';
import {
  TotalLikeCount as TotalLikeCountComponent,
  TotalLikeCountWrapper
} from './TotalLikeCount';
import { useStore } from 'context/store';
import { ReviewLikeService } from 'services/ReviewLikeService';
import { globalEnvironment } from 'helpers/global-environment';
import { useModal } from 'context/modal';
import { ModalTypes } from 'models/ModalTypes';
import { REVIEW_LIKE } from 'services/data-test-attributes';
import { TotalLikeCount } from 'models/TotalLikeCount';
import dynamic from 'next/dynamic';

const PlusOneLike = dynamic(
  () => import('components/ReviewLike/PlusOneLike/PlusOneLike'),
  { ssr: false }
);

/**
 * This class name is for Google Tag Manager:
 * If a review like button is immediately visible (happens mostly on Desktop),
 * we need to load Usercentrics immediately, so that the user can like the review.
 */
const REVIEW_LIKE_BUTTON_CLASS_NAME = 'review-like';

export function ReviewLike({
  review,
  likeType
}: {
  review: Review;
  likeType: ReviewLikeType;
}) {
  const {
    profile,
    environment,
    reviewLikeFunctionalityEnabled,
    totalLikeCounts,
    setTotalLikeCounts
  } = useStore();
  const { setModal } = useModal();
  const [totalLikeCount, setTotalLikeCount] = useState(
    new TotalLikeCount(review, likeType).get()
  );
  const [userHasLikedReview, setUserHasLikedReview] = useState(false);
  const reviewLikeService = new ReviewLikeService(environment);
  const [userLikedReviewClick, setUserLikedReviewClick] = useState(false);
  const timeUntilAnimationDoneInMs = 2000;

  useEffect(() => {
    const [totalLikeCountOnClient, userHasLikedReviewOnClient] =
      totalLikeCounts[review.id][likeType];
    setTotalLikeCount(totalLikeCountOnClient);
    setUserHasLikedReview(userHasLikedReviewOnClient);
  }); // TODO: Add [totalLikeCounts, review.id, likeType] if errors are still appearing in Sentry

  /* istanbul ignore next: has been covered by interaction tests */
  async function onLikeClick() {
    if (!reviewLikeFunctionalityEnabled) {
      setModal(
        reviewLikeService.userCentricsAvailable()
          ? ModalTypes.ReviewCookieHintModal
          : ModalTypes.UserCentricsBlockedModal
      );
      return;
    }

    if (userHasLikedReview) {
      return;
    }

    if (
      !globalEnvironment.environmentsWithReviewLikeFunctionality.includes(
        environment
      ) &&
      !globalEnvironment.testShopIds.includes(profile.tsId!)
    ) {
      const exampleTestShopId = globalEnvironment.testShopIds?.length
        ? ` (ie: ${globalEnvironment.testShopIds[0]})`
        : '';
      console.warn(
        'Warn: You just liked the review, but no changes are made in the like API nor visually. ' +
          'Please use a test shop id if you want to test the saving functionality' +
          exampleTestShopId +
          '.'
      );
      return;
    }

    const success = await reviewLikeService.like(review, likeType);
    setTotalLikeCounts({
      ...totalLikeCounts,
      [review.id]: {
        ...totalLikeCounts[review.id],
        [likeType]: [new TotalLikeCount(review, likeType).get(), success]
      }
    });
    setUserLikedReviewClick(true);

    setTimeout(
      () => setUserLikedReviewClick(false),
      timeUntilAnimationDoneInMs
    );
  }

  return (
    <ReviewLikeStyled
      type="button"
      context={Context.Tertiary}
      size={Size.Small}
      onClick={
        /* istanbul ignore next: has been covered by interaction tests */
        () => onLikeClick()
      }
      disabled={reviewLikeFunctionalityEnabled && userHasLikedReview}
      data-test={REVIEW_LIKE}
      suppressHydrationWarning
      className={REVIEW_LIKE_BUTTON_CLASS_NAME}
    >
      <FormattedMessage
        id="COMPANYPROFILES.REVIEW.LIKE.HELPFUL"
        tagName="span"
      />
      {totalLikeCount > 0 && (
        <TotalLikeCountWrapper>
          <TotalLikeCountComponent
            disabled={reviewLikeFunctionalityEnabled && userHasLikedReview}
            suppressHydrationWarning
          >
            {totalLikeCount}
          </TotalLikeCountComponent>
          <PlusOneLikeWrapper>
            {userLikedReviewClick && (
              <PlusOneLike userHasLikedReview={userLikedReviewClick} />
            )}
          </PlusOneLikeWrapper>
        </TotalLikeCountWrapper>
      )}
    </ReviewLikeStyled>
  );
}
