import styled from 'styled-components';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { StarCountStyles } from 'components/ReviewArea/ReviewFilterSelector/StarCount.styles';

export const FilterEntry = styled.div`
  white-space: nowrap;

  &.disabled {
    pointer-events: none;

    label:before {
      background-color: ${colorsVariables.GRAY_150};
      border-color: ${colorsVariables.GRAY_150};
    }

    ${StarCountStyles} {
      color: ${colorsVariables.GRAY_200};
    }
  }

  label {
    cursor: pointer;
  }
`;

export const RatingStarsWrapper = styled.div`
  display: inline-block;
  margin-left: 0.5rem;
`;
