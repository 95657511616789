import { ReactNode } from 'react';
import {
  SocialMediaItem as SocialMediaItemStyled,
  SocialMediaLink
} from './SocialMediaItem.styles';

export const SocialMediaItem = ({
  icon,
  label,
  url
}: {
  icon: ReactNode;
  label: string;
  url: string;
}) => {
  return (
    <SocialMediaItemStyled>
      <a href={url} target={'_blank'}>
        {icon}
      </a>
      <SocialMediaLink href={url} target={'_blank'}>
        {label}
      </SocialMediaLink>
    </SocialMediaItemStyled>
  );
};
