import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  CompanyDescription,
  CompanyName,
  ExternalLinkAsButton,
  GoToCompanyCTAText,
  ExternalLinkIcon,
  ShowMoreShowLessButton
} from 'components/CompanyProfile/CompanyDetails.styles';
import { useStore } from 'context/store';
import { addHttpPrefix } from 'helpers/addHttpPrefix';
import { Profile } from 'profile-api';
import { Context } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { COMPANY_LINK } from 'services/data-test-attributes';
import { SocialMedia } from 'components/CompanyProfile/SocialMedia/SocialMedia';

function getValidDescription(profile: Profile) {
  if (!profile.description) {
    return null;
  }

  const description = profile.description.trim();
  if (!description || description.length < 4) {
    return null;
  }
  return description;
}

const CompanyDetails = () => {
  const { formatMessage } = useIntl();
  const { profile } = useStore();
  const validDescription = getValidDescription(profile);
  const [isCompanyDescriptionExpanded, setIsCompanyDescriptionExpanded] =
    useState(false);
  const [isShowMoreButtonDisplayed, setIsShowMoreButtonDisplayed] =
    useState(true);

  const companyDescriptionRef = useCallback((node: any) => {
    if (node !== null) {
      const isTextClamed = node.scrollHeight > node.clientHeight;

      setIsShowMoreButtonDisplayed(isTextClamed);
    }
  }, []);

  return (
    <>
      <CompanyName as="h3">
        {formatMessage(
          { id: 'COMPANYPROFILES.MAIN.DETAILS' },
          { companyName: profile.name }
        )}
      </CompanyName>
      {validDescription && (
        <>
          <CompanyDescription
            isExpanded={isCompanyDescriptionExpanded}
            isShowMoreButtonDisplayed={isShowMoreButtonDisplayed}
            ref={companyDescriptionRef}
          >
            <span>{validDescription}</span>
          </CompanyDescription>
          {isShowMoreButtonDisplayed && (
            <ShowMoreShowLessButton
              onClick={() =>
                setIsCompanyDescriptionExpanded(!isCompanyDescriptionExpanded)
              }
            >
              {isCompanyDescriptionExpanded ? (
                <span>
                  {formatMessage({ id: 'REVIEW.SHOW_LESS_BUTTON.LABEL' })}
                </span>
              ) : (
                <span>
                  {formatMessage({ id: 'REVIEW.SHOW_MORE_BUTTON.LABEL' })}
                </span>
              )}
            </ShowMoreShowLessButton>
          )}
        </>
      )}
      {profile.url && (
        <ExternalLinkAsButton
          data-test={COMPANY_LINK}
          context={Context.Secondary}
          href={addHttpPrefix(profile.url)}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <GoToCompanyCTAText>
            {formatMessage({
              id: 'COMPANYPROFILES.DETAILS.VISIT_WEBSITE.CTA_LABEL'
            })}
          </GoToCompanyCTAText>
          <ExternalLinkIcon
            iconName="icon-action-open-in-new-tab"
            iconSize="22px"
          />
        </ExternalLinkAsButton>
      )}
      {profile.socialMediaLinks && profile.socialMediaLinks.length > 0 && (
        <SocialMedia />
      )}
    </>
  );
};

export default CompanyDetails;
