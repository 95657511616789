import { AllowedCompanyProfileQueryParams } from 'helpers/AllowedCompanyProfileQueryParams';
import { ParsedUrlQuery } from 'querystring';

export function getRobotsDefinition(queryParams: ParsedUrlQuery): string {
  function isIndexable() {
    const currentQueryParams = Object.keys(queryParams);
    for (const queryParam in AllowedCompanyProfileQueryParams) {
      if (
        [
          AllowedCompanyProfileQueryParams.language,
          AllowedCompanyProfileQueryParams.profile,
          AllowedCompanyProfileQueryParams.id
        ].includes(queryParam as AllowedCompanyProfileQueryParams)
      ) {
        continue;
      }

      if (currentQueryParams.includes(queryParam)) {
        return false;
      }
    }

    return true;
  }

  return [isIndexable() ? 'index' : 'noindex', 'follow'].join(',');
}
