import React from 'react';
import { Stars } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { useIntl } from 'react-intl';
import { ReviewFilter } from 'models/ReviewFilter';
import { Checkbox } from './Checkbox.styles';
import {
  FilterEntry as FilterEntryStyled,
  RatingStarsWrapper
} from './FilterEntry.styles';
import { StarCountStyles } from 'components/ReviewArea/ReviewFilterSelector/StarCount.styles';
import { useStore } from 'context/store';
import { MappedReviewCountsForFilters } from 'helpers/getMappedReviewCountsForFilters';

export function FilterEntry({
  filter,
  onClick
}: {
  filter: ReviewFilter;
  onClick: () => void;
}) {
  const { formatNumber } = useIntl();
  const { activeFilters, reviewCountsForFilters } = useStore();
  const isDisabled =
    activeFilters.searchTerm && reviewCountsForFilters
      ? reviewCountsForFilters[
          filter.value as unknown as keyof MappedReviewCountsForFilters
        ] === 0
      : filter.reviewCountForFilter <= 0;

  return (
    <FilterEntryStyled
      className={isDisabled ? 'disabled' : ''}
      onClick={onClick}
    >
      <Checkbox
        id={`stars-value-${filter.value}`}
        type="checkbox"
        checked={filter.selected}
        value={filter.value}
        name="ratingClassification"
        readOnly
      />
      <label
        htmlFor={'stars-value-' + filter.value}
        className={filter.selected ? 'hls hls-icon-feedback-check' : ''}
      >
        <RatingStarsWrapper>
          <Stars filledValue={filter.value} isDisabled={isDisabled} />
        </RatingStarsWrapper>
        {activeFilters.searchTerm && reviewCountsForFilters ? (
          <StarCountStyles>{`(${formatNumber(
            reviewCountsForFilters[
              filter.value as unknown as keyof MappedReviewCountsForFilters
            ] || 0
          )})`}</StarCountStyles>
        ) : (
          <StarCountStyles>{`(${formatNumber(
            filter.reviewCountForFilter || 0
          )})`}</StarCountStyles>
        )}
      </label>
    </FilterEntryStyled>
  );
}
