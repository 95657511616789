// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CertificationType,
    CertificationTypeFromJSON,
    CertificationTypeFromJSONTyped,
    CertificationTypeToJSON,
    ExcellenceProtectionItem,
    ExcellenceProtectionItemFromJSON,
    ExcellenceProtectionItemFromJSONTyped,
    ExcellenceProtectionItemToJSON,
    ReviewWidgetState,
    ReviewWidgetStateFromJSON,
    ReviewWidgetStateFromJSONTyped,
    ReviewWidgetStateToJSON,
} from './';

/**
 * Member details
 * @export
 * @interface MemberDetails
 */
export interface MemberDetails {
    /**
     * 
     * @type {ReviewWidgetState}
     * @memberof MemberDetails
     */
    reviewState: ReviewWidgetState;
    /**
     * 
     * @type {number}
     * @memberof MemberDetails
     */
    certificationStateId: number;
    /**
     * 
     * @type {CertificationType}
     * @memberof MemberDetails
     */
    certificationType: CertificationType;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDetails
     */
    hasShopConsumerMembership: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberDetails
     */
    maxProtectionAmount: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDetails
     */
    maxProtectionCurrency: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDetails
     */
    maxProtectionDuration: number;
    /**
     * 
     * @type {Array<ExcellenceProtectionItem>}
     * @memberof MemberDetails
     */
    excellenceProtectionItems: Array<ExcellenceProtectionItem> | null;
    /**
     * 
     * @type {number}
     * @memberof MemberDetails
     */
    validFrom: number;
}

export function MemberDetailsFromJSON(json: any): MemberDetails {
    return MemberDetailsFromJSONTyped(json, false);
}

export function MemberDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviewState': ReviewWidgetStateFromJSON(json['reviewState']),
        'certificationStateId': json['certificationStateId'],
        'certificationType': CertificationTypeFromJSON(json['certificationType']),
        'hasShopConsumerMembership': json['hasShopConsumerMembership'],
        'maxProtectionAmount': json['maxProtectionAmount'],
        'maxProtectionCurrency': json['maxProtectionCurrency'],
        'maxProtectionDuration': json['maxProtectionDuration'],
        'excellenceProtectionItems': (json['excellenceProtectionItems'] === null ? null : (json['excellenceProtectionItems'] as Array<any>).map(ExcellenceProtectionItemFromJSON)),
        'validFrom': json['validFrom'],
    };
}

export function MemberDetailsToJSON(value?: MemberDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviewState': ReviewWidgetStateToJSON(value.reviewState),
        'certificationStateId': value.certificationStateId,
        'certificationType': CertificationTypeToJSON(value.certificationType),
        'hasShopConsumerMembership': value.hasShopConsumerMembership,
        'maxProtectionAmount': value.maxProtectionAmount,
        'maxProtectionCurrency': value.maxProtectionCurrency,
        'maxProtectionDuration': value.maxProtectionDuration,
        'excellenceProtectionItems': (value.excellenceProtectionItems === null ? null : (value.excellenceProtectionItems as Array<any>).map(ExcellenceProtectionItemToJSON)),
        'validFrom': value.validFrom,
    };
}

