import React from 'react';
import {
  ReviewAnswer as ReviewAnswerStyled,
  CompanyStatement
} from './ReviewAnswer.styles';
import { Review } from 'reviews-api';
import { ReviewLike } from 'components/ReviewLike/ReviewLike';
import { ReviewLikeType } from 'like-api';
import { ReviewMeta } from '../ReviewMeta/ReviewMeta.styles';
import { ReviewAnswerHeader } from 'components/Review/ReviewAnswer/ReviewAnswerHeader/ReviewAnswerHeader';

export const ReviewAnswer = ({
  review,
  isLikeFunctionalityActive = true
}: {
  review: Review;
  isLikeFunctionalityActive?: boolean;
}) => {
  return (
    <ReviewAnswerStyled className="answer-right">
      <ReviewAnswerHeader review={review} />
      <div>
        <CompanyStatement>{review.reply?.comment}</CompanyStatement>
        {isLikeFunctionalityActive && (
          <ReviewMeta justifyContent="space-between">
            <ReviewLike review={review} likeType={ReviewLikeType.Shopanswer} />
          </ReviewMeta>
        )}
      </div>
    </ReviewAnswerStyled>
  );
};
