import React, { ReactNode } from 'react';
import { ReviewArea as ReviewAreaStyled } from 'components/ReviewArea/ReviewArea.styles';
import { useStore } from 'context/store';
import { reviewsStateIs } from 'helpers/reviewStateIs';
import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';
import { ReviewResponse } from 'reviews-api';
import { TopFlopServerResponse } from 'top-flop-api';
import { REVIEW_AREA } from 'services/data-test-attributes';
import { ReviewAreaWithReviews } from 'components/ReviewArea/ReviewAreaWithReviews/ReviewAreaWithReviews';

const ReviewArea = ({
  reviews,
  topFlopReviews,
  children
}: {
  reviews?: ReviewResponse;
  topFlopReviews: TopFlopServerResponse;
  children?: ReactNode;
}) => {
  const { profile } = useStore();
  const profileHasReviews = reviewsStateIs(
    profile,
    ReviewsVisibilityState.ReviewsListIsVisible
  );

  if (!profileHasReviews) return <></>;

  return (
    <ReviewAreaStyled data-test={REVIEW_AREA}>
      <ReviewAreaWithReviews reviews={reviews} topFlopReviews={topFlopReviews}>
        {children}
      </ReviewAreaWithReviews>
    </ReviewAreaStyled>
  );
};

export default ReviewArea;
