import {
  fontVariables,
  Icon
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import styled from 'styled-components';

export const RotateOnOpen = styled(Icon)<{
  open: boolean;
}>`
  transition: transform 0.3s ease-in-out;
  display: inline-block;

  ${({ open }) => open && 'transform: rotate(180deg);'}

  &&&:before {
    line-height: ${fontVariables.LINE_HEIGHT_S};
  }
`;
