// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CertificationType {
    Classic = 'CLASSIC',
    Excellence = 'EXCELLENCE',
    Sponsorship = 'SPONSORSHIP',
    NoAudit = 'NO_AUDIT'
}

export function CertificationTypeFromJSON(json: any): CertificationType {
    return CertificationTypeFromJSONTyped(json, false);
}

export function CertificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificationType {
    return json as CertificationType;
}

export function CertificationTypeToJSON(value?: CertificationType | null): any {
    return value as any;
}

