import styled from 'styled-components';
import { gutterVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const ReviewAnswer = styled.div`
  display: block;
  margin-top: ${gutterVariables.GUTTER_PLUS_HALF};
  background-color: #f0f3f4;
  padding: ${gutterVariables.GUTTER};
  border-radius: 8px;
`;

export const CompanyStatement = styled.div`
  margin-top: 0.5rem;
  padding-left: ${gutterVariables.GUTTER_PLUS_HALF};
  border-left: 2px solid #18adf3;
`;
