import React, { MouseEventHandler, useRef } from 'react';
import { ReviewTopFlop as ReviewTopFlopStyled } from 'components/ReviewTopFlop/ReviewTopFlop.styles';
import { TopFlopServerResponse } from 'top-flop-api';
import { TopFlopCard } from 'components/ReviewTopFlop/TopFlopCard/TopFlopCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { DeviceSpecificContainer } from 'components/DeviceSpecificContainer/DeviceSpecificContainer';

export const ReviewTopFlop = ({
  topFlopReviews,
  scrollToReviews,
  path
}: {
  topFlopReviews: TopFlopServerResponse;
  scrollToReviews: MouseEventHandler;
  path: string;
}) => {
  const topFlopRef = useRef<HTMLDivElement>(null);
  const isBothAnonymousReviews =
    !topFlopReviews.TopReview?.customer?.firstName &&
    !topFlopReviews.TopReview?.customer?.lastName &&
    !topFlopReviews.FlopReview?.customer?.firstName &&
    !topFlopReviews.FlopReview?.customer?.lastName;

  return (
    <>
      <ReviewTopFlopStyled className="top-flop-section" ref={topFlopRef}>
        <DeviceSpecificContainer
          mobileChildren={
            <Swiper
              slidesPerView={'auto'}
              modules={[Pagination]}
              spaceBetween={16}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <TopFlopCard
                  tooltipId={'top-mobile'}
                  type={'top'}
                  review={topFlopReviews.TopReview!}
                  scrollToReviews={scrollToReviews}
                  path={path}
                  isBothAnonymousReviews={isBothAnonymousReviews}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TopFlopCard
                  tooltipId={'flop-mobile'}
                  type={'flop'}
                  review={topFlopReviews.FlopReview!}
                  scrollToReviews={scrollToReviews}
                  path={path}
                  isBothAnonymousReviews={isBothAnonymousReviews}
                />
              </SwiperSlide>
            </Swiper>
          }
          desktopChildren={
            <>
              <TopFlopCard
                tooltipId={'top-desktop'}
                type={'top'}
                review={topFlopReviews.TopReview!}
                scrollToReviews={scrollToReviews}
                path={path}
                isBothAnonymousReviews={isBothAnonymousReviews}
              />
              <TopFlopCard
                tooltipId={'flop-desktop'}
                type={'flop'}
                review={topFlopReviews.FlopReview!}
                scrollToReviews={scrollToReviews}
                path={path}
                isBothAnonymousReviews={isBothAnonymousReviews}
              />
            </>
          }
          componentRef={topFlopRef}
        />
      </ReviewTopFlopStyled>
    </>
  );
};
