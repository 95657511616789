import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { CopyToClipboard as CopyToClipboardStyled } from './CopyToClipboard.styles';

export const CopyToClipboard = ({
  children,
  textToCopy,
  successMessage,
  uniqueId,
  refForTooltip
}: {
  children: ReactNode;
  textToCopy: string;
  successMessage: string;
  uniqueId: string;
  refForTooltip: any;
}) => {
  const { formatMessage } = useIntl();
  const [tooltipMessage, setTooltipMessage] = useState(
    formatMessage({ id: 'CORE.CONTROLS.CLICK_TO_COPY' })
  );

  const copyToClipboard = async ($event: any, text: string) => {
    $event.preventDefault();

    try {
      // Note: navigator.clipboard requires a secure origin (either HTTPS or localhost)
      await navigator.clipboard.writeText(text);
      setTooltipMessage(formatMessage({ id: successMessage }));
    } catch (e) {
      setTooltipMessage(formatMessage({ id: 'CORE.CONTROLS.COPY_FAILED' }));
      console.error(e);
    }
  };

  const resetText = () => {
    setTooltipMessage(formatMessage({ id: 'CORE.CONTROLS.CLICK_TO_COPY' }));
  };

  return (
    <>
      <Tooltip
        childrenRef={refForTooltip}
        uniqueId={'copy-to-clipboard' + uniqueId}
        content={tooltipMessage}
        minWidthDesktop={'max-content'}
        minWidthMobile={'max-content'}
      >
        <CopyToClipboardStyled
          onClick={(event) => copyToClipboard(event, textToCopy)}
          onMouseLeave={() => {
            resetText();
          }}
        >
          {children}
        </CopyToClipboardStyled>
      </Tooltip>
    </>
  );
};
