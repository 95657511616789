import styled from 'styled-components';
import {
  colorsVariables,
  fontVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { TopOrFlop } from 'models/TopFlopTypes';

export const TopFlopHeader = styled.div<{ topFlop: TopOrFlop }>`
  padding: ${gutterVariables.GUTTER};
  text-align: center;
  border-radius: ${gutterVariables.GUTTER_HALF} ${gutterVariables.GUTTER_HALF} 0
    0;

  background-color: ${(props) =>
    props.topFlop === 'top' ? '#E3FFE8' : '#FFE3E4'};

  h4 {
    font-size: ${fontVariables.SIZE_TEXT_M};
    font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
    line-height: ${fontVariables.LINE_HEIGHT_S};

    color: ${(props) =>
      props.topFlop === 'top'
        ? colorsVariables.GREEN_600
        : colorsVariables.RED_500};
  }
`;
