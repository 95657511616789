/* istanbul ignore file: our visual regression tests are enough to test this file */

import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ReviewInbox } from 'components/Illustrations/ReviewInbox/ReviewInbox.styles';
import { SearchMagnifier } from 'components/Illustrations/SearchMagnifier/SearchMagnifier.styles';
import { useStore } from 'context/store';
import {
  AllProfilesButton,
  AdditionalInfo,
  NoReviewsForShop as NoReviewsForShopStyled,
  Subtitle,
  Title
} from './NoReviewsForShop.styles';
import { Context } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { REVIEW_AREA } from 'services/data-test-attributes';

export const NoReviewsForShop = ({
  children,
  title,
  subtitle,
  hideCTA,
  illustration = 'inbox'
}: {
  children?: ReactNode;
  title: string;
  subtitle?: string;
  hideCTA?: boolean;
  illustration?: 'inbox' | 'magnifier';
}) => {
  const { formatMessage } = useIntl();
  const { profile } = useStore();

  return (
    <div id={REVIEW_AREA}>
      <NoReviewsForShopStyled>
        {illustration === 'inbox' ? <ReviewInbox /> : <SearchMagnifier />}
        <Title as="h4">{title}</Title>
        {subtitle && (
          <AdditionalInfo>
            <Subtitle>{subtitle}</Subtitle>
            {profile?.organization?.hasMoreProfiles && !hideCTA && (
              <AllProfilesButton
                context={Context.Primary}
                target="_blank"
                href={'/company/' + profile?.organization.encodedName}
              >
                {formatMessage({
                  id: 'COMPANYPROFILES.RATINGS.LIST.NO_RATINGS.CTA'
                })}
              </AllProfilesButton>
            )}
          </AdditionalInfo>
        )}
      </NoReviewsForShopStyled>
      {children}
    </div>
  );
};
