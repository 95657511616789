import React from 'react';
import { useIntl } from 'react-intl';
import { CompanyLogo as CompanyLogoStyled } from 'components/CompanyLogo/CompanyLogo.styles';
import { breakpointsVariables } from 'styles/breakpoints.variables';
import { useStore } from 'context/store';

function CompanyLogo({
  hideAfter
}: {
  hideAfter?: keyof typeof breakpointsVariables;
}) {
  const { formatMessage } = useIntl();
  const { profile } = useStore();

  if (!profile.logoUrl) {
    return null;
  }

  return (
    <CompanyLogoStyled hideAfter={hideAfter}>
      <img
        alt={
          profile.name +
          formatMessage({ id: 'COMPANYPROFILES.HEADER.LOGO_ALT_EXTENSION' })
        }
        src={profile.logoUrl}
      />
    </CompanyLogoStyled>
  );
}

export default CompanyLogo;
