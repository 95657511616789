import styled from 'styled-components';
import {
  Heading,
  colorsVariables,
  gutterVariables,
  flexItemsCentered,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';
import { MainBox } from 'components/MainBox/MainBox';

export const SimilarCompanies = styled.div`
  max-width: 100%;

  .swiper {
    overflow: visible;
  }
`;

export const SimilarCompaniesAsideWrapper = styled(MainBox)`
  display: none;

  ${minWidth('xxl', 'display: block; padding: 24px;')};
`;
export const SimilarCompaniesBottomPageWrapper = styled.div`
  grid-area: similar-companies;

  ${minWidth('xxl', 'display: none;')};
`;

export const SimilarCompaniesResultsWrapper = styled.div`
  display: none;

  ${minWidth(
    'md',
    'display: grid; grid-gap: 16px; grid-template-columns: 1fr 1fr;'
  )};

  ${minWidth('xxl', 'grid-template-columns: 1fr;')};
`;

export const SwiperWrapper = styled.div`
  ${minWidth('md', 'display: none;')};

  .swiper-pagination {
    position: initial;
    margin-top: ${gutterVariables.GUTTER};

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      border: 1px solid ${colorsVariables.GRAY_500};
      background: ${colorsVariables.GRAY_050};
    }

    .swiper-pagination-bullet-active {
      background: ${colorsVariables.BLUE_700};
      border: none;
    }
  }
`;

export const SimilarCompaniesHeading = styled(Heading)`
  ${flexItemsCentered};
  margin-bottom: ${gutterVariables.GUTTER};
  font-size: ${fontVariables.SIZE_TEXT_M};
  line-height: ${fontVariables.LINE_HEIGHT_L};
}
`;
