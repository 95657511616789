import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';
import styled from 'styled-components';

export const ReviewDate = styled.span`
  color: ${colorsVariables.GRAY_500};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
