import React, { useEffect, useState } from 'react';

export const DeviceSpecificContainer = ({
  mobileChildren,
  desktopChildren,
  componentRef,
  desktopBreakpoint = 576
}: {
  mobileChildren: JSX.Element;
  desktopChildren: JSX.Element;
  componentRef: any;
  desktopBreakpoint?: number;
}) => {
  const [isMobile, setIsMobile] = useState(false);

  function toggleMobileViewBasedOnWindowSize() {
    if (window.innerWidth < desktopBreakpoint) {
      setIsMobile(true);
      return;
    }

    setIsMobile(false);
  }

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }

    if (window.ResizeObserver) {
      const componentObserver = new ResizeObserver(() => {
        toggleMobileViewBasedOnWindowSize();
      });

      componentObserver.observe(componentRef.current);
    } else {
      window.addEventListener('resize', () => {
        toggleMobileViewBasedOnWindowSize();
      });
    }
  });

  return <>{isMobile ? mobileChildren : desktopChildren}</>;
};
