import {
  TooltipHandleFunctions,
  Tooltip
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import React, { useRef } from 'react';
import { dateAgo } from 'helpers/dateAgo';
import { determineCurrentTime } from 'helpers/determineCurrentTime';
import { useIntl } from 'react-intl';
import { useStore } from 'context/store';
import { Review } from 'reviews-api';
import {
  ReviewAnswerHeader as ReviewAnswerHeaderStyled,
  AnswerFrom,
  ShopStatementDate,
  HeaderContent
} from 'components/Review/ReviewAnswer/ReviewAnswerHeader/ReviewAnswerHeader.styles';
import { transformDateAgo } from 'helpers/transformDateAgo';
import {
  ModificationHistory,
  ModificationHistoryEntry
} from 'components/Review/ReviewProvider/ReviewProvider.styles';

export const ReviewAnswerHeader = ({ review }: { review: Review }) => {
  const { formatMessage, formatDate, locale } = useIntl();
  const { environment, profile } = useStore();
  const tooltipId = `${review.id}-answer`;
  const refForTooltip = useRef<HTMLSpanElement>(null);
  const tooltipComponentRef = useRef<TooltipHandleFunctions>(null);

  return (
    <ReviewAnswerHeaderStyled>
      <HeaderContent>
        <AnswerFrom>
          {formatMessage(
            { id: 'COMPANYPROFILES.RATINGS.SINGLE.ANSWER_BY' },
            { shopname: profile?.name }
          )}
        </AnswerFrom>
        <ShopStatementDate>
          <Tooltip
            uniqueId={tooltipId}
            content={
              <ModificationHistory>
                {review.reply?.createdAt && (
                  <ModificationHistoryEntry>
                    {formatMessage({
                      id: 'COMPANYPROFILES.REVIEW.PUBLISH_DATE'
                    })}{' '}
                    {formatDate(review.reply.createdAt, {
                      dateStyle: 'medium'
                    })}
                  </ModificationHistoryEntry>
                )}
              </ModificationHistory>
            }
            childrenRef={refForTooltip}
            ref={tooltipComponentRef}
          >
            <span ref={refForTooltip} suppressHydrationWarning>
              {transformDateAgo(
                dateAgo(
                  review.reply?.createdAt,
                  determineCurrentTime(environment),
                  locale
                ),
                formatMessage
              )}
            </span>
          </Tooltip>
        </ShopStatementDate>
      </HeaderContent>
    </ReviewAnswerHeaderStyled>
  );
};
