import styled from 'styled-components';
import {
  Heading,
  gutterVariables,
  fontVariables,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const ReviewArea = styled.div`
  display: block;
  grid-area: review-area;
`;

export const SectionIdContainer = styled.div``;

export const ReviewAreaHeading = styled(Heading)`
  margin-bottom: 12px;
  font-size: 23px;
  line-height: 33px;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  color: ${colorsVariables.BLACK};
  word-break: break-word;
`;

export const FilterBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${minWidth('md', `flex-direction: row;`)};
`;

export const SearchBarWrapper = styled.div`
  width: 100%;

  ${minWidth('md', `margin-right: ${gutterVariables.GUTTER};`)};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap-reverse;

  ${minWidth(
    'md',
    'margin-top: 0; width: auto; justify-content: normal; flex-wrap: nowrap;'
  )};
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const FilterSection = styled.div`
  margin-bottom: 20px;

  ${minWidth(
    'md',
    `flex-direction: row; margin-bottom: ${gutterVariables.GUTTER_PLUS_HALF};`
  )};
`;
