import styled from 'styled-components';
import {
  colorsVariables,
  fontVariables,
  gutterVariables,
  shadowsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const TotalLikeCount = styled.span<{ disabled?: boolean }>`
  margin: 0;
  padding: 2px ${gutterVariables.GUTTER_HALF};
  background: ${colorsVariables.BLUE_700};
  color: ${colorsVariables.WHITE};
  box-shadow: ${shadowsVariables.buttonInset};
  border-radius: 10.5px;

  ${({ disabled }) =>
    disabled &&
    `background: ${colorsVariables.GREEN_500};
     color: ${colorsVariables.WHITE};`}
`;

export const TotalLikeCountWrapper = styled.div`
  margin-left: -8px;
  margin-right: ${gutterVariables.GUTTER_HALF};
  font-size: ${fontVariables.SIZE_TEXT_XS};
  font-weight: normal;
  line-height: 1.5;
  position: relative;
`;
