import styled, { css } from 'styled-components';
import {
  fontVariables,
  colorsVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const CertificateState = styled.span<{
  isValid: boolean;
}>`
  border-radius: 3px;
  text-transform: lowercase;
  padding: ${gutterVariables.GUTTER_ONE_QUARTER} 6px;
  white-space: nowrap;
  font-size: ${fontVariables.SIZE_TEXT_XS};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  line-height: ${fontVariables.LINE_HEIGHT_XS};

  * {
    line-height: ${fontVariables.LINE_HEIGHT_XS};
  }

  .hls:before {
    line-height: ${fontVariables.LINE_HEIGHT_XL};
    color: ${colorsVariables.WHITE};
  }

  ${(props) =>
    props.isValid &&
    css`
      background-color: #e3ffe8;
      color: ${colorsVariables.GREEN_600};
    `}

  ${(props) =>
    !props.isValid &&
    css`
      background-color: ${colorsVariables.RED_100};
      color: #bc1417;
    `}
`;
