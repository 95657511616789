import { TopOrFlop } from 'models/TopFlopTypes';
import { Review } from 'top-flop-api';
import { useStore } from 'context/store';
import { useIntl } from 'react-intl';
import { ModalTypes } from 'models/ModalTypes';
import {
  ActionsSection,
  AllReviewsLinkSection,
  BuyerStatement,
  ContentSection,
  ModalContent,
  ModalSeparator,
  ModalShopReplyWrapper,
  ReadMoreLink,
  ReviewCardHeaderWrapper,
  ReviewLikeHelpfulText,
  TopFlopCard as TopFlopCardStyled
} from 'components/ReviewTopFlop/TopFlopCard/TopFlopCard.styles';
import { Separator } from 'components/ReviewTopFlop/ReviewTopFlop.styles';
import { TopFlopHeader } from 'components/ReviewTopFlop/TopFlopHeader/TopFlopHeader';
import ReviewHeader from 'components/Review/ReviewHeader/ReviewHeader';
import {
  ALL_REVIEWS_BUTTON,
  MODAL_DIALOGUE_OPENER,
  MODAL_DIALOGUE
} from 'services/data-test-attributes';
import ReviewMeta from 'components/Review/ReviewMeta/ReviewMeta';
import { buildHref } from 'helpers/buildHref';
import {
  Icon,
  Modal
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import React, { MouseEventHandler, useState } from 'react';
import { getAvailableStarFilters } from 'helpers/getAvailableStarFilters';
import { ReviewAnswer } from 'components/Review/ReviewAnswer/ReviewAnswer';
import { LinkPill } from 'components/LinkPill/LinkPill';
import { ReviewCardHeader } from 'components/Review/ReviewHeader/ReviewHeader.styles';
import AuthorInfo from 'components/Review/AuthorInfo/AuthorInfo';

const topFlopCTATranslationKeys = {
  top: 'COMPANYPROFILES.TOPFLOP.SHOW_ALL_POSITIVE',
  flop: 'COMPANYPROFILES.TOPFLOP.SHOW_ALL_CRITICAL'
};

function showReadMoreLinkForReview(review: Review): boolean {
  return !(
    review.comment &&
    review.comment.length < 100 &&
    !review.reply?.comment
  );
}

export const TopFlopCard = ({
  type,
  review,
  scrollToReviews,
  path,
  tooltipId,
  isBothAnonymousReviews
}: {
  type: TopOrFlop;
  review: Review;
  scrollToReviews: MouseEventHandler;
  path: string;
  tooltipId?: string;
  isBothAnonymousReviews?: boolean;
}) => {
  const { activeFilters, profile, totalLikeCounts } = useStore();
  const { formatMessage } = useIntl();
  const { firstName, lastName } = review.customer || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showReadMore = showReadMoreLinkForReview(review);
  const modalType =
    type === 'top' ? ModalTypes.TopReview : ModalTypes.FlopReview;
  const modalTitleKeyType = type === 'top' ? 'POSITIVE' : 'CRITICAL';

  const availableStarFilters = getAvailableStarFilters(profile, type);
  const likeCountsReview = totalLikeCounts[review.id].review[0];
  const likeCountsShopAnswer = totalLikeCounts[review.id].shopanswer[0];

  return (
    <TopFlopCardStyled isBothAnonymousReviews={isBothAnonymousReviews}>
      <TopFlopHeader topFlop={type} />
      <ContentSection>
        <div>
          <ReviewHeader
            review={review}
            tooltipId={tooltipId}
            fullWidthRating={true}
            providerTooltipPlacement={'right'}
            isTopFlop
          />
          <BuyerStatement>{review.comment}</BuyerStatement>
          {showReadMore && (
            <ReadMoreLink
              className={'text-link'}
              onClick={() => {
                setIsModalOpen(true);
              }}
              data-test={MODAL_DIALOGUE_OPENER + modalType}
            >
              {formatMessage({ id: 'COMPANYPROFILES.TOPFLOP.READ_MORE' })}
            </ReadMoreLink>
          )}
        </div>
        {firstName && lastName && (
          <ReviewCardHeaderWrapper>
            <ReviewCardHeader>
              <AuthorInfo
                review={review}
                isClickable={!!review.customer?.id}
                isTopFlop
              />
            </ReviewCardHeader>
          </ReviewCardHeaderWrapper>
        )}
      </ContentSection>
      <ActionsSection>
        <ReviewMeta
          review={review}
          justifyContent={'space-between'}
          isTopFlop
        />
        <Separator />
        <AllReviewsLinkSection>
          <LinkPill
            href={buildHref(path, {
              ...activeFilters,
              ...{
                stars: availableStarFilters,
                page: undefined
              }
            })}
            scroll={false}
          >
            <span
              data-test={ALL_REVIEWS_BUTTON[type]}
              onClick={scrollToReviews}
              className={'text-link'}
            >
              {formatMessage({ id: topFlopCTATranslationKeys[type] })}
            </span>
            <Icon iconName={'icon-arrow-right'} iconSize={'22px'} />
          </LinkPill>
        </AllReviewsLinkSection>
      </ActionsSection>
      <Modal
        setModal={() => {
          setIsModalOpen(false);
        }}
        title={formatMessage({
          id: `COMPANYPROFILES.TOPFLOP.MOST_RELEVANT_${modalTitleKeyType}`
        })}
        fixedBottomElements={[]}
        open={isModalOpen}
        dataTestValue={MODAL_DIALOGUE}
        paddingForHeadingOnly
      >
        <ModalContent>
          <ReviewHeader
            review={review}
            tooltipId={type}
            dateTooltipPlacement={'right'}
            authorTooltipPlacement={'bottom'}
            isTooltipOnMobile
          />
          <span>{review.comment}</span>
          {!!likeCountsReview && (
            <ReviewLikeHelpfulText>
              {formatMessage(
                {
                  id:
                    'COMPANYPROFILES.REVIEW.LIKE.CUSTOMER_FOUND_HELPFUL.' +
                    (likeCountsReview === 1 ? 'SINGULAR' : 'PLURAL')
                },
                { count: likeCountsReview }
              )}
            </ReviewLikeHelpfulText>
          )}
          {review.reply?.comment && (
            <ModalShopReplyWrapper>
              <ModalSeparator />
              <ReviewAnswer review={review} isLikeFunctionalityActive={false} />
              {!!likeCountsShopAnswer && (
                <ReviewLikeHelpfulText>
                  {formatMessage(
                    {
                      id:
                        'COMPANYPROFILES.REVIEW.LIKE.CUSTOMER_FOUND_HELPFUL.' +
                        (likeCountsShopAnswer === 1 ? 'SINGULAR' : 'PLURAL')
                    },
                    { count: likeCountsShopAnswer }
                  )}
                </ReviewLikeHelpfulText>
              )}
            </ModalShopReplyWrapper>
          )}
        </ModalContent>
      </Modal>
    </TopFlopCardStyled>
  );
};
