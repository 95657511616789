import { Review } from 'reviews-api';
import { reviewsStateIs } from 'helpers/reviewStateIs';
import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';
import { Profile } from 'profile-api';

interface jsonLdStructure {
  '@context': string;
  '@type': string;
  review?: Object;
}

export function generateJsonLd({
  profile,
  reviews,
  pageFullUrl,
  homeUrl
}: {
  profile: Profile;
  reviews: Review[];
  pageFullUrl: string;
  homeUrl: string;
}) {
  let jsonLD: jsonLdStructure = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    ...generateSummaryJsonLd({ profile, pageFullUrl })
  };

  if (
    reviews.length &&
    reviewsStateIs(profile, ReviewsVisibilityState.ReviewsListIsVisible)
  ) {
    jsonLD.review = generateReviewsJsonLd({ profile, reviews, homeUrl });
  }

  return JSON.stringify(jsonLD);
}

function generateSummaryJsonLd({
  profile,
  pageFullUrl
}: {
  profile: Profile;
  pageFullUrl: string;
}) {
  const summaryJsonLd = {
    '@id': pageFullUrl,
    url: profile.url,
    name: profile.name,
    telephone: profile.contactData?.phone,
    image: profile.logoUrl || ''
  };

  const addressObject = {
    address: {
      '@type': 'PostalAddress',
      streetAddress: profile.address?.street || '',
      addressLocality: profile.address?.city || '',
      postalCode: profile.address?.zipCode || '',
      addressCountry: {
        '@type': 'Country',
        name: profile.address?.countryCode || ''
      }
    }
  };

  const aggregateRatingObject = generateAggregateRating(profile);

  return { ...summaryJsonLd, ...addressObject, ...aggregateRatingObject };
}

function generateReviewsJsonLd({
  profile,
  reviews,
  homeUrl
}: {
  profile: Profile;
  reviews: Review[];
  homeUrl: string;
}) {
  let formattedReviews: any[] = [];
  if (!reviews || reviews.length <= 0) {
    return [];
  }

  reviews.forEach((review) => {
    const reviewRatingObject = generateReviewRatingObject(review);
    const itemReviewedObject = generateItemReviewedObject(profile);
    const authorObject = generateAuthorObject(review);

    const reviewJsonLd = {
      '@type': 'review',
      datePublished: new Date(review.createdAt),
      inLanguage: profile.targetMarket,
      reviewBody: review.comment || '',
      publisher: {
        sameAs: homeUrl
      },
      ...authorObject,
      ...reviewRatingObject,
      ...itemReviewedObject
    };

    formattedReviews = [...formattedReviews, reviewJsonLd];
  });

  return formattedReviews;
}

function generateReviewRatingObject(review: Review) {
  if (!review.rating) {
    return;
  }

  return {
    reviewRating: {
      '@type': 'Rating',
      bestRating: '5',
      worstRating: '1',
      ratingValue: review.rating
    }
  };
}

function generateItemReviewedObject(profile: Profile) {
  if (!profile.name) {
    return;
  }

  return {
    itemReviewed: {
      '@type': 'Thing',
      name: profile.name
    }
  };
}

function getAuthorUrl(customerId?: string | null): string {
  if (!customerId) {
    return '';
  }

  const path = 'https://www.trustedshops.com/user/de_DE/';
  return `${path}${customerId}`;
}

export function generateAuthorObject(review: Review) {
  let buyerName: string = '';
  if (review.customer?.firstName && review.customer?.lastName) {
    buyerName = review.customer.firstName + ' ' + review.customer.lastName;
  } else if (review?.customer?.firstName) {
    buyerName = review.customer.firstName;
  } else if (review?.customer?.lastName) {
    buyerName = review.customer.lastName;
  }

  const authorName =
    !review.customer?.id &&
    !review.customer?.firstName &&
    !review.customer?.lastName
      ? 'Anonymous'
      : buyerName;

  return {
    author: {
      '@type': 'Person',
      name: authorName,
      url: getAuthorUrl(review.customer?.id)
    }
  };
}

function generateAggregateRating(profile: Profile) {
  if (!reviewsStateIs(profile, ReviewsVisibilityState.SummaryIsVisible)) {
    return;
  }

  return {
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: '5',
      worstRating: '1',
      ratingValue: profile.reviewStatistic.grade,
      reviewCount: profile.reviewStatistic.yearlyReviewCount
    }
  };
}
