/* istanbul ignore file: has been covered by VRTs */

import { TsIdOrChannelRefGetSortByEnum } from 'reviews-api';
import { useIntl } from 'react-intl';
import { SortOrderLabel as SortOrderLabelStyled } from './SortOrderLabel.styles';
import { SortText } from './SortText.styles';
import { RadioButton } from 'components/RadioButton/RadioButton';

export function SortOrderLabel({
  value,
  activeSort
}: {
  value: TsIdOrChannelRefGetSortByEnum;
  activeSort: TsIdOrChannelRefGetSortByEnum;
}) {
  const { formatMessage } = useIntl();

  return (
    <SortOrderLabelStyled>
      <RadioButton isActive={value === activeSort} />
      <SortText className="cur-pointer">
        {formatMessage({
          id: `COMPANYPROFILES.RATINGS.LIST.SORT_ORDERS.${value.toUpperCase()}`
        })}
      </SortText>
    </SortOrderLabelStyled>
  );
}
