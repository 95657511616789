import { TopOrFlop } from 'models/TopFlopTypes';
import { Profile } from 'profile-api';

export function getAvailableStarFilters(
  profile: Profile,
  reviewType: TopOrFlop
) {
  const availableStarFilters = [];

  if (reviewType === 'top') {
    if (
      profile.reviewStatistic?.fourStarReviewCount &&
      profile.reviewStatistic.fourStarReviewCount > 0
    ) {
      availableStarFilters.push(4);
    }

    if (
      profile.reviewStatistic?.fiveStarReviewCount &&
      profile.reviewStatistic.fiveStarReviewCount > 0
    ) {
      availableStarFilters.push(5);
    }
  }

  if (reviewType === 'flop') {
    if (
      profile.reviewStatistic?.oneStarReviewCount &&
      profile.reviewStatistic.oneStarReviewCount > 0
    ) {
      availableStarFilters.push(1);
    }

    if (
      profile.reviewStatistic?.twoStarReviewCount &&
      profile.reviewStatistic.twoStarReviewCount > 0
    ) {
      availableStarFilters.push(2);
    }

    if (
      profile.reviewStatistic?.threeStarReviewCount &&
      profile.reviewStatistic.threeStarReviewCount > 0
    ) {
      availableStarFilters.push(3);
    }
  }

  return availableStarFilters;
}
