import styled from 'styled-components';
import {
  Button,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const ReviewLike = styled(Button)`
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  font-size: ${fontVariables.SIZE_TEXT};
  height: 32px;
  background-color: #e5e5e5;

  :disabled {
    pointer-events: none;
  }
`;

export const PlusOneLikeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
`;
