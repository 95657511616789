import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { TsIdOrChannelRefGetSortByEnum } from 'reviews-api';
import { SortOrderLabel } from './SortOrderLabel';
import {
  Tooltip,
  useOutsideClick
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { selectDropdown } from './selectDropdown';
import { SortOrderLink } from './SortOrderLink.styles';
import { MainBox } from 'components/MainBox/MainBox';
import { buildHref } from 'helpers/buildHref';
import { FilterableQueryParams } from 'models/FilterableQueryParams';
import {
  REVIEW_SORT_SELECTOR,
  REVIEW_SORT_SELECTOR_BOX,
  SORT_ORDER_LINK
} from 'services/data-test-attributes';
import {
  InfoIcon,
  ReviewSortByText,
  ReviewSortSelector as ReviewSortSelectorStyled,
  ReviewSortSelectorDropdown,
  TooltipBody,
  TooltipContent,
  TooltipHeading
} from 'components/ReviewArea/ReviewSortSelector/ReviewSortSelector.styles';
import DropdownChipOpener from 'components/DropdownChipOpener/DropdownChipOpener';

export function ReviewSortSelector({
  path,
  sortSelectionDefault,
  activeFilters
}: {
  path: string;
  sortSelectionDefault: TsIdOrChannelRefGetSortByEnum;
  activeFilters: FilterableQueryParams;
}) {
  const { formatMessage } = useIntl();
  const [sortingBoxVisible, setSortingBoxVisible] = useState(false);
  const SORT_ORDER_TYPES: TsIdOrChannelRefGetSortByEnum[] = Object.values(
    TsIdOrChannelRefGetSortByEnum
  );
  const SORT_ORDER_NAMES: typeof TsIdOrChannelRefGetSortByEnum =
    TsIdOrChannelRefGetSortByEnum;
  const activeSort = activeFilters.sort || sortSelectionDefault;
  const ref = useRef<HTMLDivElement>(null);
  const refForTooltip = useRef<HTMLSpanElement>(null);

  /* istanbul ignore next: will be covered in VRTs */
  useOutsideClick(ref, () => setSortingBoxVisible(false));

  const headline =
    formatMessage({
      id: 'COMPANYPROFILES.RATINGS.LIST.SORT_ORDER'
    }) +
    ': ' +
    formatMessage({
      id: 'COMPANYPROFILES.RATINGS.LIST.SORT_ORDERS.RELEVANCE'
    });

  return (
    <>
      <ReviewSortSelectorStyled ref={ref}>
        {activeSort === SORT_ORDER_NAMES.Relevance && (
          <>
            <Tooltip
              childrenRef={refForTooltip}
              uniqueId={'sort-selector-info'}
              content={
                <TooltipContent>
                  <TooltipHeading>{headline}</TooltipHeading>
                  <TooltipBody>
                    {formatMessage({
                      id: 'COMPANYPROFILES.RATINGS.LIST.SORT_RELEVANCE_INFO'
                    })}
                  </TooltipBody>
                </TooltipContent>
              }
              placement={'bottom'}
              maxWidthMobile={'200px'}
              maxWidthDesktop={'343px'}
              drawerHeadline={headline}
            >
              <span ref={refForTooltip}>
                <InfoIcon
                  iconName="icon-feedback-info-circle-outline"
                  className="cur-pointer"
                />
              </span>
            </Tooltip>
            <span>&nbsp;</span>
          </>
        )}
        <ReviewSortByText>
          {formatMessage({ id: 'COMPANYPROFILES.RATINGS.LIST.SORT_ORDER' })}
        </ReviewSortByText>
        <span>&nbsp;&nbsp;</span>
        <DropdownChipOpener
          dataTestName={REVIEW_SORT_SELECTOR}
          onClick={() => setSortingBoxVisible(!sortingBoxVisible)}
          label={formatMessage({
            id: `COMPANYPROFILES.RATINGS.LIST.SORT_ORDERS.${activeSort.toUpperCase()}`
          })}
          chevronToggleState={sortingBoxVisible}
        />
        <ReviewSortSelectorDropdown
          open={sortingBoxVisible}
          custom
          customStyles={selectDropdown}
        >
          <MainBox data-test={REVIEW_SORT_SELECTOR_BOX} noMargin noShadow>
            {SORT_ORDER_TYPES.map((value) => (
              <SortOrderLink
                key={value}
                rel="nofollow"
                href={buildHref(path, {
                  ...activeFilters,
                  ...{
                    sort: value === sortSelectionDefault ? '' : value,
                    page: undefined
                  }
                })}
                scroll={false}
                onClick={
                  /* istanbul ignore next */
                  () => setSortingBoxVisible(false)
                }
                data-test={SORT_ORDER_LINK}
              >
                <SortOrderLabel value={value} activeSort={activeSort} />
              </SortOrderLink>
            ))}
          </MainBox>
        </ReviewSortSelectorDropdown>
      </ReviewSortSelectorStyled>
    </>
  );
}
