/* istanbul ignore file: simple wrapper function */
import {
  DefaultApi as ReviewTopFlopApi,
  Configuration as TopFlopConfiguration,
  TopFlopServerResponse
} from 'top-flop-api';
import { determineTopFlopApiUrl } from 'helpers/urls';
import { determineProfilesApiUrl } from 'helpers/urls';
import { Environment } from 'models/Environment';
import { STATUS_CODES } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { fetchWithRetry } from 'helpers/fetchWithRetry';
import { getShopsFromApi } from 'helpers/getShopsFromApi';
import { removeTrailingSlashes } from 'helpers/removeTrailingSlashes';
import { ShopApiResponse } from 'models/ShopApiResponse';
import { nullifyIfShopHasNoInfoToShow } from 'helpers/nullifyIfShopHasNoInfoToShow';
import { applyShopOverrides } from 'helpers/applyShopOverrides';
import {
  DefaultApi as ProfileApi,
  Configuration as ProfileApiConfiguration
} from 'profile-api';
import { TargetMarket } from 'models/TargetMarket';

export async function fetchInitialData(
  environment: Environment,
  id: string,
  targetMarket: TargetMarket,
  languageCode: string
) {
  let relatedShops = {} as ShopApiResponse;
  let topFlopReviews: TopFlopServerResponse | {} = {};

  const [profile] = await Promise.all([
    nullifyIfShopHasNoInfoToShow(
      applyShopOverrides(
        await new ProfileApi(
          new ProfileApiConfiguration({
            basePath: determineProfilesApiUrl(environment),
            fetchApi: fetchWithRetry
          })
        )
          .idTargetMarketLanguageGetRaw({
            id,
            targetMarket: targetMarket ? targetMarket : 'DEU',
            language: languageCode ? languageCode : 'de'
          })
          .then(
            (response) => {
              if (response.raw.status === STATUS_CODES.NO_CONTENT) {
                return null;
              }

              return response.value();
            },
            (error) => {
              console.error(
                `Profile API failed: ${error.status} ${error.statusText}`
              );
              if (error.status === STATUS_CODES.NOT_FOUND) {
                return null;
              }

              return STATUS_CODES.INTERNAL_SERVER_ERROR;
            }
          )
      )
    )
  ]);

  const isMemberProfile = profile?.profileType === 'member';
  const isNonMemberProfile = profile?.profileType === 'non-member';
  const isFreeAccount = profile?.profileType === 'free-account';
  const isOpenProfile = isFreeAccount || isNonMemberProfile;

  if (profile) {
    topFlopReviews = await new ReviewTopFlopApi(
      new TopFlopConfiguration({
        basePath: determineTopFlopApiUrl(environment),
        fetchApi: fetchWithRetry
      })
    )
      .getTopFlop({
        tsIdOrChannelRef: profile.channelId
      })
      .catch((error) => {
        if (
          error.status === STATUS_CODES.FORBIDDEN ||
          error.status == STATUS_CODES.NOT_FOUND
        ) {
          console.warn(
            `Top/Flop API returned a forbidden or not found error: ${error.status} ${error.statusText}`
          );
          return {};
        }
        console.error(
          `Top/Flop API failed: ${error.status} ${error.statusText}`
        );
        return {};
      });
  }

  if (isOpenProfile && profile?.targetMarket) {
    relatedShops = await getShopsFromApi({
      category:
        profile && profile.categories
          ? removeTrailingSlashes(profile.categories[0]?.urlPath)
          : '',
      pageSize: '100',
      targetMarket: profile.targetMarket,
      environment
    }).catch((error) => {
      console.error(
        `Shop search API failed: ${error.status} ${error.statusText}`
      );

      return {
        shops: [],
        metaData: { totalShopCount: 0, totalPageCount: 0 },
        categories: []
      };
    });
  }

  return {
    questionnaireLink: profile?.questionnaireLink,
    relatedShops,
    topFlopReviews,
    profile,
    isMemberProfile,
    isNonMemberProfile,
    isFreeAccount,
    isOpenProfile
  };
}
