import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { useOutsideClick } from '@trustedshops/tps-seo-shared-frontend-react-components';
import {
  FilterBox,
  ReviewFilterDropdown,
  FilterSelectorHeadline,
  ReviewFilterSelector as ReviewFilterSelectorStyled
} from './ReviewFilterSelector.styles';
import { ReviewFilter } from 'models/ReviewFilter';
import { FilterEntry } from './FilterEntry';
import {
  REVIEW_FILTER_SELECTOR,
  REVIEW_FILTER_SELECTOR_BOX
} from 'services/data-test-attributes';
import DropdownChipOpener from 'components/DropdownChipOpener/DropdownChipOpener';
import { useStore } from 'context/store';
import { MappedReviewCountsForFilters } from 'helpers/getMappedReviewCountsForFilters';

export function ReviewFilterSelector({
  starsFilters
}: {
  starsFilters: ReviewFilter[];
}) {
  const { activeFilters, reviewCountsForFilters } = useStore();
  const { formatMessage } = useIntl();
  const [filterBoxVisible, setFilterBoxVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  function isFilterDisabled(filter: ReviewFilter) {
    return activeFilters.searchTerm && reviewCountsForFilters
      ? reviewCountsForFilters[
          filter.value as unknown as keyof MappedReviewCountsForFilters
        ] === 0
      : filter.reviewCountForFilter <= 0;
  }

  /* istanbul ignore next: will be covered in VRTs */
  useOutsideClick(ref, () => setFilterBoxVisible(false));

  return (
    <ReviewFilterSelectorStyled data-test={REVIEW_FILTER_SELECTOR} ref={ref}>
      <DropdownChipOpener
        onClick={() => setFilterBoxVisible(!filterBoxVisible)}
        label={formatMessage({ id: 'COMPANYPROFILES.RATINGS.LIST.FILTER' })}
        chevronToggleState={filterBoxVisible}
      />

      <ReviewFilterDropdown open={filterBoxVisible} custom={true}>
        <FilterBox data-test={REVIEW_FILTER_SELECTOR_BOX} noMargin noShadow>
          <FilterSelectorHeadline>
            {formatMessage({
              id: 'COMPANYPROFILES.RATINGS.LIST.FILTERS.RATING'
            })}
          </FilterSelectorHeadline>
          {starsFilters.map((filter) => (
            <Link
              href={filter.href}
              scroll={false}
              key={filter.value}
              className={isFilterDisabled(filter) ? 'pointer-events-none' : ''}
              rel="nofollow"
            >
              <FilterEntry
                filter={filter}
                onClick={
                  /* istanbul ignore next: will be covered in VRTs */
                  () => setFilterBoxVisible(false)
                }
              />
            </Link>
          ))}
        </FilterBox>
      </ReviewFilterDropdown>
    </ReviewFilterSelectorStyled>
  );
}
