// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReviewWidgetState {
    Badge = 'BADGE',
    Widget = 'WIDGET',
    Disabled = 'DISABLED',
    Reviews = 'REVIEWS',
    Recommendations = 'RECOMMENDATIONS',
    WidgetOrdered = 'WIDGET_ORDERED',
    WidgetSimplified = 'WIDGET_SIMPLIFIED',
    BadgeSimplified = 'BADGE_SIMPLIFIED'
}

export function ReviewWidgetStateFromJSON(json: any): ReviewWidgetState {
    return ReviewWidgetStateFromJSONTyped(json, false);
}

export function ReviewWidgetStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewWidgetState {
    return json as ReviewWidgetState;
}

export function ReviewWidgetStateToJSON(value?: ReviewWidgetState | null): any {
    return value as any;
}

