import { minWidth } from 'helpers/buildMediaQueries';
import styled from 'styled-components';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const SummaryOpenerWrapper = styled.div<{ isTooltipVisible?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  transition: transform 0.2s ease-in-out;

  ${(props) =>
    props.isTooltipVisible &&
    `
      transform: rotate(180deg);
  `};
`;

export const RatingsArea = styled.div<{ scrollingAnimationEnabled?: boolean }>`
  width: 100%;

  ${minWidth('sm', 'width: fit-content; margin-top: 20px;')};

  ${(props) =>
    props.scrollingAnimationEnabled &&
    `
    ${minWidth('sm', 'margin-top: 0;')};
  `};
`;

export const RatingInText = styled.div`
  display: none;
  color: ${colorsVariables.GRAY_700};

  ${minWidth('sm', 'display: block;')};
`;
