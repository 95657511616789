export enum AllowedCompanyProfileQueryParams {
  // actual query params:
  stars = 'stars',
  page = 'page',
  sort = 'sort',
  // params in URL path:
  language = 'language',
  profile = 'profile',
  id = 'id',
  searchTerm = 'searchTerm'
}
