import styled from 'styled-components';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const RadioButton = styled.div<{ isActive: boolean }>`
  min-width: 21px;
  width: 21px;
  height: 21px;
  background: ${colorsVariables.WHITE};
  border: 1px solid ${colorsVariables.GRAY_300};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex !important;

  ${(props) =>
    props.isActive &&
    `
    background: ${colorsVariables.GREEN_500};
    border: 1.25px solid ${colorsVariables.GREEN_600};
  `};
`;

export const RadioButtonSelected = styled.div`
  background-color: ${colorsVariables.WHITE};
  width: 7px;
  height: 7px;
  display: flex;
  border-radius: 50%;
`;
