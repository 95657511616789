// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TargetMarket {
    Deu = 'DEU',
    Aut = 'AUT',
    Bel = 'BEL',
    Che = 'CHE',
    Cze = 'CZE',
    Dnk = 'DNK',
    Esp = 'ESP',
    Euo = 'EUO',
    Fin = 'FIN',
    Fra = 'FRA',
    Gbr = 'GBR',
    Grc = 'GRC',
    Ita = 'ITA',
    Lux = 'LUX',
    Nld = 'NLD',
    Nor = 'NOR',
    Pol = 'POL',
    Prt = 'PRT',
    Rou = 'ROU',
    Swe = 'SWE'
}

export function TargetMarketFromJSON(json: any): TargetMarket {
    return TargetMarketFromJSONTyped(json, false);
}

export function TargetMarketFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetMarket {
    return json as TargetMarket;
}

export function TargetMarketToJSON(value?: TargetMarket | null): any {
    return value as any;
}

