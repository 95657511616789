import { UserType } from 'models/ReportAReview';
import {
  ReportReviewTooltip as ReportReviewTooltipStyled,
  TooltipHeadline,
  TooltipBody
} from 'components/ReviewArea/ReportModal/ReportReviewWizard/ReportReviewInfoTooltip/ReportReviewInfoTooltip.styles';
import { useIntl } from 'react-intl';

export const ReportReviewInfoTooltip = ({
  userType
}: {
  userType: UserType;
}) => {
  const { formatMessage } = useIntl();

  return (
    <ReportReviewTooltipStyled>
      <TooltipHeadline>
        {formatMessage({
          id: `REPORT_REVIEW.TOOLTIP.${userType.toUpperCase()}_HEADLINE`
        })}
      </TooltipHeadline>
      <TooltipBody>
        <span>
          {formatMessage({
            id: `REPORT_REVIEW.TOOLTIP.${userType.toUpperCase()}_BODY_1`,
            defaultMessage: ' '
          }).trim()}{' '}
        </span>
        <a
          href={formatMessage({
            id: `REPORT_REVIEW.TOOLTIP.${userType.toUpperCase()}_LINK_URL`
          })}
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage({
            id: `REPORT_REVIEW.TOOLTIP.${userType.toUpperCase()}_BODY_2.LINK_LABEL`
          }).trim()}{' '}
        </a>
        <span>
          {formatMessage({
            id: `REPORT_REVIEW.TOOLTIP.${userType.toUpperCase()}_BODY_3`,
            defaultMessage: ' '
          })}
        </span>
      </TooltipBody>
    </ReportReviewTooltipStyled>
  );
};
