export function buildPath({ language, id, profileContext, isTsId }: any) {
  let path = language ? `/${language}` : '';

  if (isTsId) {
    path += `/${profileContext}/info_${id}.html`;
    return path;
  }

  path += `/${profileContext}/${id}`;
  return path;
}
