import React from 'react';
import { SocialMediaItem } from 'components/CompanyProfile/SocialMedia/SocialMediaItem/SocialMediaItem';
import { SocialMediaList } from 'components/CompanyProfile/SocialMedia/SocialMedia.styles';
import { useStore } from 'context/store';
import { SocialMediaLinkListTypeEnum } from 'profile-api';
import { FacebookIcon } from 'components/CompanyProfile/SocialMedia/Icons/FacebookIcon';
import { InstagramIcon } from 'components/CompanyProfile/SocialMedia/Icons/InstagramIcon';
import { YouTubeIcon } from 'components/CompanyProfile/SocialMedia/Icons/YouTubeIcon';
import { TikTokIcon } from 'components/CompanyProfile/SocialMedia/Icons/TikTokIcon';
import { XIcon } from 'components/CompanyProfile/SocialMedia/Icons/xIcon';
import { PinterestIcon } from 'components/CompanyProfile/SocialMedia/Icons/PinterestIcon';

const iconComponentMapping = {
  facebook: {
    component: FacebookIcon,
    label: 'Facebook'
  },
  instagram: {
    component: InstagramIcon,
    label: 'Instagram'
  },
  youtube: {
    component: YouTubeIcon,
    label: 'YouTube'
  },
  x: {
    component: XIcon,
    label: 'X/Twitter'
  },
  tiktok: {
    component: TikTokIcon,
    label: 'TikTok'
  },
  pinterest: {
    component: PinterestIcon,
    label: 'Pinterest'
  }
};

const renderSocialMediaIcon = (type?: SocialMediaLinkListTypeEnum) => {
  const IconComponent =
    iconComponentMapping[
      type?.toLowerCase() as keyof typeof iconComponentMapping
    ].component || FacebookIcon;
  return <IconComponent />;
};

export const SocialMedia = () => {
  const { profile } = useStore();

  if (profile.socialMediaLinks?.length === 0) {
    return <></>;
  }

  return (
    <SocialMediaList>
      {profile.socialMediaLinks?.map((socialMediaLink, index) => {
        const url = socialMediaLink.url?.match(/^https?:\/\//)
          ? socialMediaLink.url
          : `https://${socialMediaLink.url}`;

        return (
          <li key={'social-media-link-' + index}>
            <SocialMediaItem
              label={
                iconComponentMapping[
                  socialMediaLink.type?.toLowerCase() as keyof typeof iconComponentMapping
                ].label
              }
              icon={renderSocialMediaIcon(socialMediaLink.type)}
              url={url || ''}
            />
          </li>
        );
      })}
    </SocialMediaList>
  );
};
