import styled, { css } from 'styled-components';
import { MainBox } from 'components/MainBox/MainBox';
import { minWidth } from 'helpers/buildMediaQueries';
import {
  colorsVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const CompanyDetailsAndContactInfo = styled.div<{
  showOnMobile?: boolean;
}>`
  width: 100%;
  flex: 1 0 auto;

  ${(props) =>
    !props.showOnMobile &&
    css`
      display: none;
      ${minWidth('xl', 'display: inherit;')};
    `};
`;

export const CompanyDetailsAndContactInfoBox = styled(MainBox)`
  display: block;
  padding: ${gutterVariables.GUTTER_PLUS_HALF};
`;

export const SectionSeparator = styled.div`
  border-bottom: 1px solid ${colorsVariables.GRAY_100};
  margin: 24px 0;
`;
