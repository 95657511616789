import styled from 'styled-components';
import { MainBox } from 'components/MainBox/MainBox';
import {
  Heading,
  LinkAsButton,
  flexColumnCenteredItems,
  gutterVariables,
  fontVariables,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const ProductWrapper = styled(MainBox)`
  ${flexColumnCenteredItems};
  border-bottom: 1px ${colorsVariables.GRAY_200} solid;
  text-align: center;
`;

export const VoucherHeading = styled(Heading)`
  margin-bottom: ${gutterVariables.GUTTER};
`;

export const CodeTitle = styled.div`
  font-size: ${fontVariables.SIZE_TEXT_M};
  margin-bottom: ${gutterVariables.GUTTER_HALF};
`;

export const CodeWrapper = styled.div`
  margin-bottom: ${gutterVariables.GUTTER_HALF};
`;

export const LinkToOffer = styled(LinkAsButton)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  font-size: ${fontVariables.SIZE_TEXT};
  width: fit-content;
  justify-content: center;
  padding: ${gutterVariables.GUTTER_HALF} ${gutterVariables.GUTTER};
  height: auto;
  line-height: 16px;
  margin-top: ${gutterVariables.GUTTER_HALF} 0 0 0;
`;

export const VoucherIllustration = styled.div`
  height: 160px;
  margin-bottom: ${gutterVariables.GUTTER};

  svg {
    height: 100%;
    width: auto;
  }
`;

export const CampaignText = styled.div`
  margin-bottom: ${gutterVariables.GUTTER_HALF};
`;

export const VoucherCode = styled.div`
  width: fit-content;
  border: 1px ${colorsVariables.GRAY_200} dashed;
  padding: ${gutterVariables.GUTTER};
  border-radius: 8px;
  background-color: ${colorsVariables.BLUE_100};
`;
