import styled from 'styled-components';
import {
  colorsVariables,
  flexItemsCentered,
  fontVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const ReviewMeta = styled.div<{
  justifyContent: string;
  paddingLeft?: string;
}>`
  ${flexItemsCentered};
  width: 100%;
  margin-top: ${gutterVariables.GUTTER_PLUS_HALF};
  line-height: ${fontVariables.LINE_HEIGHT};
  color: ${colorsVariables.GRAY_700};

  ${(props) =>
    `
      justify-content: ${props.justifyContent}; 
      padding-left: ${props.paddingLeft}
    `};
`;

export const ReviewReport = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  vertical-align: bottom;
  font-size: ${fontVariables.SIZE_TEXT_S};
  text-align: justify;
  color: ${colorsVariables.GRAY_500};
  gap: 8px;
`;

export const ReportText = styled.span`
  display: none;

  ${minWidth('md', 'display: block;')}
`;
