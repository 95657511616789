
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/profile/[profile]/[id]",
      function () {
        return require("private-next-pages/profile/[profile]/[id].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/profile/[profile]/[id]"])
      });
    }
  