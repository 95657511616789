import styled from 'styled-components';
import {
  colorsVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const DropdownChipOpener = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 5px;
  height: 42px;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  line-height: 26px;

  background: ${colorsVariables.GRAY_100};
  color: ${colorsVariables.BLUE_700};
  border-radius: 40px;
`;
