import styled, { css } from 'styled-components';
import {
  Icon,
  Heading,
  HeadingProps,
  colorsVariables,
  fontVariables,
  flexItemsCentered,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const CertificateHeader = styled.div`
  ${flexItemsCentered};
  justify-content: space-between;
  line-height: 1;
`;

export const CertificateInfoHeading = styled(Heading)<HeadingProps>`
  ${flexItemsCentered};
  flex-wrap: wrap;
  font-size: ${fontVariables.SIZE_TEXT_M};
  line-height: ${fontVariables.LINE_HEIGHT_L};
  color: ${colorsVariables.GRAY_900};
`;

export const CertificateInfoHeadingWrapper = styled.div<{
  hasMarginBottom?: boolean;
}>`
  margin-bottom: ${(props) =>
    props.hasMarginBottom ? gutterVariables.GUTTER_ONE_QUARTER : 0};
`;

export const CertificateInfoHeadingText = styled.span`
  margin-right: ${gutterVariables.GUTTER_HALF};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  line-height: ${fontVariables.LINE_HEIGHT_S};
`;

export const CertificateInfoSubheading = styled.span`
  font-size: ${fontVariables.SIZE_TEXT_S};
  font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
  color: ${colorsVariables.GRAY_700};
  line-height: ${fontVariables.LINE_HEIGHT};
`;

export const ContentWrapper = styled.div`
  ${flexItemsCentered};
  text-align: left;
`;

export const CertificateStateTag = styled.div`
  line-height: 8px;
`;

export const ChevronArrow = styled(Icon)<{ detailsExpanded: boolean }>`
  transition: transform 0.3s ease-in-out;
  display: inline-block;
  vertical-align: middle;

  &::before {
    vertical-align: middle;
  }

  ${(props) =>
    props.detailsExpanded &&
    css`
      transform: rotate(180deg);
    `};
`;
