import React from 'react';
import {
  AuthorAdditionalInfo,
  AuthorInfo as AuthorInfoStyled,
  AuthorInfoLink,
  AuthorNameInfo as AuthorNameInfoStyled,
  ReviewAvatar
} from 'components/Review/AuthorInfo/AuthorInfo.styles';
import { Review } from 'reviews-api';
import { Icon } from '@trustedshops/tps-seo-shared-frontend-react-components';

export function authorLink(review?: Review): string {
  if (!review || !review?.customer?.id) {
    return '#';
  }

  return `https://www.trustedshops.com/user/de_DE/${review.customer.id}`;
}
export const AuthorNameInfo = ({
  firstName,
  lastName,
  hasMarginBottom,
  isTopFlop
}: {
  firstName?: string;
  lastName?: string;
  hasMarginBottom?: boolean;
  isTopFlop?: boolean;
}) => {
  if (firstName && lastName) {
    return (
      <AuthorNameInfoStyled
        hasMarginBottom={hasMarginBottom}
        isTopFlop={isTopFlop}
      >
        {firstName && <span>{firstName}</span>}
        {firstName && lastName && <span>&nbsp;</span>}
        {lastName && <span>{lastName}</span>}
      </AuthorNameInfoStyled>
    );
  }

  return null;
};

function stringToHslColor(
  userName: string,
  saturation: number,
  lightness: number
) {
  let hash = 0;

  // Converts the given string (the name and last name of the user) to a numeric hash
  for (let i = 0; i < userName.length; i++) {
    hash = userName.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

const AuthorInfo = ({
  review,
  isClickable,
  isTopFlop
}: {
  review: Review;
  isClickable?: boolean;
  isTopFlop?: boolean;
}) => {
  const { firstName, lastName } = review.customer || {};
  const avatarBgColor =
    firstName && lastName
      ? stringToHslColor(firstName + lastName, 40, 50)
      : '#ccc';

  return (
    <AuthorInfoStyled>
      <ReviewAvatar avatarBgColor={avatarBgColor} isTopFlop={isTopFlop}>
        <span>
          {firstName!.substring(0, 1).toUpperCase()}
          {lastName!.substring(0, 1).toUpperCase()}
        </span>
      </ReviewAvatar>
      <div>
        {isClickable && firstName && lastName ? (
          <AuthorInfoLink href={authorLink(review)}>
            <AuthorNameInfo
              firstName={firstName}
              lastName={lastName}
              hasMarginBottom={!!review.customer?.city}
              isTopFlop={isTopFlop}
            />
          </AuthorInfoLink>
        ) : (
          <AuthorNameInfo firstName={firstName} lastName={lastName} />
        )}
        {review.customer?.city && (
          <AuthorAdditionalInfo>
            <Icon iconSize={'20px'} iconName={'icon-label-location-pin'} />
            <span>{review.customer?.city}</span>
          </AuthorAdditionalInfo>
        )}
      </div>
    </AuthorInfoStyled>
  );
};

export default AuthorInfo;
