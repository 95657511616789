import { GetServerSidePropsContext } from 'next';
import { DefaultPage } from 'styles/DefaultPage.styles';
import React, { useEffect, useRef, useState } from 'react';
import { CompanyHeaderComponent } from 'components/CompanyHeader/CompanyHeader';
import ReviewArea from 'components/ReviewArea/ReviewArea';
import { determineShopReviewsApiUrl } from 'helpers/urls';
import {
  determineEnvironmentFromHost,
  determineHost,
  determineTargetMarketFromHost,
  determineTldFromHost
} from 'helpers/url-mapper';
import { validateStarsFilterValues } from 'helpers/validateStarsFilterValues';
import { useRouter } from 'next/router';
import { sortIsValid } from 'helpers/sortIsValid';
import {
  Configuration as ShopReviewsConfiguration,
  DefaultApi as ShopReviewsApi,
  TsIdOrChannelRefGetSortByEnum,
  TsIdOrChannelRefGetOrderEnum,
  ReviewResponse
} from 'reviews-api';
import { globalEnvironment, ProfileType } from 'helpers/global-environment';
import { fetchInitialData } from 'helpers/fetchInitialData';
import { PageParameterValidator } from 'helpers/PageParameterValidator';
import {
  Footer,
  STATUS_CODES
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { useIntl } from 'react-intl';
import { LocalesAllowedValues } from 'models/Locales';
import { CompanyProfileHead } from 'components/CompanyProfileHead/CompanyProfileHead';
import { mapTldToLanguageCode } from 'helpers/mapTldToLanguageCode';
import { determineCurrentTime } from 'helpers/determineCurrentTime';
import { ProfilePageProps } from 'models/ProfilePageProps';
import { shopCertificateStateIs } from 'helpers/shopCertificateStateIs';
import {
  CertificationState,
  CertificationStateVisibility
} from 'models/CertificationState';
import NotFoundPage from 'components/NotFoundPage/NotFoundPage';
import BasicLayout from 'layouts/BasicLayout';
import { addETrustedImprintLink } from 'helpers/addETrustedImprintLink';
import { MAIN_CONTENT_WRAPPER } from 'services/data-test-attributes';
import { Environment } from 'models/Environment';
import { TargetMarket, TLD } from 'models/TargetMarket';
import { MobileAndTabletOnlyWrapper } from 'styles/utils/deviceOnlyWrapper';
import { fetchWithRetry } from 'helpers/fetchWithRetry';
import { SimilarCompaniesBottomPageWrapper } from 'components/SimilarCompanies/SimilarCompanies.styles';
import { MainPageContent } from 'components/MainPageContent/MainPageContent';
import { PAGE_TOP_ID } from 'services/page-section-ids';
import { Profile } from 'profile-api';
import { reviewsStateIs } from 'helpers/reviewStateIs';
import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';
import dynamic from 'next/dynamic';
import { buildPath } from 'helpers/buildPath';
import { formatId } from 'helpers/formatId';
import { routeNeedsToBeReplaced } from 'helpers/routeNeedsToBeReplaced';
import { ProfileAsideSection } from 'components/ProfileAsideSection/ProfileAsideSection';

const SimilarCompanies = dynamic(
  () => import('components/SimilarCompanies/SimilarCompanies')
);

const CertificateInfo = dynamic(
  () => import('components/CertificateInfo/CertificateInfo')
);

const EmptyProfileMainContent = dynamic(
  () => import('components/EmptyProfileMainContent/EmptyProfileMainContent')
);

export class Handler {
  protected id: string;
  protected language?: string;
  protected environment: Environment;
  private tld: TLD;
  private languageCode: string;
  private host?: string;
  private isTsId: boolean;
  private targetMarket: TargetMarket;

  constructor(protected context: GetServerSidePropsContext) {
    this.id = formatId(context.query.id as string);
    this.language = context.query.language as string | undefined;
    const firstContentfulSegment =
      this.language || (context.query.profile as string);
    this.host = determineHost(context);
    this.tld = determineTldFromHost(this.host);
    this.languageCode = mapTldToLanguageCode(this.tld, firstContentfulSegment);
    this.environment = determineEnvironmentFromHost(this.host);
    this.isTsId = this.id.startsWith('X') && this.id.length === 33;
    this.targetMarket = determineTargetMarketFromHost(this.host);
  }

  async getProps() {
    const context = this.context;
    const path = buildPath({
      language: this.language,
      id: this.id,
      profileContext: this.context.query.profile,
      isTsId: this.isTsId
    });
    const [validStars, stars] = validateStarsFilterValues(context.query.stars);
    const searchTerm = context.query.searchTerm
      ? (context.query.searchTerm as string)
      : undefined;

    // Read all non-reviews-related data from cache, during client-side navigation
    const {
      questionnaireLink,
      topFlopReviews,
      isMemberProfile,
      isNonMemberProfile,
      isFreeAccount,
      isOpenProfile,
      relatedShops,
      profile
    } = await this.getInitialData();

    const commonProps = {
      host: this.host,
      languageCode: this.languageCode,
      currentTime: determineCurrentTime(this.environment).toISOString()
    };

    if (
      profile &&
      profile === (STATUS_CODES.INTERNAL_SERVER_ERROR as unknown as Profile)
    ) {
      if (typeof window === 'undefined') {
        context.res.statusCode = STATUS_CODES.INTERNAL_SERVER_ERROR;
      }
      return {
        ...commonProps,
        error: STATUS_CODES.INTERNAL_SERVER_ERROR
      };
    }

    if (!profile) {
      if (typeof window === 'undefined') {
        context.res.statusCode = STATUS_CODES.GONE;
      }
      return {
        ...commonProps,
        error: STATUS_CODES.GONE
      };
    }

    const sortSelectionDefault =
      globalEnvironment.reviews.sort.defaultSort[ProfileType.eTrusted];
    let validSort = true;
    let sort = undefined;
    if (context.query.sort) {
      if (sortIsValid(context.query.sort)) {
        sort = context.query.sort as TsIdOrChannelRefGetSortByEnum;
      } else {
        validSort = false;
      }
    }

    const pageParameterValidator = new PageParameterValidator(
      context.query.page
    );
    const reviews: ReviewResponse = await new ShopReviewsApi(
      new ShopReviewsConfiguration({
        basePath: determineShopReviewsApiUrl(this.environment),
        fetchApi: fetchWithRetry
      })
    )
      .tsIdOrChannelRefGet({
        tsIdOrChannelRef: profile.channelId || this.id,
        stars: stars.length === 0 ? undefined : stars,
        order: TsIdOrChannelRefGetOrderEnum.Desc,
        sortBy: sort || sortSelectionDefault,
        pageSize: globalEnvironment.reviews.pageSize,
        pageIndex: pageParameterValidator.currentPage - 1,
        searchTerm: searchTerm || undefined
      })
      .catch((error) => {
        console.error(
          `Reviews API failed: ${error.status} ${error.statusText}`
        );

        return { metaData: { totalReviewCount: 0 }, reviews: [] };
      });

    const { totalReviewCount } = reviews.metaData;
    const pageCount = Math.ceil(
      totalReviewCount / globalEnvironment.reviews.pageSize
    );

    return {
      ...commonProps,
      error: undefined,
      questionnaireLink,
      path,
      reviews,
      pageCount,
      page: pageParameterValidator.currentPage,
      validPage: pageParameterValidator.pageParamIsValidNumber,
      stars,
      validStars,
      sort,
      validSort,
      sortSelectionDefault,
      environment: this.environment,
      topFlopReviews,
      isMemberProfile,
      isNonMemberProfile,
      isFreeAccount,
      isOpenProfile,
      relatedShops,
      searchTerm,
      profile
    };
  }

  async getInitialData() {
    if (typeof window === 'undefined') {
      return await fetchInitialData(
        this.environment,
        this.id,
        this.targetMarket,
        this.languageCode
      );
    }
    return window.__NEXT_DATA__.props.pageProps as Awaited<
      ReturnType<typeof fetchInitialData>
    >;
  }
}

export async function getInitialProps(context: GetServerSidePropsContext) {
  const handler = new Handler(context);

  return await handler.getProps();
}

function ProfilePage(props: ProfilePageProps) {
  const router = useRouter();
  const { formatMessage, messages } = useIntl();
  const translations = messages as LocalesAllowedValues;
  const footerImprintStructure =
    translations && translations['FOOTER_IMPRINT_STRUCTURE'];
  addETrustedImprintLink(
    footerImprintStructure,
    formatMessage({
      id: 'APP.FOOTER.IMPRINT_LINK_ETRUSTED'
    })
  );
  const [_, setCurrentHeaderHeight] = useState(0);
  const desktopVoucherRef = useRef<HTMLDivElement>(null);
  const contactInfoRef = useRef<HTMLDivElement>(null);
  const pageHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pageHeaderRef && pageHeaderRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setCurrentHeaderHeight(pageHeaderRef.current?.clientHeight || 0);
      });

      resizeObserver.observe(pageHeaderRef.current);
      return () => resizeObserver.disconnect(); // clean up
    }
  }, []);

  if (props.error === STATUS_CODES.GONE) {
    return (
      <NotFoundPage
        mobileMenuOpen={props.mobileMenuOpen}
        setMobileMenuOpen={props.setMobileMenuOpen}
      />
    );
  } else if (props.error) {
    return;
  }

  const isCertificateInfoVisible = shopCertificateStateIs(
    props.profile,
    CertificationStateVisibility.Visible
  );
  const isCertificationInvalid =
    [CertificationState.Cancelled].indexOf(
      props.profile.memberDetails?.certificationStateId!
    ) >= 0;
  const companyDetailsVisible = !(
    isCertificationInvalid &&
    props.profile.reviewStatistic?.yearlyReviewCount === 0 &&
    !!props.profile.description
  );
  const summaryIsVisible = reviewsStateIs(
    props.profile,
    ReviewsVisibilityState.SummaryIsVisible
  );

  /* istanbul ignore next: has been covered by interaction tests */
  if (routeNeedsToBeReplaced(props)) {
    router.replace(
      {
        pathname: props.path,
        query: {
          stars: props.stars,
          sort: props.sort || [],
          page:
            props.page === 1 ||
            (props.pageCount > 1 && props.page > props.pageCount)
              ? []
              : props.page,
          searchTerm: props.searchTerm || []
        }
      },
      undefined,
      {
        shallow: false
      }
    );
  }

  return (
    <BasicLayout noScrolling={props.mobileMenuOpen}>
      <DefaultPage id={PAGE_TOP_ID}>
        <CompanyProfileHead
          queryParams={router.query}
          reviews={props.reviews.reviews}
        />
        <CompanyHeaderComponent
          translations={translations}
          mobileMenuOpen={props.mobileMenuOpen}
          setMobileMenuOpen={props.setMobileMenuOpen}
          pageHeaderRef={pageHeaderRef}
        />
        <MainPageContent
          data-test={MAIN_CONTENT_WRAPPER}
          isCertificateInfoVisible={isCertificateInfoVisible}
          hasNoReviews={!props.searchTerm && !summaryIsVisible}
        >
          {isCertificateInfoVisible && (
            <MobileAndTabletOnlyWrapper>
              <CertificateInfo />
            </MobileAndTabletOnlyWrapper>
          )}
          <ProfileAsideSection
            isCertificateInfoVisible={isCertificateInfoVisible}
            summaryIsVisible={summaryIsVisible}
            searchTerm={props.searchTerm}
            companyDetailsVisible={companyDetailsVisible}
            reviews={props.reviews}
            contactInfoRef={contactInfoRef}
            desktopVoucherRef={desktopVoucherRef}
            relatedShops={props.relatedShops}
          />
          {!summaryIsVisible && !props.searchTerm && (
            <EmptyProfileMainContent
              companyDetailsVisible={companyDetailsVisible}
              contactInfoRef={contactInfoRef}
            />
          )}
          <ReviewArea
            reviews={props.reviews}
            topFlopReviews={props.topFlopReviews}
          />
          {!props.isMemberProfile && (
            <SimilarCompaniesBottomPageWrapper>
              <SimilarCompanies similarCompanies={props.relatedShops.shops} />
            </SimilarCompaniesBottomPageWrapper>
          )}
        </MainPageContent>
        <Footer
          translations={translations}
          footerStructure={translations && translations['FOOTER_STRUCTURE']}
          footerImprintStructure={footerImprintStructure}
        />
      </DefaultPage>
    </BasicLayout>
  );
}

ProfilePage.getInitialProps = getInitialProps;

export default ProfilePage;
