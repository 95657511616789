import React from 'react';
import { useIntl } from 'react-intl';
import { TopOrFlop } from 'models/TopFlopTypes';
import { TopFlopHeader as TopFlopHeaderStyled } from 'components/ReviewTopFlop/TopFlopHeader/TopFlopHeader.styles';
import { Heading } from '@trustedshops/tps-seo-shared-frontend-react-components';

const topFlopTranslationKeys = {
  top: 'COMPANYPROFILES.TOPFLOP.MOST_RELEVANT_POSITIVE',
  flop: 'COMPANYPROFILES.TOPFLOP.MOST_RELEVANT_CRITICAL'
};

export const TopFlopHeader = ({ topFlop }: { topFlop: TopOrFlop }) => {
  const { formatMessage } = useIntl();

  return (
    <TopFlopHeaderStyled topFlop={topFlop}>
      <Heading as="h4">
        {formatMessage({ id: topFlopTranslationKeys[topFlop] })}
      </Heading>
    </TopFlopHeaderStyled>
  );
};
