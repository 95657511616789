import styled from 'styled-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const DesktopOnlyWrapper = styled.div`
  display: none;

  ${minWidth('xxl', 'display: block;')}
`;

export const MobileAndTabletOnlyWrapper = styled.div`
  display: block;

  ${minWidth('xxl', 'display: none;')}
`;

export const MobileOnlyWrapper = styled.div`
  display: block;

  ${minWidth('sm', 'display: none;')}
`;

export const DesktopAndTabletOnlyWrapper = styled.div`
  display: none;

  ${minWidth('sm', 'display: block;')}
`;
