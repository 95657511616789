import { TsIdOrChannelRefGetSortByEnum } from 'reviews-api';

export function sortIsValid(sort: string | string[]): boolean {
  if (!sort) {
    return true;
  }

  return Object.values(TsIdOrChannelRefGetSortByEnum).includes(
    sort as TsIdOrChannelRefGetSortByEnum
  );
}
