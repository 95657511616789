import React from 'react';
import { useIntl } from 'react-intl';
import { StatusText } from 'components/Review/ReviewProvider/ReviewProvider.styles';

export const InModeration = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <StatusText>
        {formatMessage({ id: 'COMPANYPROFILES.RATINGS.SINGLE.IN_MODERATION' })}
      </StatusText>
    </>
  );
};
