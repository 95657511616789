import styled from 'styled-components';
import {
  colorsVariables,
  fontVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${minWidth('md', 'gap: 16px;')};
`;

export const AuthorNameInfo = styled.div<{
  hasMarginBottom?: boolean;
  isTopFlop?: boolean;
}>`
  color: ${colorsVariables.GRAY_900};
  font-size: ${fontVariables.SIZE_TEXT};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  line-height: ${fontVariables.LINE_HEIGHT_M};

  ${(props) =>
    props.hasMarginBottom &&
    `
    margin-bottom: ${gutterVariables.GUTTER_ONE_QUARTER};
  `};

  ${(props) =>
    props.isTopFlop &&
    `
    font-size: ${fontVariables.SIZE_TEXT_S};
    line-height: ${fontVariables.LINE_HEIGHT};
  `};
`;

export const AuthorInfoLink = styled.a`
  &:hover {
    color: ${colorsVariables.GRAY_500};
    text-decoration: underline;
`;

const avatarSize = 48;
const avatarSizeTopflop = 40;
export const ReviewAvatar = styled.div<{
  avatarBgColor: string;
  isTopFlop?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.avatarBgColor};
  border-radius: 50%;
  height: ${avatarSize}px;
  text-align: center;
  width: ${avatarSize}px;

  & > span {
    font-size: calc(${avatarSize}px / 2);
    line-height: 1;
    position: relative;
    color: ${colorsVariables.WHITE};
  }

  ${(props) =>
    props.isTopFlop &&
    `
    height: ${avatarSizeTopflop}px;
    width: ${avatarSizeTopflop}px;
  `};
`;

export const AuthorAdditionalInfo = styled.div`
  color: ${colorsVariables.GRAY_500};
  font-size: ${fontVariables.SIZE_TEXT_S};
  font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
  line-height: ${fontVariables.LINE_HEIGHT};
  margin-left: -4px;
`;
