import styled from 'styled-components';
import {
  fontVariables,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const Chip = styled.div`
  color: ${colorsVariables.GRAY_700};
  border-radius: 32px;
  padding: 5px 35px 5px 10px;
  display: inline-block;
  position: relative;
  background: ${colorsVariables.WHITE};
  border: 1px ${colorsVariables.GRAY_300} solid;

  .chip-text {
    line-height: ${fontVariables.LINE_HEIGHT_L};
    letter-spacing: 0.4px;
  }
`;
