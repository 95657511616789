import styled from 'styled-components';
import { minWidth } from 'helpers/buildMediaQueries';
import {
  gutterVariables,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const ReviewTopFlop = styled.div<{ isModalOpen?: boolean }>`
  margin-bottom: ${gutterVariables.GUTTER_PLUS_HALF};

  ${minWidth(
    'sm',
    `
    display: grid; 
    grid-gap: ${gutterVariables.GUTTER_PLUS_HALF};
    grid-template-columns: minmax(calc(50% - 12px), 1fr) minmax(calc(50% - 12px), 1fr);
  `
  )};

  ${minWidth(
    'md',
    `
    grid-template-columns: 304px 304px;
  `
  )};

  ${minWidth(
    'lg',
    `
    grid-template-columns: 365px 365px;
  `
  )};

  ${minWidth(
    'xxl',
    `
    grid-template-columns: 356px 356px;
  `
  )};

  .swiper {
    position: initial;
    overflow: visible;
    display: grid;
  }

  .swiper-wrapper {
    pointer-events: ${(props) => (props.isModalOpen ? 'none' : 'all')};
  }

  .swiper-slide {
    width: 90%;
    transform: none;
  }

  .swiper-pagination {
    position: initial;
    margin-top: ${gutterVariables.GUTTER};
    z-index: 0;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      border: 1px solid ${colorsVariables.GRAY_600};
      background: ${colorsVariables.GRAY_050};
    }

    .swiper-pagination-bullet-active {
      background: #42687a;
      border: none;
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${colorsVariables.GRAY_100};
  margin: 16px -2px 16px -2px;
`;
