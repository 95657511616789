import styled from 'styled-components';
import {
  flexItemsCentered,
  fontVariables,
  colorsVariables,
  flexColumn,
  Icon
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const ReviewProviderStyled = styled.div<{
  isVerifiedProvider?: boolean;
}>`
  ${flexItemsCentered};
  padding: 0 6px;
  border-radius: 2px;

  ${(props) =>
    props.isVerifiedProvider &&
    `
    background: #f5f9fa;
  `};
`;

export const StatusText = styled.span<{ isVerifiedProvider?: boolean }>`
  display: block;
  line-height: ${fontVariables.LINE_HEIGHT_XS};
  font-size: ${fontVariables.SIZE_TEXT_XS};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};

  ${(props) =>
    props.isVerifiedProvider &&
    `
    color: ${colorsVariables.BLUE_600};
  `};
`;

export const VerifiedTooltipWrapper = styled.div`
  ${flexItemsCentered};
  padding: 0 6px;
  border-radius: 2px;
  background: #f5f9fa;
`;

export const VerifiedReviewTooltipContent = styled.div<{
  isTooltipOnMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #344054;

  ${(props) => props.isTooltipOnMobile && `color: white;`}

  ${minWidth('md', `color: white;`)};
`;

export const VerifiedReviewTooltipChildren = styled.div`
  display: flex;
  align-items: center;
`;

export const ModificationHistory = styled.div`
  ${flexColumn};
`;

export const ModificationHistoryEntry = styled.span<{
  isTooltipOnMobile?: boolean;
}>`
  color: #344054;
  font-size: 16px;
  line-height: 150%;

  ${(props) => props.isTooltipOnMobile && `color: white; font-size: 14px;`}

  ${minWidth(
    'md',
    `font-size: ${fontVariables.SIZE_TEXT_XS}; font-weight: ${fontVariables.FONT_WEIGHT_REGULAR}; color: white;`
  )};
`;

export const ModificationHistoryEntryHeading = styled.span<{
  isTooltipOnMobile?: boolean;
}>`
  color: #344054;
  font-weight: ${fontVariables.FONT_WEIGHT_BOLD};

  ${(props) => props.isTooltipOnMobile && `color: white; font-size: 14px;`}

  ${minWidth('md', `font-size: ${fontVariables.SIZE_TEXT_XS}; color: white;`)};
`;

export const VerifiedIcon = styled(Icon)`
  height: 20px;
`;

export const LinkInTooltip = styled.a`
  & {
    text-decoration: underline;
  }
`;
