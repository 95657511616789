import React, { useRef, useState } from 'react';
import {
  colorsVariables,
  Icon,
  Stars,
  Tooltip,
  TooltipHandleFunctions
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import {
  RatingInText,
  RatingsArea as RatingsAreaStyled,
  SummaryOpenerWrapper
} from 'components/CompanyHeader/RatingsArea/RatingsArea.styles';
import { RATING_SUMMARY_TOOLTIP } from 'services/data-test-attributes';
import { useStore } from 'context/store';
import {
  AverageRating,
  BoldedText,
  StarsRatingWrapper
} from 'components/CompanyHeader/CompanyHeader.styles';
import { useIntl } from 'react-intl';
import { getGradeName } from 'helpers/getGradeName';
import dynamic from 'next/dynamic';

const RatingsSummary = dynamic(
  () =>
    import(
      'components/CompanyHeader/RatingsArea/RatingsSummary/RatingsSummary'
    ),
  { ssr: false }
);

export const RatingsArea = ({
  summaryIsVisible,
  scrollingAnimationEnabled
}: {
  summaryIsVisible: boolean;
  scrollingAnimationEnabled?: boolean;
}) => {
  const { profile } = useStore();
  const { formatNumber, formatMessage } = useIntl();
  const refForTooltip = useRef<HTMLDivElement>(null);
  const tooltipComponentRef = useRef<TooltipHandleFunctions>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const gradeName = getGradeName(profile.reviewStatistic?.grade || 0);

  function handleVisibilityChange(tooltipVisibility: boolean) {
    setIsTooltipVisible(tooltipVisibility);
  }

  return (
    <RatingsAreaStyled scrollingAnimationEnabled={scrollingAnimationEnabled}>
      {summaryIsVisible &&
      profile.reviewStatistic?.grade &&
      profile.reviewStatistic?.grade > 0 ? (
        <Tooltip
          onVisibilityChange={handleVisibilityChange}
          childrenRef={refForTooltip}
          ref={tooltipComponentRef}
          uniqueId={'ratings-summary'}
          content={isTooltipVisible ? <RatingsSummary /> : <></>}
          placement={'bottom'}
          maxWidthMobile={'100%'}
          maxWidthDesktop={'343px'}
          minWidthDesktop={'343px'}
          outsideClickEventActive={false}
          isPointerArrowVisible={false}
          mobileElementType={'tooltip'}
          backgroundColor={'white'}
        >
          <StarsRatingWrapper
            hasRatings
            className={
              scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
            }
          >
            <Stars
              filledValue={profile.reviewStatistic?.grade || 0}
              iconSize={'22px'}
              iconSizeDesktop={'26px'}
            />
            <AverageRating
              className={
                scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
              }
            >
              {formatNumber(profile.reviewStatistic?.grade || 0, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </AverageRating>
            <RatingInText>
              <BoldedText>
                {formatMessage({
                  id: 'COMPANYPROFILES.RATINGS.SUMMARY.LABEL_' + gradeName
                })}
              </BoldedText>
            </RatingInText>
            <SummaryOpenerWrapper
              data-test={RATING_SUMMARY_TOOLTIP}
              ref={refForTooltip}
              isTooltipVisible={isTooltipVisible}
            >
              <Icon
                iconName={'icon-arrow-chevron-down'}
                iconSize={'24px'}
                color={colorsVariables.GRAY_600}
              />
            </SummaryOpenerWrapper>
          </StarsRatingWrapper>
        </Tooltip>
      ) : (
        <StarsRatingWrapper
          className={
            scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
          }
        >
          <Stars
            filledValue={0}
            iconSize={'22px'}
            iconSizeDesktop={scrollingAnimationEnabled ? '20px' : '32px'}
          />
          <AverageRating
            className={
              scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
            }
          />
        </StarsRatingWrapper>
      )}
    </RatingsAreaStyled>
  );
};
