import { dateAgo as dateAgoFunction } from 'helpers/dateAgo';
import { IntlShape } from 'react-intl';

export function transformDateAgo(
  dateAgo: ReturnType<typeof dateAgoFunction>,
  formatMessage: IntlShape['formatMessage']
) {
  if (!dateAgo) {
    return null;
  }

  if (typeof dateAgo === 'string') {
    return dateAgo;
  }

  if (!dateAgo?.key) {
    return null;
  }

  return formatMessage(
    { id: dateAgo?.key },
    {
      amount: dateAgo?.value
    }
  );
}
