// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrafficPackage
 */
export interface TrafficPackage {
    /**
     * 
     * @type {string}
     * @memberof TrafficPackage
     */
    voucherCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPackage
     */
    voucherValue?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPackage
     */
    campaignText?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPackage
     */
    deepLinkUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficPackage
     */
    deepLinkLabel?: string;
}

export function TrafficPackageFromJSON(json: any): TrafficPackage {
    return TrafficPackageFromJSONTyped(json, false);
}

export function TrafficPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficPackage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'voucherCode': !exists(json, 'voucherCode') ? undefined : json['voucherCode'],
        'voucherValue': !exists(json, 'voucherValue') ? undefined : json['voucherValue'],
        'campaignText': !exists(json, 'campaignText') ? undefined : json['campaignText'],
        'deepLinkUrl': !exists(json, 'deepLinkUrl') ? undefined : json['deepLinkUrl'],
        'deepLinkLabel': !exists(json, 'deepLinkLabel') ? undefined : json['deepLinkLabel'],
    };
}

export function TrafficPackageToJSON(value?: TrafficPackage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voucherCode': value.voucherCode,
        'voucherValue': value.voucherValue,
        'campaignText': value.campaignText,
        'deepLinkUrl': value.deepLinkUrl,
        'deepLinkLabel': value.deepLinkLabel,
    };
}

