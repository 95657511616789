import styled from 'styled-components';
import freeAccountProfileBadge from './freeAccountProfileBadge.svg';

// Implementation inspired by: https://medium.com/@ians/rendering-svgs-as-images-directly-in-react-a26615c45770

const encodedImportReview = Buffer.from(freeAccountProfileBadge).toString(
  'base64'
);

/* istanbul ignore */
export const FreeAccountProfileBadge = styled.div<{ iconSize?: string }>`
  background: url('data:image/svg+xml;base64,${encodedImportReview}') no-repeat
    center;
  background-size: contain;
  min-width: ${(props) => props.iconSize || '24px'};
  height: ${(props) => props.iconSize || '24px'};
`;
