import {
  Button,
  fontVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import styled from 'styled-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const SubmitReview = styled.div<{
  scrollingAnimationEnabled?: boolean;
  hasNoReviews: boolean;
}>`
  grid-area: submit-review;
  display: grid;
  min-width: fit-content;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-bottom: ${gutterVariables.GUTTER_DOUBLE};
  width: 100%;

  ${(props) =>
    props.scrollingAnimationEnabled &&
    `
    margin-bottom: 0;
    width: max-content;
  `};

  ${(props) =>
    props.hasNoReviews &&
    `
    margin-bottom: 0;
    
    ${minWidth(
      'md',
      `
      margin-bottom: ${gutterVariables.GUTTER_DOUBLE};
    `
    )};
    
    &.scrolling-animation-enabled {
        margin-bottom: 0;
      }
  `};

  ${minWidth(
    'xxl',
    `
      width: max-content;
      margin: 0;
    `
  )};
`;

export const SubmitReviewButtonText = styled.span`
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
`;

export const SubmitReviewButton = styled(Button)<{
  scrollingAnimationEnabled?: boolean;
}>`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1 / 1 / 2 / 3;
  height: 48px;
  gap: 8px;
  padding: 0 24px 0 16px;

  & * {
    margin: 0;
  }

  ${(props) =>
    props.scrollingAnimationEnabled &&
    `
    height: ${gutterVariables.GUTTER_DOUBLE};
    font-size: ${fontVariables.SIZE_TEXT_S};
  `};
`;
