import styled from 'styled-components';
import Link from 'next/link';
import {
  colorsVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const LinkPill = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 5px 8px 5px 16px;
  width: fit-content;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};

  :hover,
  :active,
  :visited,
  :focus {
    text-decoration: none;
    background-color: ${colorsVariables.GRAY_050};
    border-radius: 32px;
    color: ${colorsVariables.BLUE_700};
  }

  span {
    :hover {
      text-decoration: none;
      color: ${colorsVariables.BLUE_700};
    }
  }
`;
