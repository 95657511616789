import styled from 'styled-components';
import { ReviewInbox } from 'components/Illustrations/ReviewInbox/ReviewInbox.styles';
import { minWidth } from 'helpers/buildMediaQueries';
import {
  Heading,
  LinkAsButton,
  flexColumnCenteredItems,
  gutterVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { SearchMagnifier } from 'components/Illustrations/SearchMagnifier/SearchMagnifier.styles';

const imgSize = '160px';

export const NoReviewsForShop = styled.div`
  ${flexColumnCenteredItems};
  text-align: center;
  margin-top: 40px;
  margin-bottom: 64px;

  ${SearchMagnifier},
  ${ReviewInbox} {
    display: inline-block;
    width: ${imgSize};
    height: ${imgSize};
  }
`;

export const AdditionalInfo = styled.div`
  ${flexColumnCenteredItems};
`;

export const Subtitle = styled.span`
  font-size: ${fontVariables.SIZE_TEXT};
  margin-top: ${gutterVariables.GUTTER_HALF};
`;

export const Title = styled(Heading)`
  font-size: ${fontVariables.SIZE_TEXT};
  margin-top: ${gutterVariables.GUTTER};
`;

export const AllProfilesButton = styled(LinkAsButton)`
  display: flex;
  justify-content: center;
  width: fit-content;
  padding: ${gutterVariables.GUTTER_HALF} ${gutterVariables.GUTTER};
  margin-bottom: 8px;
  height: auto;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  font-size: ${fontVariables.SIZE_TEXT_S};
  line-height: 16px;

  ${minWidth('sm', 'width: fit-content;')}
`;
