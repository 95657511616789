// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    ContactData,
    ContactDataFromJSON,
    ContactDataFromJSONTyped,
    ContactDataToJSON,
    Keyword,
    KeywordFromJSON,
    KeywordFromJSONTyped,
    KeywordToJSON,
    Language,
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
    LegalInformation,
    LegalInformationFromJSON,
    LegalInformationFromJSONTyped,
    LegalInformationToJSON,
    MemberDetails,
    MemberDetailsFromJSON,
    MemberDetailsFromJSONTyped,
    MemberDetailsToJSON,
    Organization,
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
    ProfileType,
    ProfileTypeFromJSON,
    ProfileTypeFromJSONTyped,
    ProfileTypeToJSON,
    ReviewStatistic,
    ReviewStatisticFromJSON,
    ReviewStatisticFromJSONTyped,
    ReviewStatisticToJSON,
    SocialMediaLinkList,
    SocialMediaLinkListFromJSON,
    SocialMediaLinkListFromJSONTyped,
    SocialMediaLinkListToJSON,
    TargetMarket,
    TargetMarketFromJSON,
    TargetMarketFromJSONTyped,
    TargetMarketToJSON,
    TrafficPackage,
    TrafficPackageFromJSON,
    TrafficPackageFromJSONTyped,
    TrafficPackageToJSON,
} from './';

/**
 * Profile object with all properties that can be read
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * Encoded version of the company's url.
     * @type {string}
     * @memberof Profile
     */
    encodedUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    channelId: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    tsId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    contractStartDate?: number;
    /**
     * 
     * @type {TargetMarket}
     * @memberof Profile
     */
    targetMarket: TargetMarket;
    /**
     * 
     * @type {Language}
     * @memberof Profile
     */
    language: Language;
    /**
     * 
     * @type {Organization}
     * @memberof Profile
     */
    organization: Organization;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    description: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    address: Address | null;
    /**
     * 
     * @type {ContactData}
     * @memberof Profile
     */
    contactData: ContactData | null;
    /**
     * generated questionnaire link for specificly to the profile.
     * @type {string}
     * @memberof Profile
     */
    questionnaireLink: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    logoUrl: string | null;
    /**
     * 
     * @type {ReviewStatistic}
     * @memberof Profile
     */
    reviewStatistic: ReviewStatistic;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    verificationDeepLink?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Profile
     */
    categories: Array<Category>;
    /**
     * 
     * @type {Array<SocialMediaLinkList>}
     * @memberof Profile
     */
    socialMediaLinks?: Array<SocialMediaLinkList>;
    /**
     * 
     * @type {Array<Keyword>}
     * @memberof Profile
     */
    keywords: Array<Keyword>;
    /**
     * 
     * @type {ProfileType}
     * @memberof Profile
     */
    profileType: ProfileType;
    /**
     * 
     * @type {TrafficPackage}
     * @memberof Profile
     */
    trafficPackage: TrafficPackage;
    /**
     * 
     * @type {LegalInformation}
     * @memberof Profile
     */
    legalInformation: LegalInformation | null;
    /**
     * 
     * @type {MemberDetails}
     * @memberof Profile
     */
    memberDetails: MemberDetails | null;
}

export function ProfileFromJSON(json: any): Profile {
    return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'encodedUrl': json['encodedUrl'],
        'channelId': json['channelId'],
        'tsId': json['tsId'],
        'name': json['name'],
        'contractStartDate': !exists(json, 'contractStartDate') ? undefined : json['contractStartDate'],
        'targetMarket': TargetMarketFromJSON(json['targetMarket']),
        'language': LanguageFromJSON(json['language']),
        'organization': OrganizationFromJSON(json['organization']),
        'url': json['url'],
        'description': json['description'],
        'address': AddressFromJSON(json['address']),
        'contactData': ContactDataFromJSON(json['contactData']),
        'questionnaireLink': json['questionnaireLink'],
        'logoUrl': json['logoUrl'],
        'reviewStatistic': ReviewStatisticFromJSON(json['reviewStatistic']),
        'verificationDeepLink': !exists(json, 'verificationDeepLink') ? undefined : json['verificationDeepLink'],
        'categories': ((json['categories'] as Array<any>).map(CategoryFromJSON)),
        'socialMediaLinks': !exists(json, 'socialMediaLinks') ? undefined : ((json['socialMediaLinks'] as Array<any>).map(SocialMediaLinkListFromJSON)),
        'keywords': ((json['keywords'] as Array<any>).map(KeywordFromJSON)),
        'profileType': ProfileTypeFromJSON(json['profileType']),
        'trafficPackage': TrafficPackageFromJSON(json['trafficPackage']),
        'legalInformation': LegalInformationFromJSON(json['legalInformation']),
        'memberDetails': MemberDetailsFromJSON(json['memberDetails']),
    };
}

export function ProfileToJSON(value?: Profile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'encodedUrl': value.encodedUrl,
        'channelId': value.channelId,
        'tsId': value.tsId,
        'name': value.name,
        'contractStartDate': value.contractStartDate,
        'targetMarket': TargetMarketToJSON(value.targetMarket),
        'language': LanguageToJSON(value.language),
        'organization': OrganizationToJSON(value.organization),
        'url': value.url,
        'description': value.description,
        'address': AddressToJSON(value.address),
        'contactData': ContactDataToJSON(value.contactData),
        'questionnaireLink': value.questionnaireLink,
        'logoUrl': value.logoUrl,
        'reviewStatistic': ReviewStatisticToJSON(value.reviewStatistic),
        'verificationDeepLink': value.verificationDeepLink,
        'categories': ((value.categories as Array<any>).map(CategoryToJSON)),
        'socialMediaLinks': value.socialMediaLinks === undefined ? undefined : ((value.socialMediaLinks as Array<any>).map(SocialMediaLinkListToJSON)),
        'keywords': ((value.keywords as Array<any>).map(KeywordToJSON)),
        'profileType': ProfileTypeToJSON(value.profileType),
        'trafficPackage': TrafficPackageToJSON(value.trafficPackage),
        'legalInformation': LegalInformationToJSON(value.legalInformation),
        'memberDetails': MemberDetailsToJSON(value.memberDetails),
    };
}

