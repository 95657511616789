import styled from 'styled-components';

export const SocialMediaList = styled.ul`
  display: flex;
  list-style: none;
  gap: 16px;
  margin: 24px 0 0 0;
  padding: 0;
  flex-wrap: wrap;
`;
