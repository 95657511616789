import { ShopApiResponse } from 'models/ShopApiResponse';
import { ShopApiRequestParams } from 'models/ShopApiRequestParams';
import { determineShopSearchApiUrl } from './urls';
import { Environment } from 'models/Environment';
import { TargetMarket } from 'models/TargetMarket';
import { STATUS_CODES } from 'helpers/statusCodes';

export const getShopsFromApi = async ({
  category,
  pageSize,
  environment,
  targetMarket
}: ShopApiRequestParams & {
  environment: Environment;
  targetMarket: TargetMarket;
}): Promise<ShopApiResponse> => {
  const url = new URL(determineShopSearchApiUrl(environment));
  if (category) {
    url.searchParams.append('categories', category);
  }
  if (pageSize) {
    url.searchParams.append('pageSize', pageSize);
  }

  url.searchParams.append('targetMarket', targetMarket);

  const response: Response = await fetch(url.toString());

  if (!response.ok && response.status === STATUS_CODES.NOT_FOUND) {
    throw STATUS_CODES.GONE;
  }

  if (!response.ok && response.status >= STATUS_CODES.BAD_REQUEST) {
    throw response.status;
  }

  return (await response.json()) as ShopApiResponse;
};
