import { FooterImprintStructure } from 'models/FooterStructure';

export function addETrustedImprintLink(
  footerImprintStructure: FooterImprintStructure,
  imprintLink: string
) {
  const imprintItem = footerImprintStructure.find(
    (item) => item.ID === 'imprint'
  );
  if (imprintItem) {
    imprintItem.LINK = imprintLink;
  }
}
