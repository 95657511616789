import {
  NavItem,
  NavItemAnchor,
  NavItemList,
  Slider
} from 'components/CompanyHeader/PageNavigation/PageNavigation.styles';
import { useIntl } from 'react-intl';
import {
  ANIMATED_COMPANY_HEADER_ID,
  NAV_ITEMS_ANCHOR_CONFIG,
  PAGE_TOP_ID
} from 'services/page-section-ids';
import { useScrollspy } from 'hooks/useScrollspy';
import React, { useEffect, useState } from 'react';
import { ScrollDirection } from 'models/ScrollDirection';
import { useStore } from 'context/store';

const navigationItemsConfig = [
  {
    labelKey: 'COMPANYPROFILES.PAGE_NAVIGATION.OVERVIEW',
    belongingAnchorIds: [
      NAV_ITEMS_ANCHOR_CONFIG.overview,
      NAV_ITEMS_ANCHOR_CONFIG.certification,
      NAV_ITEMS_ANCHOR_CONFIG.topFlop
    ]
  },
  {
    labelKey: 'COMPANYPROFILES.PAGE_NAVIGATION.REVIEWS',
    belongingAnchorIds: [NAV_ITEMS_ANCHOR_CONFIG.reviews]
  },
  {
    labelKey: 'COMPANYPROFILES.PAGE_NAVIGATION.COMPANY_DETAILS',
    belongingAnchorIds: [NAV_ITEMS_ANCHOR_CONFIG.companyDetails]
  }
];

export const PageNavigation = ({
  id,
  scrollingAnimationEnabled,
  pageHeaderRef
}: {
  id: string;
  scrollingAnimationEnabled?: boolean;
  pageHeaderRef: any;
}) => {
  const { currentPageSectionIdPosition, setCurrentPageSectionIdPosition } =
    useStore();
  const { formatMessage } = useIntl();
  const [windowInnerWidth, setWindowInnerWidth] = useState(0);
  const headerOffsetInPx = pageHeaderRef?.current?.clientHeight;
  const [currentActiveId, setCurrentActiveId] = useState(PAGE_TOP_ID);
  const activeId = useScrollspy(
    [
      NAV_ITEMS_ANCHOR_CONFIG.overview,
      NAV_ITEMS_ANCHOR_CONFIG.certification,
      NAV_ITEMS_ANCHOR_CONFIG.topFlop,
      NAV_ITEMS_ANCHOR_CONFIG.reviews,
      NAV_ITEMS_ANCHOR_CONFIG.companyDetails
    ],
    headerOffsetInPx,
    windowInnerWidth > 575 ? 300 : 180
  );
  const topOfPageIds = [
    NAV_ITEMS_ANCHOR_CONFIG.overview,
    NAV_ITEMS_ANCHOR_CONFIG.certification,
    NAV_ITEMS_ANCHOR_CONFIG.topFlop
  ];

  useEffect(() => {
    setWindowInnerWidth(window.innerWidth);

    if (activeId && windowInnerWidth && windowInnerWidth < 1440) {
      if (topOfPageIds.indexOf(activeId as NAV_ITEMS_ANCHOR_CONFIG) > -1) {
        history.pushState({}, '', `#${NAV_ITEMS_ANCHOR_CONFIG.overview}`);
      } else {
        // Changes the url hash of the page without scrolling to the id container
        history.pushState({}, '', `#${activeId}`);

        if (scrollingAnimationEnabled) {
          window.onscrollend = () => {
            if (currentActiveId === activeId) return;
            focusNavItem(
              document.getElementById(
                `${ANIMATED_COMPANY_HEADER_ID}-${activeId}-nav-item`
              )
            );
          };
        }
      }
    }

    setCurrentActiveId(activeId || PAGE_TOP_ID);
  }, [activeId]);

  function isActiveClass(
    anchorIds: NAV_ITEMS_ANCHOR_CONFIG[],
    activeId: any
  ): boolean {
    return anchorIds.indexOf(activeId) !== -1;
  }

  const handleItemClick = (event: any, anchorId: NAV_ITEMS_ANCHOR_CONFIG) => {
    event.preventDefault();
    event.stopPropagation();

    const html = document.getElementsByTagName('html')[0];
    const nextScrollDirection = calculateNextScrollDirection(anchorId);

    if (!html && !pageHeaderRef) return;
    if (!pageHeaderRef) {
      html.style.removeProperty('scroll-padding-top');
    }

    if (windowInnerWidth > 575) {
      html.style.scrollPaddingTop =
        nextScrollDirection === ScrollDirection.down
          ? `${headerOffsetInPx - 95}px`
          : `${headerOffsetInPx + 10}px`;
    } else {
      html.style.scrollPaddingTop =
        nextScrollDirection === ScrollDirection.down ? '80px' : '175px';
    }

    const pageSectionElementToScrollTo = document.getElementById(anchorId);
    pageSectionElementToScrollTo?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const focusNavItem = (activeNavItem: HTMLElement | null) => {
    if (!windowInnerWidth || !activeNavItem) return;

    const pageNavigation = document.getElementById(
      `${ANIMATED_COMPANY_HEADER_ID}-page-navigation`
    );
    const elementDomRect = activeNavItem.getBoundingClientRect();
    const horizontalScrollPosition =
      getElementHorizontalPositionToScroll(elementDomRect);

    if (pageNavigation) {
      pageNavigation.scrollLeft = horizontalScrollPosition;
    }
  };

  const getElementHorizontalPositionToScroll = (elementDomRect: DOMRect) => {
    if (Math.floor(elementDomRect.right) === windowInnerWidth) return 1000;
    if (Math.floor(elementDomRect.right) > windowInnerWidth) {
      return elementDomRect.right - windowInnerWidth;
    }

    return 0;
  };

  const calculateNextScrollDirection = (
    anchorIdToScrollTo: NAV_ITEMS_ANCHOR_CONFIG
  ) => {
    const nextActiveIdPosition =
      document.getElementById(anchorIdToScrollTo)?.offsetTop || 0;

    const scrollDirection =
      nextActiveIdPosition >= currentPageSectionIdPosition
        ? ScrollDirection.down
        : ScrollDirection.up;

    setCurrentPageSectionIdPosition(nextActiveIdPosition);

    return scrollDirection;
  };

  return (
    <NavItemList
      data-test="COMPANY_HEADER_HIDE"
      scrollingAnimationEnabled={scrollingAnimationEnabled}
      id={id + '-page-navigation'}
    >
      {navigationItemsConfig.map((navItem) => {
        return (
          <NavItem
            key={navItem.belongingAnchorIds[0]}
            id={`${id}-${navItem.belongingAnchorIds[0]}-nav-item`}
          >
            <NavItemAnchor
              id={navItem.belongingAnchorIds[0] + '-button'}
              href={'#' + navItem.belongingAnchorIds[0]}
              onClick={(e) => handleItemClick(e, navItem.belongingAnchorIds[0])}
              className={
                isActiveClass(navItem.belongingAnchorIds, activeId)
                  ? 'activeSection'
                  : ''
              }
            >
              {formatMessage({ id: navItem.labelKey })}
            </NavItemAnchor>
            <Slider
              scrollingAnimationEnabled={scrollingAnimationEnabled}
              className={
                isActiveClass(navItem.belongingAnchorIds, activeId)
                  ? 'activeSection'
                  : ''
              }
            />
          </NavItem>
        );
      })}
    </NavItemList>
  );
};
