import styled from 'styled-components';
import searchMagnifier from './search-magnifier.svg';

// Implementation inspired by: https://medium.com/@ians/rendering-svgs-as-images-directly-in-react-a26615c45770

const encodedSearchMagnifier = Buffer.from(searchMagnifier).toString('base64');

export const SearchMagnifier = styled.div({
  background: `url("data:image/svg+xml;base64,${encodedSearchMagnifier}")`,
  backgroundRepeat: 'no-repeat'
});
