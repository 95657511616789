import {
  Context,
  Modal
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { useIntl } from 'react-intl';
import {
  SubmitReview as SubmitReviewStyled,
  SubmitReviewButton,
  SubmitReviewButtonText
} from './SubmitReview.styles';
import { MODAL_DIALOGUE, SUBMIT_REVIEW } from 'services/data-test-attributes';
import { SubmitReviewIllustration } from 'components/SubmitReview/SubmitReviewIllustration';
import { ResponsiveIframe } from 'components/ResponsiveIframe/ResponsiveIframe.styles';
import React, { useEffect, useState } from 'react';
import { useStore } from 'context/store';

export function SubmitReview({
  scrollingAnimationEnabled,
  hasNoReviews
}: {
  scrollingAnimationEnabled?: boolean;
  hasNoReviews: boolean;
}) {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { profile } = useStore();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const companyHeader = document.getElementById('animated-company-header');

      if (isModalOpen && companyHeader) {
        companyHeader.classList.remove('scrolling-animation-enabled');
      } else if (!isModalOpen && companyHeader) {
        companyHeader.classList.add('scrolling-animation-enabled');
      }
    }
  }, [isModalOpen]);

  return (
    <>
      <SubmitReviewStyled
        scrollingAnimationEnabled={scrollingAnimationEnabled}
        hasNoReviews={hasNoReviews}
        className={
          scrollingAnimationEnabled ? 'scrolling-animation-enabled' : ''
        }
      >
        <SubmitReviewButton
          type="button"
          context={Context.Primary}
          onClick={() => setIsModalOpen(true)}
          data-test={SUBMIT_REVIEW}
          scrollingAnimationEnabled={scrollingAnimationEnabled}
        >
          <SubmitReviewIllustration />
          <SubmitReviewButtonText>
            {formatMessage({
              id: 'COMPANYPROFILES.SUBMIT_REVIEW.CALL_TO_ACTION'
            })}
          </SubmitReviewButtonText>
        </SubmitReviewButton>
      </SubmitReviewStyled>
      <Modal
        setModal={setIsModalOpen}
        title=""
        fixedBottomElements={[]}
        open={isModalOpen}
        maxWidth="700px"
        fullHeight
        paddingForHeadingOnly
        dataTestValue={MODAL_DIALOGUE}
        mobileElementType={'modal'}
      >
        <ResponsiveIframe
          src={profile?.questionnaireLink || undefined}
          style={{ border: 0 }}
        />
      </Modal>
    </>
  );
}
