import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import {
  CertificateInfo as CertificateInfoStyled,
  AboutExpander
} from './CertificateInfo.styles';
import {
  transitionDefaultStyles,
  transitionDuration,
  transitionStyles
} from './CertificateInfo.transitions';
import {
  ABOUT_EXPANDER,
  CERTIFICATE_INFO
} from 'services/data-test-attributes';
import { CertificateHeader } from 'components/CertificateInfo/CertificateHeader/CertificateHeader';
import { CERTIFICATION_AREA_ID } from 'services/page-section-ids';
import dynamic from 'next/dynamic';

const CertificateMainContent = dynamic(
  () =>
    import(
      'components/CertificateInfo/CertificateMainContent/CertificateMainContent'
    )
);

export default function CertificateInfo() {
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  return (
    <CertificateInfoStyled
      data-test={CERTIFICATE_INFO}
      id={CERTIFICATION_AREA_ID}
    >
      <AboutExpander
        data-test={ABOUT_EXPANDER}
        onClick={() => setDetailsExpanded(!detailsExpanded)}
      >
        <CertificateHeader detailsExpanded={detailsExpanded} />
      </AboutExpander>
      <Transition
        in={detailsExpanded}
        timeout={{
          enter: 0,
          exit: transitionDuration
        }}
      >
        {(state) => (
          <div
            style={{
              ...transitionDefaultStyles,
              ...transitionStyles[state]
            }}
          >
            {detailsExpanded && <CertificateMainContent />}
          </div>
        )}
      </Transition>
    </CertificateInfoStyled>
  );
}
