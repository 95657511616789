import React from 'react';
import { ReviewFilterTypes } from 'models/ReviewFilterTypes';
import { Chip } from './Chip.styles';
import { Stars } from '@trustedshops/tps-seo-shared-frontend-react-components';
import {
  ReviewFiltersActive as ReviewFiltersActiveStyled,
  CloseIcon,
  ResetFiltersLink
} from './ReviewFiltersActive.styles';
import { ReviewFilter } from 'models/ReviewFilter';
import Link from 'next/link';
import { StarCount } from './StarCount';
import { REVIEW_FILTERS_ACTIVE } from 'services/data-test-attributes';
import { buildHref } from 'helpers/buildHref';
import { useIntl } from 'react-intl';
import { useStore } from 'context/store';
import { MappedReviewCountsForFilters } from 'helpers/getMappedReviewCountsForFilters';

export function ReviewFiltersActive({
  starsFilters,
  path
}: {
  starsFilters: ReviewFilter[];
  path: string;
}) {
  const { formatMessage } = useIntl();
  const { activeFilters, reviewCountsForFilters } = useStore();
  const activeStarFilters = starsFilters.filter(
    (starFilter) =>
      starFilter.selected &&
      starFilter.type === ReviewFilterTypes.RatingClassification
  );

  return (
    <ReviewFiltersActiveStyled data-test={REVIEW_FILTERS_ACTIVE}>
      {activeStarFilters.map((filter) => {
        const id = `${filter.type}${filter.value}`;
        return (
          <Chip key={id} data-test={id}>
            <Stars filledValue={filter.value} />
            {activeFilters.searchTerm && reviewCountsForFilters ? (
              <StarCount>{`(${
                reviewCountsForFilters[
                  filter.value as unknown as keyof MappedReviewCountsForFilters
                ]
              })`}</StarCount>
            ) : (
              <StarCount>{`(${filter.reviewCountForFilter})`}</StarCount>
            )}
            <Link href={filter.removeHref} scroll={false} rel="nofollow">
              <CloseIcon iconName="icon-action-dismiss" iconSize="20px" />
            </Link>
          </Chip>
        );
      })}
      <ResetFiltersLink href={buildHref(path, {})} scroll={false}>
        <span>{formatMessage({ id: 'FILTERS_RESET_LINK' })}</span>
      </ResetFiltersLink>
    </ReviewFiltersActiveStyled>
  );
}
