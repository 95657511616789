import { ReviewClassificationEnum } from 'models/ReviewClassificationEnum';

export function validateStarsFilterValues(
  values: string | string[] | undefined
): [boolean, number[]] {
  if (!values) {
    return [true, []];
  }
  const stars: string[] = Array.isArray(values) ? values : [values];
  const numberValues: number[] = stars.map((star) =>
    star in ReviewClassificationEnum ? parseInt(star, 10) : NaN
  );
  const starsAllValid: boolean = !numberValues.some((numberValue) =>
    isNaN(numberValue)
  );

  return [starsAllValid, starsAllValid ? numberValues : []];
}
