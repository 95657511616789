import { ReactNode } from 'react';
import { MainContentWrapperStyled } from 'components/MainPageContent/MainPageContent.styles';
import { useStore } from 'context/store';
import { reviewsStateIs } from 'helpers/reviewStateIs';
import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';

export const MainPageContent = ({
  children,
  isCertificateInfoVisible,
  hasNoReviews
}: {
  children: ReactNode;
  isCertificateInfoVisible: boolean;
  hasNoReviews: boolean;
}) => {
  const { profile, isOpenProfile } = useStore();

  const isSummaryVisible = reviewsStateIs(
    profile,
    ReviewsVisibilityState.SummaryIsVisible
  );

  return (
    <MainContentWrapperStyled
      isOpenProfile={isOpenProfile}
      isSummaryVisible={isSummaryVisible}
      isCertificateInfoVisible={isCertificateInfoVisible}
      hasNoReviews={hasNoReviews}
    >
      {children}
    </MainContentWrapperStyled>
  );
};
