/* istanbul ignore file: this file is only for configuration */

import { Environment } from 'models/Environment';
import { ProfilePageProps } from 'models/ProfilePageProps';

/**
 * If currentTime is a page property (i.e. it is returned by a page's getInitialProps),
 * it will be cached in the HTML and stored in the __NEXT_DATA__ global variable.
 *
 * As the cached HTML might be several hours old, we need to re-use the cached currentTime
 * during the client-side hydration. Otherwise, all information based on currentTime
 * (like "n hours ago", "updated on") might change (flicker) during hydration.
 *
 * Also note that, between creating a new Date object and caching/storing it,
 * several milliseconds will pass.
 */
export function determineCurrentTime(environment: Environment): Date {
  if (typeof window !== 'undefined' && window?.__NEXT_DATA__) {
    const { currentTime } = window.__NEXT_DATA__.props
      .pageProps as ProfilePageProps;

    if (currentTime) {
      return new Date(currentTime);
    }
  }

  if (environment === Environment.vrt) {
    const year = 2020;
    const month = 7;
    const day = 14;
    const monthIndex = month - 1;
    return new Date(year, monthIndex, day, 12, 3, 19, 134);
  }

  return new Date();
}
