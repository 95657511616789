import styled from 'styled-components';
import {
  fontVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const ReportReviewTooltip = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 260px;
  padding: 9px 5px;
`;
export const TooltipHeadline = styled.div`
  font-size: ${fontVariables.SIZE_TEXT_S};
  line-height: ${fontVariables.LINE_HEIGHT_S};
  margin-bottom: ${gutterVariables.GUTTER_ONE_QUARTER};
`;
export const TooltipBody = styled.span`
  font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
`;
