export const PinterestIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_12669_6954)">
        <path
          d="M23.625 0H4.375C1.95875 0 0 1.95875 0 4.375V23.625C0 26.0412 1.95875 28 4.375 28H23.625C26.0412 28 28 26.0412 28 23.625V4.375C28 1.95875 26.0412 0 23.625 0Z"
          fill="#E60023"
        />
        <path
          d="M14 7C10.15 7 7 10.15 7 14C7 16.8875 8.75 19.3375 11.2 20.3875C11.2 19.8625 11.2 19.3375 11.2875 18.8125C11.4625 18.2 12.1625 14.9625 12.1625 14.9625C12.1625 14.9625 11.9 14.525 11.9 13.825C11.9 12.775 12.5125 11.9875 13.2125 11.9875C13.825 11.9875 14.175 12.425 14.175 13.0375C14.175 13.65 13.7375 14.6125 13.5625 15.4875C13.3875 16.1875 13.9125 16.8 14.7 16.8C16.0125 16.8 16.8875 15.1375 16.8875 13.0375C16.8875 11.4625 15.8375 10.325 14 10.325C11.9 10.325 10.5875 11.9 10.5875 13.65C10.5875 14.2625 10.7625 14.7 11.025 15.05C11.1125 15.225 11.2 15.225 11.1125 15.4C11.1125 15.4875 11.025 15.8375 10.9375 15.925C10.85 16.1 10.7625 16.1875 10.5875 16.1C9.62501 15.6625 9.1875 14.6125 9.1875 13.3875C9.1875 11.375 10.85 9.0125 14.175 9.0125C16.8875 9.0125 18.6375 10.9375 18.6375 13.0375C18.6375 15.75 17.15 17.85 14.875 17.85C14.0875 17.85 13.3875 17.4125 13.125 16.975C13.125 16.975 12.6875 18.55 12.6 18.9C12.425 19.425 12.1625 19.95 11.9 20.3875C12.5125 20.5625 13.2125 20.65 13.9125 20.65C17.7625 20.65 20.9125 17.5 20.9125 13.65C21 10.15 17.85 7 14 7Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12669_6954">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
