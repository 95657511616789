/* istanbul ignore file: this file is only for configuration */

import { ReactTransitionStates } from 'models/ReactTransitionStates';

export const transitionDuration = 300;

export const transitionDefaultStyles = {
  transition: `${transitionDuration}ms ease-in-out`
};

const entered = {
  overflow: 'visible',
  maxHeight: '600px',
  opacity: 1
};

const exited = {
  overflow: 'hidden',
  maxHeight: '0',
  opacity: 0
};

export const transitionStyles: ReactTransitionStates = {
  entering: entered,
  entered,
  exiting: exited,
  exited
};
