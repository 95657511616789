import styled, { css } from 'styled-components';
import { minWidth } from 'helpers/buildMediaQueries';
import {
  colorsVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const NavItemList = styled.ul<{ scrollingAnimationEnabled?: boolean }>`
  display: flex;
  position: absolute;
  bottom: 0;
  margin: 0 0 -1px 0;
  list-style: none;
  padding: 0;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;

  // To make the horizontal scrollbar disappear visually
  &&::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  ${minWidth(
    'md',
    `
      width: 100%;
      position: relative;
      display: flex;
      width: 634px;
      margin: -56px 0 0 0;
    `
  )};

  ${minWidth(
    'lg',
    `
      width: 754px;
    `
  )};
  ${minWidth('xxl', 'display: none;')};

  ${(props) =>
    props.scrollingAnimationEnabled &&
    css`
      ${minWidth(
        'md',
        `
          margin: -20px 0 0 0;
      `
      )};
    `}
`;
export const NavItem = styled.li`
  display: flex;
  white-space: nowrap;
  position: relative;
`;

export const Slider = styled.div<{ scrollingAnimationEnabled?: boolean }>`
  display: none;
  position: absolute;
  inset-block-end: 0;
  block-size: 2px;
  inline-size: 100%;
  background-color: ${colorsVariables.BLUE_700};
  transition: inset-inline-start 0.25s;

  &.activeSection {
    display: block;
  }

  ${(props) =>
    props.scrollingAnimationEnabled &&
    css`
      block-size: 2px;
    `}
`;

export const NavItemAnchor = styled.a`
  padding: 16px;
  font-size: ${fontVariables.SIZE_TEXT_S};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  line-height: ${fontVariables.LINE_HEIGHT_L};
  word-break: normal;

  &,
  :visited {
    color: ${colorsVariables.GRAY_500};
  }

  :active {
    text-decoration: none;
    color: ${colorsVariables.BLUE_700};
  }

  :hover {
    text-decoration: none;

    ${minWidth(
      'sm',
      `
       color: ${colorsVariables.BLUE_700};
    `
    )};
  }

  &.activeSection {
    color: ${colorsVariables.BLUE_700};
  }

  ${minWidth(
    'md',
    `
      padding: 16px 24px;
    `
  )};
`;
