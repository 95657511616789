import styled from 'styled-components';
import { MainBox } from 'components/MainBox/MainBox';
import {
  Trustmark,
  gutterVariables,
  fontVariables,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { Separator } from 'styles/utils/separator.styles';
import { minWidth } from 'helpers/buildMediaQueries';

const imgSize = '46px';

export const CertificateInfo = styled(MainBox)<{ isExpanded?: boolean }>`
  display: block;
  margin: 0;
  padding: ${gutterVariables.GUTTER};
  line-height: ${fontVariables.LINE_HEIGHT_L};
  font-size: ${fontVariables.SIZE_TEXT};

  ${minWidth(
    'md',
    `padding: ${gutterVariables.GUTTER} ${gutterVariables.GUTTER_PLUS_HALF};`
  )};

  ${Trustmark} {
    display: inline-block;
    width: ${imgSize};
    height: ${imgSize};
    min-width: ${imgSize};
    margin-right: ${gutterVariables.GUTTER_HALF};
  }

  a {
    text-decoration: underline;
    color: ${colorsVariables.BLUE_600};
  }
`;

export const ContentSeparator = styled(Separator)`
  margin: ${gutterVariables.GUTTER} 0 ${gutterVariables.GUTTER_PLUS_HALF} 0;
  background: ${colorsVariables.GRAY_100};
`;

export const AboutExpander = styled.div`
  text-align: center;
  font-size: ${fontVariables.SIZE_TEXT_M};
  color: ${colorsVariables.GRAY_700};
  cursor: pointer;
  margin-bottom: 0;
`;
