import { minWidth } from 'helpers/buildMediaQueries';
import styled from 'styled-components';
import { breakpointsVariables } from 'styles/breakpoints.variables';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const CompanyLogo = styled.div<{
  hideAfter?: keyof typeof breakpointsVariables;
}>`
  display: inline-block;
  ${({ hideAfter }) => hideAfter && minWidth(hideAfter, 'display: none')}
  border-radius: 3px;
  background-color: ${colorsVariables.WHITE};
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }
`;
