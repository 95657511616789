export const YouTubeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_12669_4621)">
        <path
          d="M23.625 0H4.375C1.95875 0 0 1.95875 0 4.375V23.625C0 26.0412 1.95875 28 4.375 28H23.625C26.0412 28 28 26.0412 28 23.625V4.375C28 1.95875 26.0412 0 23.625 0Z"
          fill="#FF0000"
        />
        <path
          d="M20.65 10.5875C20.475 9.97501 20.0375 9.53751 19.425 9.36251C18.375 9.10001 13.9125 9.10001 13.9125 9.10001C13.9125 9.10001 9.53751 9.10001 8.40001 9.36251C7.78751 9.53751 7.35 9.97501 7.175 10.5875C7 11.725 7 14 7 14C7 14 7 16.275 7.2625 17.4125C7.4375 18.025 7.875 18.4625 8.4875 18.6375C9.5375 18.9 14 18.9 14 18.9C14 18.9 18.375 18.9 19.5125 18.6375C20.125 18.4625 20.5625 18.025 20.7375 17.4125C21 16.275 21 14 21 14C21 14 21 11.725 20.65 10.5875ZM12.6 16.1V11.9L16.275 14L12.6 16.1Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12669_4621">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
