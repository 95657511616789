import { ReviewFilter } from 'models/ReviewFilter';
import {
  getReviewCountForStarsFromShopSummary,
  NEGATIVE_STAR_VALUES,
  POSITIVE_STAR_VALUES,
  removeStarFromArray,
  addStarToArray
} from './ReviewArea.helpers';
import { ReviewFilterTypes } from 'models/ReviewFilterTypes';
import { FilterableQueryParams } from 'models/FilterableQueryParams';
import { buildHref } from 'helpers/buildHref';
import { Profile } from 'profile-api';

export function buildStarsFilters(
  profile: Profile,
  activeFilters: FilterableQueryParams,
  pathname: string
): ReviewFilter[] {
  let starsFilters: ReviewFilter[] = [];
  const stars = activeFilters?.stars || [];
  [...POSITIVE_STAR_VALUES, ...NEGATIVE_STAR_VALUES].forEach((starValue) => {
    const filterIsActive = stars.includes(starValue);

    starsFilters.push({
      type: ReviewFilterTypes.RatingClassification,
      value: starValue,
      selected: filterIsActive,
      reviewCountForFilter: getReviewCountForStarsFromShopSummary(
        profile,
        starValue
      ),
      href: buildHref(pathname, {
        stars: filterIsActive
          ? removeStarFromArray(stars, starValue)
          : addStarToArray(stars, starValue),
        sort: activeFilters.sort,
        searchTerm: activeFilters.searchTerm
      }),
      removeHref: buildHref(pathname, {
        stars: removeStarFromArray(stars, starValue),
        sort: activeFilters.sort,
        searchTerm: activeFilters.searchTerm
      })
    });
  });

  return starsFilters;
}
