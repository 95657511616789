import { ReviewClassificationEnum } from 'models/ReviewClassificationEnum';
import { classificationMapper } from 'helpers/classificationMapper';
import { Profile, ReviewStatistic } from 'profile-api';

export const POSITIVE_STAR_VALUES: ReviewClassificationEnum[] = [
  ReviewClassificationEnum.EXCELLENT,
  ReviewClassificationEnum.GOOD
];

export const NEGATIVE_STAR_VALUES: ReviewClassificationEnum[] = [
  ReviewClassificationEnum.FAIR,
  ReviewClassificationEnum.POOR,
  ReviewClassificationEnum.VERY_POOR
];

export function getReviewCountForStarsFromShopSummary(
  profile: Profile,
  ...classifications: number[]
): number {
  if (
    !classifications ||
    classifications.every(
      (classification) => !classification && classification !== 0
    )
  ) {
    return 0;
  }

  if (
    classifications.some(
      (classification) => classification >= 6 || classification < 1
    )
  ) {
    return profile.reviewStatistic.yearlyReviewCount as number;
  }

  return (classifications as [1, 2, 3, 4, 5])
    .map((classification) => classificationMapper.get(classification))
    .map((catChar) =>
      catChar
        ? (profile.reviewStatistic[
            (catChar + 'StarReviewCount') as keyof ReviewStatistic
          ] as number)
        : 0
    )
    .reduce((a, b) => a + b, 0);
}

export function removeStarFromArray(
  activeFilters: ReviewClassificationEnum[],
  value: ReviewClassificationEnum
): ReviewClassificationEnum[] {
  return activeFilters.filter((filter) => filter !== value);
}

export function addStarToArray(
  currentParams: ReviewClassificationEnum[],
  value: ReviewClassificationEnum
): ReviewClassificationEnum[] {
  if (!currentParams || currentParams.length < 1) {
    return [value];
  }

  if (currentParams.includes(value)) {
    return currentParams;
  }

  return [...currentParams, value].sort();
}
