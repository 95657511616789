import React, { useCallback, useState } from 'react';
import ReviewHeader from 'components/Review/ReviewHeader/ReviewHeader';
import {
  BuyerStatement,
  ShowMoreButton,
  Review as ReviewStyled,
  ReviewTitle
} from 'components/Review/Review.styles';
import ReviewMeta from 'components/Review/ReviewMeta/ReviewMeta';
import { ReviewAnswer } from './ReviewAnswer/ReviewAnswer';
import { Review as ReviewType } from 'reviews-api';
import { Icon } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { useIntl } from 'react-intl';

export function isReviewTitleVisible(
  reviewTitle?: string,
  reviewBuyerStatement?: string
) {
  if (!reviewTitle || !reviewBuyerStatement) {
    return false;
  }

  const titleWithoutDots = reviewTitle.replace('...', '').replace('…', '');
  const titleLength = titleWithoutDots.length;
  const buyerStatementFirstPart = reviewBuyerStatement.substring(
    0,
    titleLength
  );

  return titleWithoutDots !== buyerStatementFirstPart;
}

const Review = ({
  review,
  searchTerm
}: {
  review: ReviewType;
  searchTerm?: string;
}) => {
  const { formatMessage } = useIntl();
  const [isBuyerStatementExpanded, setIsBuyerStatementExpanded] =
    useState(false);
  const [isShowMoreButtonDisplayed, setIsShowMoreButtonDisplayed] =
    useState(true);

  const buyerStatementRef = useCallback((node: any) => {
    if (node !== null) {
      const isTextClamed = node.scrollHeight > node.clientHeight;

      setIsShowMoreButtonDisplayed(isTextClamed);
    }
  }, []);

  if (!review?.id) {
    return null;
  }

  function getHighlightedText(reviewText: string, searchTerm?: string) {
    if (!searchTerm || searchTerm === '') {
      return reviewText;
    }

    const parts = reviewText.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((matchingPart, index) => (
      <React.Fragment key={index}>
        {matchingPart.toLowerCase() === searchTerm?.toLowerCase() ? (
          <b>{matchingPart}</b>
        ) : (
          matchingPart
        )}
      </React.Fragment>
    ));
  }

  return (
    <ReviewStyled>
      <ReviewHeader review={review} />
      {isReviewTitleVisible(review.title, review.comment) && (
        <ReviewTitle>{review.title}</ReviewTitle>
      )}
      {review.comment && (
        <>
          <BuyerStatement
            isExpanded={isBuyerStatementExpanded}
            ref={buyerStatementRef}
          >
            {getHighlightedText(review.comment, searchTerm)}
          </BuyerStatement>
          {isShowMoreButtonDisplayed && (
            <ShowMoreButton
              onClick={() =>
                setIsBuyerStatementExpanded(!isBuyerStatementExpanded)
              }
            >
              <span>
                {isBuyerStatementExpanded
                  ? formatMessage({ id: 'REVIEW.SHOW_LESS_BUTTON.LABEL' })
                  : formatMessage({ id: 'REVIEW.SHOW_MORE_BUTTON.LABEL' })}
              </span>
              <Icon
                lineHeight={'18px'}
                iconName={
                  isBuyerStatementExpanded
                    ? 'icon-arrow-chevron-up'
                    : 'icon-arrow-chevron-down'
                }
              />
            </ShowMoreButton>
          )}
        </>
      )}
      <ReviewMeta review={review} justifyContent={'space-between'} />
      {review?.reply?.comment && <ReviewAnswer review={review} />}
    </ReviewStyled>
  );
};

export default Review;
