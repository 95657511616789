import styled from 'styled-components';
import { MainBox } from 'components/MainBox/MainBox';
import { minWidth } from 'helpers/buildMediaQueries';
import {
  colorsVariables,
  fontVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const Review = styled(MainBox)`
  display: block;
  margin-bottom: ${gutterVariables.GUTTER};
  white-space: pre-line;
  line-height: 1.5;
  padding: ${gutterVariables.GUTTER};
  color: ${colorsVariables.GRAY_700};

  ${minWidth(
    'md',
    `padding: ${gutterVariables.GUTTER_PLUS_HALF}; margin-bottom: ${gutterVariables.GUTTER_PLUS_HALF};`
  )}
`;

export const BuyerStatement = styled.div<{ isExpanded: boolean }>`
  ${(props) =>
    !props.isExpanded &&
    `
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
  `};
`;

export const ShowMoreButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: ${fontVariables.SIZE_TEXT_S};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  color: ${colorsVariables.BLUE_700};
  gap: 4px;
  padding: 0;
  cursor: pointer;
  margin-top: ${gutterVariables.GUTTER_HALF};
`;

export const ReviewTitle = styled.h4`
  font-size: ${fontVariables.SIZE_TEXT_M};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  line-height: 34px;
  color: ${colorsVariables.GRAY_900};
  margin-bottom: ${gutterVariables.GUTTER_HALF};
`;
