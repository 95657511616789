import styled from 'styled-components';
import {
  colorsVariables,
  Dropdown,
  fontVariables,
  Icon
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const ReviewSortSelector = styled.div`
  display: flex;
  position: relative;
  white-space: nowrap;
  align-items: center;
`;

export const InfoIcon = styled(Icon)`
  display: flex;

  &&&:before {
    line-height: ${fontVariables.LINE_HEIGHT_S};
  }
`;

export const TooltipHeading = styled.span`
  display: none;
  margin-bottom: 4px;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  color: ${colorsVariables.WHITE};
  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  ${minWidth('md', 'display: block')};
`;

export const TooltipBody = styled.span`
  white-space: break-spaces;
  font-weight: 400;
  color: #344054;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;

  ${minWidth(
    'md',
    `font-size: 14px; line-height: 20px; color: ${colorsVariables.WHITE};`
  )};
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewSortByText = styled.span`
  font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
`;

export const ReviewSortSelectorDropdown = styled(Dropdown)`
  top: calc(100% + 8px);

  ${minWidth('md', 'top: calc(100% + 16px);')};
`;
