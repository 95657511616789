// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Top and Flop reviews API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TopFlopServerResponse,
    TopFlopServerResponseFromJSON,
    TopFlopServerResponseToJSON,
} from '../models';

export interface GetTopFlopRequest {
    tsIdOrChannelRef: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Gets the top and flop review of a company
     */
    async getTopFlopRaw(requestParameters: GetTopFlopRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TopFlopServerResponse>> {
        if (requestParameters.tsIdOrChannelRef === null || requestParameters.tsIdOrChannelRef === undefined) {
            throw new runtime.RequiredError('tsIdOrChannelRef','Required parameter requestParameters.tsIdOrChannelRef was null or undefined when calling getTopFlop.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{tsIdOrChannelRef}/TopFlop.json`.replace(`{${"tsIdOrChannelRef"}}`, encodeURIComponent(String(requestParameters.tsIdOrChannelRef))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopFlopServerResponseFromJSON(jsonValue));
    }

    /**
     * Gets the top and flop review of a company
     */
    async getTopFlop(requestParameters: GetTopFlopRequest, initOverrides?: RequestInit): Promise<TopFlopServerResponse> {
        const response = await this.getTopFlopRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
