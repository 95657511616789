import { RefObject } from 'react';

export const scrollToRef = ({ ref }: { ref: RefObject<HTMLDivElement> }) => {
  if (!ref?.current) {
    return;
  }

  ref.current.scrollIntoView({
    behavior: 'auto'
  });
};
