import styled from 'styled-components';
import {
  colorsVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const Checkbox = styled.input<{ checked: boolean }>`
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: none;
  -webkit-appearance: none;
  float: left;

  &:not(:checked) ~ label:before {
    content: ' ';
  }

  & ~ label {
    outline: none;
    &:before {
      display: inline-block;
      height: 20px;
      width: 20px;
      background: ${colorsVariables.WHITE};
      border: 1px solid ${colorsVariables.GRAY_300};
      border-radius: 5px;
      color: ${colorsVariables.WHITE};
      box-sizing: border-box;
      line-height: ${fontVariables.LINE_HEIGHT};
      text-align: center;
      vertical-align: middle;
      margin-right: 5px;
      margin-top: -2px;
      font-size: 15px;

      ${(props) =>
        props.checked &&
        `
          background: ${colorsVariables.GREEN_500};
          border: 1px solid ${colorsVariables.GREEN_600};
      `};
    }
  }

  & ~ label.hls:before {
    line-height: 18px;
  }

  &:focus ~ label:before {
    border-color: ${colorsVariables.CURACAO};
  }
`;
