import styled from 'styled-components';
import {
  colorsVariables,
  fontVariables,
  gutterVariables,
  Icon
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const ReviewAuthenticityTeaser = styled.div<{
  hasMarginBottom?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${colorsVariables.GRAY_700};
  margin-bottom: ${gutterVariables.GUTTER_PLUS_HALF};
  padding: 8px 16px;
  background-color: ${colorsVariables.BLUE_100};
  border-radius: 8px;
  font-size: ${fontVariables.SIZE_TEXT};
  line-height: ${fontVariables.LINE_HEIGHT_L};

  ${minWidth('lg', 'margin-top: 0;')}

  a,
  a:visited,
  a:hover,
  a:link {
    color: inherit;
    text-decoration: underline;
  }
`;

export const FeedbackInfoCircleIcon = styled(Icon)`
  margin-right: 4px;
`;

export const TeaserLink = styled.a`
  && {
    color: ${colorsVariables.BLUE_700};
  }

  &&:hover {
    color: ${colorsVariables.BLUE_500};
  }
`;
