import { NoReviewsForSearchTerm as NoReviewsForSearchTermStyled } from './NoReviewsForSearchTerm.styles';
import { NoReviewsForShop } from 'components/ReviewArea/NoReviewsForShop/NoReviewsForShop';
import React from 'react';
import { useIntl } from 'react-intl';

export const NoReviewsForSearchTerm = () => {
  const { formatMessage } = useIntl();

  return (
    <NoReviewsForSearchTermStyled>
      <NoReviewsForShop
        title={formatMessage({
          id: 'COMPANYPROFILES.REVIEW_SEARCH.NO_RESULTS.TITLE'
        })}
        subtitle={formatMessage({
          id: 'COMPANYPROFILES.REVIEW_SEARCH.NO_RESULTS.SUBTITLE'
        })}
        illustration="magnifier"
        hideCTA
      />
    </NoReviewsForSearchTermStyled>
  );
};
