// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contains the social media accounts of the profile
 * @export
 * @interface SocialMediaLinkList
 */
export interface SocialMediaLinkList {
    /**
     * 
     * @type {string}
     * @memberof SocialMediaLinkList
     */
    type?: SocialMediaLinkListTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaLinkList
     */
    url?: string;
}

/**
* @export
* @enum {string}
*/
export enum SocialMediaLinkListTypeEnum {
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    X = 'X',
    Youtube = 'YOUTUBE',
    Tiktok = 'TIKTOK',
    Pinterest = 'PINTEREST'
}

export function SocialMediaLinkListFromJSON(json: any): SocialMediaLinkList {
    return SocialMediaLinkListFromJSONTyped(json, false);
}

export function SocialMediaLinkListFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMediaLinkList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function SocialMediaLinkListToJSON(value?: SocialMediaLinkList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'url': value.url,
    };
}

