import { useIntl } from 'react-intl';
import { useStore } from 'context/store';
import {
  LinkInTooltip,
  VerifiedReviewTooltipContent as VerifiedReviewTooltipContentStyled
} from 'components/Review/ReviewProvider/ReviewProvider.styles';
import React from 'react';

export default function VerifiedReviewTooltipContent({
  isTooltipOnMobile
}: {
  isTooltipOnMobile?: boolean;
}) {
  const { formatMessage } = useIntl();
  const { profile } = useStore();

  return (
    <VerifiedReviewTooltipContentStyled isTooltipOnMobile={isTooltipOnMobile}>
      <span>
        {formatMessage(
          {
            id: 'REVIEW_PROVIDER.VERIFIED_REVIEW.TOOLTIP.BODY_1'
          },
          { companyName: profile.name }
        )}{' '}
        <LinkInTooltip
          href={formatMessage({
            id: 'REVIEW_PROVIDER.VERIFIED_REVIEW.TOOLTIP.LINK.URL'
          })}
          target={'_blank'}
          rel={'noreferrer nofollow'}
        >
          {formatMessage({
            id: 'REVIEW_PROVIDER.VERIFIED_REVIEW.TOOLTIP.LINK.LABEL'
          })}{' '}
        </LinkInTooltip>
      </span>
      <span>
        {formatMessage({
          id: 'REVIEW_PROVIDER.VERIFIED_REVIEW.TOOLTIP.BODY_2',
          defaultMessage: ' '
        })}
      </span>
    </VerifiedReviewTooltipContentStyled>
  );
}
