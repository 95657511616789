import { STATUS_CODES } from '@trustedshops/tps-seo-shared-frontend-react-components';

const MAX_TRY_COUNT = 5;
const WAITING_TIME_IN_MS_BEFORE_RETRY = 1000;

export async function fetchWithRetry(
  url: string,
  requestInit: RequestInit,
  {
    tryNumber = 1,
    maxTryCount = MAX_TRY_COUNT,
    waitingTimeInMsBeforeRetry = WAITING_TIME_IN_MS_BEFORE_RETRY
  }: {
    tryNumber?: number;
    maxTryCount?: number;
    waitingTimeInMsBeforeRetry?: number;
  } = {}
): Promise<Response> {
  const response = await fetch(url, requestInit);
  if (
    response.status < STATUS_CODES.INTERNAL_SERVER_ERROR ||
    tryNumber >= maxTryCount
  ) {
    return response;
  }
  console.warn(`Retrying requesting ${url}`);
  return new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          fetchWithRetry(url, requestInit, {
            tryNumber: tryNumber + 1,
            maxTryCount,
            waitingTimeInMsBeforeRetry
          })
        ),
      waitingTimeInMsBeforeRetry
    );
  });
}
