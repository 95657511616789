import styled from 'styled-components';
import {
  Heading,
  fontVariables,
  gutterVariables,
  colorsVariables,
  LinkAsButton,
  Icon
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const CompanyName = styled(Heading)`
  margin-bottom: ${gutterVariables.GUTTER};
  font-size: ${fontVariables.SIZE_TEXT_M};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  line-height: ${fontVariables.LINE_HEIGHT_L};
`;

export const CompanyDescription = styled.div<{
  isExpanded: boolean;
  isShowMoreButtonDisplayed?: boolean;
}>`
  font-size: ${fontVariables.SIZE_TEXT};
  font-weight: 400;
  line-height: 1.7;
  color: ${colorsVariables.GRAY_700};
  margin-bottom: ${(props) =>
    props.isShowMoreButtonDisplayed ? '4px' : gutterVariables.GUTTER};

  ${(props) =>
    !props.isExpanded &&
    `
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      
      ${minWidth(
        'xxl',
        `
      -webkit-line-clamp: 8;
    `
      )};
  `};
`;

export const ShowMoreShowLessButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: ${fontVariables.SIZE_TEXT_S};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  color: ${colorsVariables.BLUE_700};
  gap: 4px;
  padding: 0;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const ExternalLinkAsButton = styled(LinkAsButton)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${gutterVariables.GUTTER};
  height: 32px;
  border: 1px solid;
`;

export const GoToCompanyCTAText = styled.span`
  font-size: ${fontVariables.SIZE_TEXT_S};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
`;

export const ExternalLinkIcon = styled(Icon)`
  && {
    text-decoration: none;
    margin: 12px 14px 14px -14px;
  }

  &&:before {
    :hover {
      text-decoration: none;
    }
  }

  &&:hover {
    text-decoration: none;
  }
`;
