// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegalInformation
 */
export interface LegalInformation {
    /**
     * 
     * @type {string}
     * @memberof LegalInformation
     */
    jurisdiction: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInformation
     */
    responsibleEntity: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInformation
     */
    commercialRegisterNumber: string;
}

export function LegalInformationFromJSON(json: any): LegalInformation {
    return LegalInformationFromJSONTyped(json, false);
}

export function LegalInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jurisdiction': json['jurisdiction'],
        'responsibleEntity': json['responsibleEntity'],
        'commercialRegisterNumber': json['commercialRegisterNumber'],
    };
}

export function LegalInformationToJSON(value?: LegalInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jurisdiction': value.jurisdiction,
        'responsibleEntity': value.responsibleEntity,
        'commercialRegisterNumber': value.commercialRegisterNumber,
    };
}

