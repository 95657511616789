export function getCanonicalHref(path: string, homeUrl: string) {
  const pathSliceParams = Math.min.apply(Math, [
    path.indexOf('?') > 0 ? path.indexOf('?') : path.length,
    path.indexOf('#') > 0 ? path.indexOf('#') : path.length
  ]);

  return `${homeUrl}${path.substring(0, pathSliceParams)}`;
}

export function getCanonicalMemberHref(
  path: string,
  homeUrl: string,
  tsId: string
) {
  const pathPrefix = path.substring(0, path.lastIndexOf('/'));

  return `${homeUrl}${pathPrefix}/info_${tsId}.html`;
}
