import { FilterableQueryParams } from 'models/FilterableQueryParams';

export function buildHref(
  path: string,
  { page, sort, stars, searchTerm }: FilterableQueryParams
): {
  pathname: string;
  query: FilterableQueryParams;
} {
  let pathname = path;

  let query: FilterableQueryParams = {};
  if (!pathname.endsWith('/')) {
    pathname += '/';
  }
  if (stars && stars.length > 0) {
    query.stars = stars;
  }
  if (sort) {
    query.sort = sort;
  }
  if (page && page > 1) {
    query.page = page;
  }
  if (searchTerm && searchTerm !== '') {
    query.searchTerm = searchTerm;
  }
  return {
    pathname,
    query
  };
}
