import dynamic from 'next/dynamic';
import { ReviewResponse } from 'models/ReviewResponse';
import { TopFlopServerResponse } from 'models/TopFlopServerResponse';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useStore } from 'context/store';
import { RouteChangeHandler } from 'components/ReviewArea/RouteChangeHandler';
import { ReviewFilter } from 'models/ReviewFilter';
import { buildStarsFilters } from 'components/ReviewArea/buildStarsFilters';
import { ReviewLikeService } from 'services/ReviewLikeService';
import { getMappedReviewCountsForFilters } from 'helpers/getMappedReviewCountsForFilters';
import { scrollToRef } from 'helpers/scrollTo';
import { buildHref } from 'helpers/buildHref';
import {
  FilterBar,
  FilterSection,
  FiltersWrapper,
  PaginationWrapper,
  ReviewAreaHeading,
  SearchBarWrapper,
  SectionIdContainer
} from 'components/ReviewArea/ReviewArea.styles';
import { REVIEW_AREA_ID, TOP_FLOP_ID } from 'services/page-section-ids';
import {
  DesktopAndTabletOnlyWrapper,
  MobileOnlyWrapper
} from 'styles/utils/deviceOnlyWrapper';
import { ReviewAuthenticityTeaser } from 'components/ReviewArea/ReviewAuthenticityTeaser/ReviewAuthenticityTeaser';
import { ReviewTopFlop } from 'components/ReviewTopFlop/ReviewTopFlop';
import { REVIEW, REVIEW_FILTER_SECTION } from 'services/data-test-attributes';
import {
  LoadingLinesCards,
  Pagination,
  SearchField
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { ReviewFilterSelector } from 'components/ReviewArea/ReviewFilterSelector/ReviewFilterSelector';
import { ReviewSortSelector } from 'components/ReviewArea/ReviewSortSelector/ReviewSortSelector';
import { ReviewFiltersActive } from 'components/ReviewArea/ReviewFiltersActive';
import { ReviewLoadingLinesCardConfig } from 'models/ReviewLoadingLinesCardConfig';
import { Review as ShopReview } from 'models/Review';
import { ReviewsNotFound } from 'components/ReviewArea/ReviewsNotFound/ReviewsNotFound';
import { NoReviewsForSearchTerm } from 'components/ReviewArea/NoReviewsForSearchTerm/NoReviewsForSearchTerm';
import Review from 'components/Review/Review';

const ReviewAuthenticity = dynamic(
  () => import('components/ReviewArea/ReviewAuthenticity/ReviewAuthenticity'),
  { ssr: false }
);

const ReviewCookieHintModal = dynamic(
  () =>
    import(
      'components/ReviewArea/ReviewAreaWithReviews/ReviewCookieHintModal/ReviewCookieHintModal'
    ),
  { ssr: false }
);

const ReviewLikeModal = dynamic(
  () =>
    import(
      'components/ReviewArea/ReviewAreaWithReviews/ReviewLikeModal/ReviewLikeModal'
    ),
  { ssr: false }
);

export const ReviewAreaWithReviews = ({
  reviews,
  topFlopReviews,
  children
}: {
  reviews?: ReviewResponse;
  topFlopReviews: TopFlopServerResponse;
  children: ReactNode;
}) => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const {
    profile,
    activeFilters,
    pageCount,
    path,
    sortSelectionDefault,
    environment,
    reviewLikeFunctionalityEnabled,
    setReviewLikeFunctionalityEnabled,
    callbacks,
    setActiveFilters,
    setReviewCountsForFilters
  } = useStore();
  const filterAreaRef = useRef<HTMLDivElement>(null);
  const reviewAuthenticityRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  /* istanbul ignore next: will be covered by VRTs (if at all) */
  useEffect(() => {
    const handleStart: RouteChangeHandler = (_url, { shallow }) => {
      // Loading animation should not appear if router.replace with { shallow: true }
      // has been used (= when routeNeedsToBeReplaced returns true)
      if (!shallow) {
        setIsLoading(true);
      }
    };
    const handleStop: RouteChangeHandler = (_url, { shallow }) => {
      if (!shallow) {
        setIsLoading(false);
      }
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  const starsFilters: ReviewFilter[] = buildStarsFilters(
    profile,
    activeFilters,
    path
  );

  const reviewLikeService = new ReviewLikeService(environment);

  /* istanbul ignore next: has been covered by VRTs */
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const events = ['UC_UI_INITIALIZED', 'cookieSettingsChanged'];
    const listener = (event: any) => {
      if (event.type === 'UC_UI_INITIALIZED') {
        const isReviewsLikeAccepted = reviewLikeService.reviewCookiesAccepted;
        setReviewLikeFunctionalityEnabled(isReviewsLikeAccepted);
      }

      if (event.type === 'cookieSettingsChanged') {
        const isReviewsLikeAccepted = reviewLikeService.reviewCookiesAccepted;

        if (isReviewsLikeAccepted !== reviewLikeFunctionalityEnabled) {
          setReviewLikeFunctionalityEnabled(isReviewsLikeAccepted);
        }
      }
    };

    events.forEach((event) =>
      window.addEventListener(event, (event) => listener(event))
    );

    return () => {
      events.forEach((event) => window.removeEventListener(event, listener));
    };
  });

  /* istanbul ignore next: has been covered by VRTs */
  useEffect(() => {
    if (reviews && reviews.metaData) {
      setReviewCountsForFilters(
        getMappedReviewCountsForFilters(reviews.metaData.counts)
      );
    }
  }, [reviews, setReviewCountsForFilters]);

  /* istanbul ignore next: has been covered by VRTs */
  useEffect(() => {
    if (reviews && reviews.metaData) {
      setReviewCountsForFilters(
        getMappedReviewCountsForFilters(reviews.metaData.counts)
      );
    }
  }, [reviews]);

  callbacks.scrollToReviews = () => scrollToRef({ ref: filterAreaRef });

  function handleSearchReviews(query: string) {
    if (setActiveFilters) {
      setActiveFilters({ ...activeFilters, searchTerm: query, page: 1 });
    }

    router.replace(
      buildHref(path, { ...activeFilters, searchTerm: query, page: 1 }),
      undefined,
      {
        shallow: false,
        scroll: false
      }
    );
  }

  return (
    <div>
      <SectionIdContainer id={TOP_FLOP_ID}>
        <MobileOnlyWrapper>
          <ReviewAuthenticityTeaser
            onItemClick={() => scrollToRef({ ref: reviewAuthenticityRef })}
          />
        </MobileOnlyWrapper>
        {children}
        {topFlopReviews.TopReview && topFlopReviews.FlopReview && (
          <ReviewTopFlop
            topFlopReviews={topFlopReviews}
            scrollToReviews={() => scrollToRef({ ref: filterAreaRef })}
            path={path}
          />
        )}
        <DesktopAndTabletOnlyWrapper>
          <ReviewAuthenticityTeaser
            onItemClick={() => scrollToRef({ ref: reviewAuthenticityRef })}
          />
        </DesktopAndTabletOnlyWrapper>
      </SectionIdContainer>
      <SectionIdContainer id={REVIEW_AREA_ID}>
        <ReviewAreaHeading ref={filterAreaRef} as="h2">
          {formatMessage(
            { id: 'COMPANYPROFILES.RATINGS.SUMMARY.HEADLINE' },
            { shopname: profile.name }
          )}
        </ReviewAreaHeading>
        {
          <FilterSection id={REVIEW_FILTER_SECTION}>
            <FilterBar data-test={REVIEW_FILTER_SECTION}>
              <SearchBarWrapper>
                <SearchField
                  placeholder={formatMessage({
                    id: 'REVIEW_SEARCH_PLACEHOLDER'
                  })}
                  activeFilters={activeFilters}
                  minLength={3}
                  required={false}
                  appearanceType={'standalone'}
                  onSubmit={(query: string) => handleSearchReviews(query)}
                  onClear={(query: string) => handleSearchReviews(query)}
                />
              </SearchBarWrapper>
              <FiltersWrapper>
                <ReviewFilterSelector starsFilters={starsFilters} />
                <ReviewSortSelector
                  path={path}
                  sortSelectionDefault={sortSelectionDefault}
                  activeFilters={activeFilters}
                />
              </FiltersWrapper>
            </FilterBar>
            {activeFilters.stars && activeFilters.stars.length > 0 && (
              <ReviewFiltersActive starsFilters={starsFilters} path={path} />
            )}
          </FilterSection>
        }
        {isLoading ? (
          <LoadingLinesCards
            loadingLinesCardConfig={ReviewLoadingLinesCardConfig}
            cardsToRender={3}
          />
        ) : (
          <>
            {reviews && reviews.reviews.length > 0 ? (
              <>
                {reviews.reviews.map((review: ShopReview, index: number) => {
                  return (
                    <Review
                      data-test={REVIEW}
                      review={review}
                      key={`review-${index}`}
                      searchTerm={activeFilters.searchTerm}
                    />
                  );
                })}
                <PaginationWrapper>
                  <Pagination
                    page={activeFilters.page}
                    pageCount={pageCount}
                    buildHref={(pageForHref?: number) =>
                      buildHref(path, {
                        ...activeFilters,
                        page: pageForHref
                      })
                    }
                    id="pagination"
                    onItemClick={() => {
                      scrollToRef({ ref: filterAreaRef });
                    }}
                  />
                </PaginationWrapper>
              </>
            ) : !activeFilters.searchTerm ? (
              <ReviewsNotFound
                activeFiltersSet={
                  (activeFilters.stars?.length || 0) > 0 ||
                  (activeFilters.page || 0) > 1
                }
                path={path}
              />
            ) : (
              <NoReviewsForSearchTerm />
            )}
          </>
        )}
        <div ref={reviewAuthenticityRef} id={'legal'}>
          <ReviewAuthenticity />
        </div>
      </SectionIdContainer>
      <ReviewCookieHintModal />
      <ReviewLikeModal />
    </div>
  );
};
