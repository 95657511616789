// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Top and Flop reviews API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Review,
    ReviewFromJSON,
    ReviewFromJSONTyped,
    ReviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface TopFlopServerResponse
 */
export interface TopFlopServerResponse {
    /**
     * 
     * @type {Review}
     * @memberof TopFlopServerResponse
     */
    TopReview?: Review;
    /**
     * 
     * @type {Review}
     * @memberof TopFlopServerResponse
     */
    FlopReview?: Review;
}

export function TopFlopServerResponseFromJSON(json: any): TopFlopServerResponse {
    return TopFlopServerResponseFromJSONTyped(json, false);
}

export function TopFlopServerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopFlopServerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'TopReview': !exists(json, 'TopReview') ? undefined : ReviewFromJSON(json['TopReview']),
        'FlopReview': !exists(json, 'FlopReview') ? undefined : ReviewFromJSON(json['FlopReview']),
    };
}

export function TopFlopServerResponseToJSON(value?: TopFlopServerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TopReview': ReviewToJSON(value.TopReview),
        'FlopReview': ReviewToJSON(value.FlopReview),
    };
}

