import styled from 'styled-components';
import { MainBox } from 'components/MainBox/MainBox';
import {
  gutterVariables,
  colorsVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const TopFlopCard = styled(MainBox)<{
  isBothAnonymousReviews?: boolean;
}>`
  display: grid;
  grid-row: 1;
  min-height: 100%;
  padding: 0;
  grid-template-rows: minmax(52px, auto) minmax(375px, auto) min-content;

  ${(props) =>
    props.isBothAnonymousReviews &&
    `
      grid-template-rows: minmax(52px,auto) minmax(275px,auto) min-content;
  `};

  ${minWidth(
    'md',
    `
      grid-template-rows: 52px auto min-content;
  `
  )};
`;

export const ActionsSection = styled.div`
  align-self: end;
  padding: 0 ${gutterVariables.GUTTER} ${gutterVariables.GUTTER};

  ${minWidth(
    'md',
    `
    padding: 0 ${gutterVariables.GUTTER_PLUS_HALF} ${gutterVariables.GUTTER};
  `
  )};
`;

export const ContentSection = styled.div`
  padding: ${gutterVariables.GUTTER} ${gutterVariables.GUTTER} 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;

  ${minWidth(
    'md',
    `
    padding: ${gutterVariables.GUTTER} ${gutterVariables.GUTTER_PLUS_HALF} 0;
  `
  )};
`;

export const BuyerStatement = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  font-size: ${fontVariables.SIZE_TEXT};
  line-height: ${fontVariables.LINE_HEIGHT_L};
  color: ${colorsVariables.GRAY_700};
`;

export const ModalContent = styled.div`
  white-space: pre-line;

  .tooltip {
    z-index: 100;
  }

  ${minWidth(
    'md',
    `
    padding: 24px;
  `
  )};
`;

export const ModalSeparator = styled.hr`
  border: 1px solid ${colorsVariables.GRAY_200};
  margin: ${gutterVariables.GUTTER} 0 12px 0;
`;

export const ModalShopReplyWrapper = styled.div`
  position: relative;
`;

export const ReadMoreLink = styled.div`
  margin-top: ${gutterVariables.GUTTER_HALF};
`;

export const ReviewLikeHelpfulText = styled.div`
  color: ${colorsVariables.GRAY_700};
  margin-top: ${gutterVariables.GUTTER_ONE_QUARTER};
`;

export const AllReviewsLinkSection = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const ReviewCardHeaderWrapper = styled.div`
  margin-top: 24px;
  border-radius: 8px;
  background: #f3f5f6;
  padding: 8px 12px;

  backdrop-filter: blur(10px);

  div {
    margin-bottom: 0;
  }
`;
