export const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_12669_4631)">
        <path
          d="M0 4.375C0 1.95875 1.95875 0 4.375 0H23.625C26.0412 0 28 1.95875 28 4.375V23.625C28 26.0412 26.0412 28 23.625 28H4.375C1.95875 28 0 26.0412 0 23.625V4.375Z"
          fill="black"
        />
        <path
          d="M15.3319 12.928L20.5437 7H19.3087L14.7833 12.1472L11.1688 7H7L12.4657 14.7835L7 21H8.2351L13.0141 15.5643L16.8312 21H21L15.3316 12.928H15.3319ZM13.6403 14.8521L13.0865 14.077L8.68013 7.90978H10.5772L14.1331 12.887L14.6869 13.662L19.3093 20.1316H17.4122L13.6403 14.8524V14.8521Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12669_4631">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
