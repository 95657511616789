import styled from 'styled-components';
import { gutterVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { default as NextLink } from 'next/link';

export const SortOrderLink = styled(NextLink)`
  display: block;
  line-height: 1.5;

  &:not(:first-of-type) {
    padding-top: ${gutterVariables.GUTTER};
  }

  &:hover {
    text-decoration: none;
  }
`;
