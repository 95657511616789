import styled from 'styled-components';
import {
  colorsVariables,
  flexColumn,
  flexItemsCentered,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

export const ReviewHeader = styled.div`
  ${flexColumn};
  margin-bottom: ${gutterVariables.GUTTER_PLUS_HALF};
  position: relative;
  margin-right: 0;
`;

export const HeaderMainContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
`;

export const ReviewCardHeader = styled.div`
  ${flexItemsCentered};
  color: ${colorsVariables.GRAY_500};

  gap: 16px;
  justify-content: space-between;
  position: relative;
  min-height: 48px;
  margin-bottom: ${gutterVariables.GUTTER_PLUS_HALF};
`;

export const RatingAndDateWrapper = styled.div<{ fullWidthRating?: boolean }>`
  display: flex;
  gap: 12px;

  ${(props) => props.fullWidthRating && `width: 100%;`};

  ${minWidth('md', 'width: auto;')};
`;
