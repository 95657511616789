import { LoadingLine } from '@trustedshops/tps-seo-shared-frontend-react-components/dist/models/LoadingLinesProps';

export const ReviewLoadingLinesCardConfig: LoadingLine[] = [
  {
    styles: { width: '60%', height: '25px', gridRow: 1, gridColumn: 1 }
  },
  {
    styles: {
      width: '40%',
      height: '20px',
      gridRow: 1,
      gridColumn: 2,
      justifySelf: 'end'
    }
  },
  {
    styles: { width: '40%', height: '20px', marginBottom: '20px' }
  },
  {},
  {},
  {
    styles: { width: '50%', height: '35px', margin: '16px 0' }
  }
];
