import { useLayoutEffect, useState } from 'react';

const sectionsGapInPx = 32;

export const useScrollspy = (
  ids: string[],
  offsetValueInPx: number = 0,
  scrollMarginTopInPx: number = 180
) => {
  const [activeId, setActiveId] = useState('');

  // Prevent this hook to work on the server, to avoid the render warning
  if (typeof window === 'undefined') {
    return;
  }

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.scrollY;

      const companyDetailsSectionOffset =
        document.getElementById('company-details')?.offsetTop;
      const isNearBottom = companyDetailsSectionOffset! < scroll;

      // Always set the last element of the list when we are close to the bottom of the page
      if (isNearBottom) {
        setActiveId(ids[ids.length - 1]);
        return;
      }

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(
            rect.top + scroll - offsetValueInPx - scrollMarginTopInPx
          );
          const bottom = clamp(
            rect.bottom + scroll - offsetValueInPx - scrollMarginTopInPx
          );

          return { id, top, bottom };
        })
        .filter(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(
        position && position.length > 1
          ? position[1]?.id
          : position[0]?.id || ''
      );
    };

    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [ids, offsetValueInPx]);

  return activeId;
};

// Restrict value to be between the range [0, value]
const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) => {
  return value >= floor && value <= ceil + sectionsGapInPx;
};
