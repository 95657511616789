import styled from 'styled-components';
import {
  colorsVariables,
  Dropdown,
  fontVariables,
  zIndicesVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { MainBox } from 'components/MainBox/MainBox';
import { minWidth } from 'helpers/buildMediaQueries';

export const ReviewFilterSelector = styled.div`
  position: relative;
  white-space: nowrap;

  ${minWidth('md', 'margin-right: 18px;')};
`;

export const FilterBox = styled(MainBox)`
  display: grid;
  grid-row-gap: 0.7rem;
  max-width: 350px;
  z-index: ${zIndicesVariables.filterDropdown};

  a,
  a:not(:hover),
  a:hover {
    color: ${colorsVariables.BLACK};
  }
`;

export const ReviewFilterDropdown = styled(Dropdown)`
  top: calc(100% + 8px);

  ${minWidth('md', 'top: calc(100% + 16px);')};
`;

export const FilterSelectorHeadline = styled.span`
  font-size: ${fontVariables.SIZE_TEXT};
  font-weight: 600;
  line-height: ${fontVariables.LINE_HEIGHT_L};
`;
