import React, { useRef } from 'react';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { useIntl } from 'react-intl';
import { useStore } from 'context/store';
import {
  CodeTitle,
  CodeWrapper,
  ProductWrapper,
  LinkToOffer,
  CampaignText,
  VoucherIllustration,
  VoucherCode,
  VoucherHeading
} from 'components/TrafficProduct/TrafficProduct.styles';
import { Context } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { addHttpPrefix } from 'helpers/addHttpPrefix';
import voucherIllustrationSvg from './Voucher.svg';

export const TrafficProduct = () => {
  const { formatMessage } = useIntl();
  const { profile } = useStore();
  const refForTooltip = useRef<HTMLSpanElement>(null);

  const { trafficPackage } = profile;
  if (!trafficPackage) {
    return null;
  }

  return (
    <>
      <ProductWrapper>
        {trafficPackage.voucherValue && (
          <>
            <VoucherHeading as="h3">
              {trafficPackage.voucherValue}
            </VoucherHeading>
            <VoucherIllustration
              dangerouslySetInnerHTML={{ __html: voucherIllustrationSvg }}
            />
            <CodeTitle>
              {formatMessage({
                id: 'COMPANYPROFILES.TRAFFIC_PRODUCT.VOUCHER_TITLE'
              })}
            </CodeTitle>
            {trafficPackage.voucherCode && (
              <CodeWrapper>
                <CopyToClipboard
                  textToCopy={trafficPackage.voucherCode}
                  successMessage={
                    'COMPANYPROFILES.TRAFFIC_PRODUCT.COPY_CODE_SUCCESS'
                  }
                  uniqueId="voucherCode"
                  refForTooltip={refForTooltip}
                >
                  <VoucherCode>{trafficPackage.voucherCode}</VoucherCode>
                </CopyToClipboard>
              </CodeWrapper>
            )}
          </>
        )}
        {trafficPackage.campaignText && (
          <CampaignText>{trafficPackage.campaignText}</CampaignText>
        )}
        {trafficPackage.deepLinkUrl && (
          <LinkToOffer
            context={Context.Primary}
            href={addHttpPrefix(trafficPackage.deepLinkUrl)}
            target="_blank"
            rel="noreferrer"
          >
            {trafficPackage.deepLinkLabel}
          </LinkToOffer>
        )}
      </ProductWrapper>
    </>
  );
};
