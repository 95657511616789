const DEFAULT_PAGE = 1;

export class PageParameterValidator {
  public currentPage: number = DEFAULT_PAGE;
  public pageParamIsValidNumber: boolean = false;

  constructor(pageToCheck?: string | string[]) {
    const positiveIntegrRegex = /^[+]?\d+$/g;

    if (!pageToCheck) {
      this.pageParamIsValidNumber = true;
      return;
    }

    if (Array.isArray(pageToCheck)) {
      return;
    }

    if (!positiveIntegrRegex.test(pageToCheck)) {
      return;
    }

    const parsedPageToCheck = parseInt(pageToCheck, 10);

    this.pageParamIsValidNumber =
      !isNaN(parsedPageToCheck) && parsedPageToCheck > 1;

    if (this.pageParamIsValidNumber) {
      this.currentPage = parsedPageToCheck;
    }
  }
}
