import { ProfilePageProps } from 'models/ProfilePageProps';
import { AllowedCompanyProfileQueryParams } from 'helpers/AllowedCompanyProfileQueryParams';

/**
 * Note: This function does not use router.query, but parses location.search
 * Reason: query parameters are passed from server to client (cached query object in HTML).
 * As CloudFront only passes allowed query params (page, sort, stars, searchTerm) to the Lambda function,
 * the whole app doesn't know anything about other query parameters (like utm_source),
 * and therefore would not replace invalid query parameters each time.
 *
 * istanbul ignore next: has been covered by interaction tests
 */
export function routeNeedsToBeReplaced(props: ProfilePageProps) {
  if (props.error) {
    return;
  }

  if (typeof window === 'undefined') {
    return false;
  }

  if (props.pageCount >= 1 && props.page > props.pageCount) {
    return true;
  }

  if (location.href.includes('.html&')) {
    return true;
  }

  const query = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );

  for (const parameter in query) {
    if (!AllowedCompanyProfileQueryParams.hasOwnProperty(parameter as any)) {
      return true;
    }
  }

  return (
    query.sort !== props.sort ||
    (query.hasOwnProperty(AllowedCompanyProfileQueryParams.stars) &&
      props.stars.length === 0) ||
    (query.hasOwnProperty(AllowedCompanyProfileQueryParams.page) &&
      (query.page !== props.page.toString() || !props.validPage)) ||
    (query.hasOwnProperty(AllowedCompanyProfileQueryParams.searchTerm) &&
      query.searchTerm !== props.searchTerm) ||
    props.searchTerm === ''
  );
}
