export const REVIEW_AREA_ID = 'review-area';
export const PAGE_TOP_ID = 'page-top';
export const TOP_FLOP_ID = 'top-flop';
export const COMPANY_DETAILS_ID = 'company-details';
export const CERTIFICATION_AREA_ID = 'certification-area';
export const COMPANY_HEADER_ID = 'company-header';
export const ANIMATED_COMPANY_HEADER_ID = 'animated-company-header';

export enum NAV_ITEMS_ANCHOR_CONFIG {
  overview = PAGE_TOP_ID,
  certification = CERTIFICATION_AREA_ID,
  topFlop = TOP_FLOP_ID,
  reviews = REVIEW_AREA_ID,
  companyDetails = COMPANY_DETAILS_ID
}
