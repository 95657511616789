// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Top and Flop reviews API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contains the shop owner's response details
 * @export
 * @interface Reply
 */
export interface Reply {
    /**
     * when shop owner submitted reply | in milliseconds
     * @type {number}
     * @memberof Reply
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof Reply
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof Reply
     */
    totalLikeCount?: number;
}

export function ReplyFromJSON(json: any): Reply {
    return ReplyFromJSONTyped(json, false);
}

export function ReplyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reply {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'totalLikeCount': !exists(json, 'totalLikeCount') ? undefined : json['totalLikeCount'],
    };
}

export function ReplyToJSON(value?: Reply | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'comment': value.comment,
        'totalLikeCount': value.totalLikeCount,
    };
}

