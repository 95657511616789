export const SubmitReviewIllustration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 21.1276C12.86 21.1191 12.2222 21.0513 11.5947 20.9253C11.2984 20.8746 10.9945 20.9533 10.7601 21.1415C9.56071 22.0227 8.15251 22.5762 6.67419 22.7476C6.50267 22.7656 6.33631 22.6824 6.24777 22.5344C6.15922 22.3863 6.16459 22.2004 6.26152 22.0577C6.83284 21.3461 7.24303 20.519 7.46382 19.6334C7.5084 19.3916 7.4139 19.1452 7.21909 18.9952C5.06294 17.3931 3.77955 14.8751 3.75 12.1888C3.75 7.25816 8.11524 3.25 13.5 3.25C18.8848 3.25 23.25 7.25816 23.25 12.1888C23.25 17.1195 18.8849 21.1276 13.5 21.1276ZM13.4997 7.58333L12.4766 10.8924L9.16667 10.8939L11.8444 12.9394L10.8225 16.25L13.4997 14.2044L16.178 16.25L15.1563 12.9394L17.8333 10.8939L14.5242 10.8924L13.4997 7.58333Z"
        fill="white"
      />
    </svg>
  );
};
