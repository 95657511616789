import styled from 'styled-components';
import {
  gutterVariables,
  fontVariables,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const AnswerFrom = styled.span`
  font-size: ${fontVariables.SIZE_TEXT};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  color: ${colorsVariables.GRAY_800};
`;

export const ShopStatementDate = styled.div`
  display: flex;
  color: ${colorsVariables.GRAY_500};
`;

export const ReviewAnswerHeader = styled.div`
  display: flex;
`;

export const HeaderContent = styled.div`
  padding-left: ${gutterVariables.GUTTER_HALF};
`;
