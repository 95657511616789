import styled from 'styled-components';
import {
  Icon,
  fontVariables,
  colorsVariables,
  Heading,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { TextOverflowEllipsis } from 'styles/utils/text.styles';
import { minWidth, onlyWidth } from 'helpers/buildMediaQueries';
import { ExternalLinkAsButton } from 'components/CompanyProfile/CompanyDetails.styles';

export const CompanyContactInfo = styled.div`
  a {
    color: ${colorsVariables.BLUE_700};
  }
`;

export const CompanyDetailsSectionHeadline = styled(Heading)`
  font-size: ${fontVariables.SIZE_TEXT};
  line-height: ${fontVariables.LINE_HEIGHT_M};
  color: ${colorsVariables.GRAY_900};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  margin-bottom: ${gutterVariables.GUTTER};
`;

export const LegalHeadline = styled(CompanyDetailsSectionHeadline)`
  margin-bottom: 0;
`;

export const LegalInfoText = styled.span`
  font-size: ${fontVariables.SIZE_TEXT};
  color: ${colorsVariables.GRAY_700};
  line-height: 1.7;
`;

export const CompanyContactIcon = styled(Icon)`
  line-height: 25px;
  padding-right: 0;

  &&:before {
    font-size: 25px;
    color: ${colorsVariables.GRAY_500};
    line-height: 25px;
  }
`;

export const CompanyContactDetailBlock = styled.div<{
  isEmptyProfile?: boolean;
}>`
  display: flex;
  margin-top: ${gutterVariables.GUTTER};

  ${(props) =>
    props.isEmptyProfile &&
    `
    ${minWidth('md', 'width: 100%')};
  `};
`;

export const CompanyContactDetail = styled.div`
  padding-left: ${gutterVariables.GUTTER};
  max-width: 90%;
  color: ${colorsVariables.GRAY_700};
  font-size: ${fontVariables.SIZE_TEXT};
  line-height: ${fontVariables.LINE_HEIGHT_L};
`;

export const CompanyContactDetailLink = styled.a`
  display: inline-block;
  ${TextOverflowEllipsis};
  max-width: 100%;
  line-height: 1;
  vertical-align: text-bottom;
  text-decoration: underline;

  && {
    color: ${colorsVariables.BLUE_600};
  }
`;

export const ContactInfoSection = styled.div<{
  alignment: 'left' | 'between';
  hasNoReviews?: boolean;
}>`
  ${(props) =>
    props.alignment === 'between'
      ? onlyWidth('xl', 'display: flex; justify-content: space-between;')
      : onlyWidth(
          'xl',
          'display: flex; justify-content: flex-start; gap: 32px;'
        )}

  ${(props) =>
    props.hasNoReviews &&
    minWidth(
      'md',
      `
    display: grid; 
    gap: 16px; 
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: ${props.alignment === 'between' ? 'space-between' : 'left'};
  `
    )}

  ${(props) =>
    !props.hasNoReviews &&
    `
    @media (min-width: 595px) and (max-width: 1439px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;
  }
    `}
`;

export const LegalContactsSection = styled(ContactInfoSection)<{
  alignment: 'left' | 'between';
}>`
  margin-top: ${gutterVariables.GUTTER_PLUS_HALF};

  > div {
    margin-bottom: 24px;

    ${onlyWidth('xl', 'margin-bottom: 0;')};
  }
`;

export const LegalContact = styled.div`
  width: 100%;
`;

export const CompanyOrgName = styled.div`
  color: #344054;
  line-height: ${fontVariables.LINE_HEIGHT_L};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
`;

export const MultiProfileWrapper = styled.div<{
  isEmptyProfile?: boolean;
}>`
  display: flex;
  color: #344054;
  background-color: #f2f4f7;
  gap: 16px;
  padding: 12px 12px 0 12px;
  border-radius: 4px;
  flex-direction: column;
  margin-top: 16px;

  ${minWidth(
    'md',
    'padding: 12px; flex-direction: row; align-items: center; justify-content: space-between;'
  )};

  ${(props) =>
    !props.isEmptyProfile &&
    `
    ${minWidth('xxl', 'padding: 12px 12px 0 12px; flex-direction: column;')};
  `};
`;

export const MultiCompanyLinkAsButton = styled(ExternalLinkAsButton)<{
  isEmptyProfile?: boolean;
}>`
  ${minWidth(
    'md',
    'width: fit-content; min-width: fit-content; margin-bottom: 0;'
  )};

  ${(props) =>
    !props.isEmptyProfile &&
    `
    ${minWidth('xxl', 'margin-bottom: 12px; width: 100%;')};
  `};
`;
