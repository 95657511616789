// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contains review count values and grade information
 * @export
 * @interface ReviewStatistic
 */
export interface ReviewStatistic {
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    yearlyReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    allTimeReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    oneStarReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    twoStarReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    threeStarReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    fourStarReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    fiveStarReviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewStatistic
     */
    grade: number;
}

export function ReviewStatisticFromJSON(json: any): ReviewStatistic {
    return ReviewStatisticFromJSONTyped(json, false);
}

export function ReviewStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewStatistic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yearlyReviewCount': json['yearlyReviewCount'],
        'allTimeReviewCount': json['allTimeReviewCount'],
        'oneStarReviewCount': json['oneStarReviewCount'],
        'twoStarReviewCount': json['twoStarReviewCount'],
        'threeStarReviewCount': json['threeStarReviewCount'],
        'fourStarReviewCount': json['fourStarReviewCount'],
        'fiveStarReviewCount': json['fiveStarReviewCount'],
        'grade': json['grade'],
    };
}

export function ReviewStatisticToJSON(value?: ReviewStatistic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yearlyReviewCount': value.yearlyReviewCount,
        'allTimeReviewCount': value.allTimeReviewCount,
        'oneStarReviewCount': value.oneStarReviewCount,
        'twoStarReviewCount': value.twoStarReviewCount,
        'threeStarReviewCount': value.threeStarReviewCount,
        'fourStarReviewCount': value.fourStarReviewCount,
        'fiveStarReviewCount': value.fiveStarReviewCount,
        'grade': value.grade,
    };
}

