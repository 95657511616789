import styled from 'styled-components';
import {
  gutterVariables,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const StarCountStyles = styled.div`
  display: inline-block;
  margin-left: ${gutterVariables.GUTTER_HALF};
  color: ${colorsVariables.GRAY_800};
`;
