import { DateAgoDistanceUnitsInterface } from 'models/DateAgoDistanceUnitsInterface';

const RELATIVE_TO_MS: { [type: string]: number } = {
  day: 86400023,
  hour: 3600000,
  minute: 60000
};

export const CALCULATION_BASE = {
  numberOfDaysAfterWhichAbsoluteDateIsShown: 14,
  numberOfHoursAfterWhichDaysAreShown: 24,
  numberOfMinutesAfterWhichHoursAreShown: 60,
  numberOfMinutesAfterWhichMinutesAreShown: 10
};

export function dateAgo(
  dateToCheck: any,
  currentTime: any,
  currentLocale: string
):
  | string
  | {
      key: string;
      value: number;
    }
  | null {
  if (!dateToCheck || !currentTime) {
    return null;
  }

  if (typeof Intl.RelativeTimeFormat === 'function') {
    const timestampToCheck = getTimestampFromDate(dateToCheck);
    let timestampCurrent = getTimestampFromDate(currentTime);

    if (isNaN(timestampToCheck) || isNaN(timestampCurrent)) {
      return null;
    }

    if (timestampToCheck > timestampCurrent) {
      timestampCurrent = Date.now();
    }

    const distanceInMs = timestampCurrent - timestampToCheck;
    const { minutes, hours, days } = getDistanceInUnits(distanceInMs);

    if (minutes < CALCULATION_BASE.numberOfMinutesAfterWhichMinutesAreShown) {
      return {
        key: `CORE.DATES.JUST_NOW`,
        value: 0
      };
    }

    function getTranslationValues():
      | [Intl.RelativeTimeFormatUnit, number]
      | undefined {
      if (minutes < CALCULATION_BASE.numberOfMinutesAfterWhichHoursAreShown) {
        return ['minutes', minutes];
      }

      if (hours < CALCULATION_BASE.numberOfHoursAfterWhichDaysAreShown) {
        return ['hours', hours];
      }

      if (days < CALCULATION_BASE.numberOfDaysAfterWhichAbsoluteDateIsShown) {
        return ['days', days];
      }
    }

    const [translationKey, translationValue] = getTranslationValues() || [];
    if (translationKey && translationValue) {
      const relativeTimeFormat = new Intl.RelativeTimeFormat(currentLocale, {
        numeric: 'always'
      });
      return relativeTimeFormat.format(-translationValue, translationKey);
    }
  }

  return new Date(dateToCheck).toLocaleDateString(currentLocale, {
    dateStyle: 'medium'
  });
}

export function getTimestampFromDate(date: string | number | Date): number {
  switch (typeof date) {
    case 'string':
      return Date.parse(date);
    case 'object':
      return Date.parse(date.toString());
    case 'number':
      return date;
  }
}

export function getDistanceInUnits(
  distanceInMs: number
): DateAgoDistanceUnitsInterface {
  return {
    days: Math.ceil(distanceInMs / RELATIVE_TO_MS.day),
    hours: Math.ceil(distanceInMs / RELATIVE_TO_MS.hour),
    minutes: Math.ceil(distanceInMs / RELATIVE_TO_MS.minute)
  };
}
