// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorMessage,
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    Profile,
    ProfileFromJSON,
    ProfileToJSON,
} from '../models';

export interface IdGetRequest {
    id: string;
}

export interface IdTargetMarketLanguageGetRequest {
    id: string;
    targetMarket: string;
    language: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Returns Profile for a given id
     */
    async idGetRaw(requestParameters: IdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Returns Profile for a given id
     */
    async idGet(requestParameters: IdGetRequest, initOverrides?: RequestInit): Promise<Profile> {
        const response = await this.idGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns Profile for a given id
     */
    async idTargetMarketLanguageGetRaw(requestParameters: IdTargetMarketLanguageGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idTargetMarketLanguageGet.');
        }

        if (requestParameters.targetMarket === null || requestParameters.targetMarket === undefined) {
            throw new runtime.RequiredError('targetMarket','Required parameter requestParameters.targetMarket was null or undefined when calling idTargetMarketLanguageGet.');
        }

        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling idTargetMarketLanguageGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{id}/{targetMarket}/{language}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"targetMarket"}}`, encodeURIComponent(String(requestParameters.targetMarket))).replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Returns Profile for a given id
     */
    async idTargetMarketLanguageGet(requestParameters: IdTargetMarketLanguageGetRequest, initOverrides?: RequestInit): Promise<Profile> {
        const response = await this.idTargetMarketLanguageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
