import React, { useRef } from 'react';
import { dateAgo } from 'helpers/dateAgo';
import { determineCurrentTime } from 'helpers/determineCurrentTime';
import { useIntl } from 'react-intl';
import { useStore } from 'context/store';
import { Review } from 'reviews-api';
import { transformDateAgo } from 'helpers/transformDateAgo';
import {
  ModificationHistory,
  ModificationHistoryEntry,
  ModificationHistoryEntryHeading
} from 'components/Review/ReviewProvider/ReviewProvider.styles';
import { DATE_AGO } from 'services/data-test-attributes';
import {
  Tooltip,
  TooltipHandleFunctions,
  TooltipPlacement
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { ReviewDate as ReviewDateStyled } from './ReviewDate.styles';

export const ReviewDate = ({
  review,
  tooltipId = review.id,
  tooltipPlacement,
  isTooltipOnMobile
}: {
  review: Review;
  tooltipId?: string;
  tooltipPlacement: TooltipPlacement;
  isTooltipOnMobile?: boolean;
}) => {
  const { formatMessage, formatDate, locale } = useIntl();
  const { environment } = useStore();
  const refForTooltip = useRef<HTMLSpanElement>(null);
  const tooltipComponentRef = useRef<TooltipHandleFunctions>(null);

  const TooltipContent = () => {
    return (
      <ModificationHistory>
        {review.createdAt && (
          <ModificationHistoryEntry isTooltipOnMobile={isTooltipOnMobile}>
            <ModificationHistoryEntryHeading
              isTooltipOnMobile={isTooltipOnMobile}
            >
              {' '}
              {formatMessage({
                id: 'COMPANYPROFILES.REVIEW.PUBLISH_DATE'
              })}{' '}
            </ModificationHistoryEntryHeading>
            {formatDate(review.createdAt, {
              dateStyle: 'medium'
            })}
          </ModificationHistoryEntry>
        )}
        {review.updatedAt && (
          <ModificationHistoryEntry isTooltipOnMobile={isTooltipOnMobile}>
            <ModificationHistoryEntryHeading
              isTooltipOnMobile={isTooltipOnMobile}
            >
              {' '}
              {formatMessage({ id: 'COMPANYPROFILES.REVIEW.CHANGE_DATE' })}{' '}
            </ModificationHistoryEntryHeading>
            {formatDate(review.updatedAt, {
              dateStyle: 'medium'
            })}
          </ModificationHistoryEntry>
        )}
        {review.transaction?.date && (
          <ModificationHistoryEntry isTooltipOnMobile={isTooltipOnMobile}>
            <ModificationHistoryEntryHeading
              isTooltipOnMobile={isTooltipOnMobile}
            >
              {' '}
              {formatMessage({ id: 'COMPANYPROFILES.REVIEW.ORDER_DATE' })}{' '}
            </ModificationHistoryEntryHeading>
            {formatDate(review.transaction.date, {
              dateStyle: 'medium'
            })}
          </ModificationHistoryEntry>
        )}
      </ModificationHistory>
    );
  };

  return (
    <>
      <Tooltip
        childrenRef={refForTooltip}
        content={TooltipContent()}
        placement={tooltipPlacement}
        uniqueId={tooltipId}
        ref={tooltipComponentRef}
        mobileElementType={isTooltipOnMobile ? 'tooltip' : 'drawer'}
      >
        <ReviewDateStyled
          data-test={DATE_AGO}
          ref={refForTooltip}
          suppressHydrationWarning
        >
          {transformDateAgo(
            dateAgo(
              review.createdAt,
              determineCurrentTime(environment),
              locale
            ),
            formatMessage
          )}
        </ReviewDateStyled>
      </Tooltip>
    </>
  );
};
