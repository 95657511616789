import { COMPANY_DETAILS_ID } from 'services/page-section-ids';
import { DesktopOnlyWrapper } from 'styles/utils/deviceOnlyWrapper';
import { TrafficProduct } from 'components/TrafficProduct/TrafficProduct';
import {
  CompanyDetailsAndContactInfo,
  CompanyDetailsAndContactInfoBox
} from 'components/CompanyProfile/CompanyDetailsAndContactInfo.styles';
import { CompanyName } from 'components/CompanyProfile/CompanyDetails.styles';
import ContactInfo from 'components/CompanyProfile/ContactInfo/ContactInfo';
import { SimilarCompaniesAsideWrapper } from 'components/SimilarCompanies/SimilarCompanies.styles';
import { useStore } from 'context/store';
import CompanyDetails from 'components/CompanyProfile/CompanyDetails';
import { useIntl } from 'react-intl';
import { ReviewResponse } from 'models/ReviewResponse';
import dynamic from 'next/dynamic';
import CertificateInfo from 'components/CertificateInfo/CertificateInfo';

const ClaimProfile = dynamic(
  () => import('components/ClaimProfile/ClaimProfile'),
  { ssr: false }
);

const SimilarCompanies = dynamic(
  () => import('components/SimilarCompanies/SimilarCompanies'),
  { ssr: false }
);

export const ProfileAsideSection = ({
  isCertificateInfoVisible,
  summaryIsVisible,
  desktopVoucherRef,
  contactInfoRef,
  searchTerm,
  reviews,
  companyDetailsVisible,
  relatedShops
}: {
  isCertificateInfoVisible: boolean;
  summaryIsVisible: boolean;
  desktopVoucherRef: any;
  contactInfoRef: any;
  searchTerm?: string;
  reviews: ReviewResponse;
  companyDetailsVisible: boolean;
  relatedShops: any;
}) => {
  const { profile, isOpenProfile, isNonMemberProfile } = useStore();
  const { formatMessage } = useIntl();

  return (
    <aside id={COMPANY_DETAILS_ID}>
      {isCertificateInfoVisible && (
        <DesktopOnlyWrapper>
          <CertificateInfo />
        </DesktopOnlyWrapper>
      )}
      {((profile.trafficPackage && profile.trafficPackage?.voucherValue) ||
        (profile.trafficPackage?.campaignText &&
          profile.trafficPackage?.deepLinkUrl)) && (
        <div ref={desktopVoucherRef}>
          <TrafficProduct />
        </div>
      )}
      {((searchTerm && summaryIsVisible) ||
        (reviews.metaData.totalReviewCount > 0 && summaryIsVisible)) && (
        <CompanyDetailsAndContactInfo showOnMobile={true} ref={contactInfoRef}>
          <CompanyDetailsAndContactInfoBox>
            {companyDetailsVisible ? (
              <CompanyDetails />
            ) : (
              <>
                <CompanyName as="h3">
                  {formatMessage(
                    { id: 'COMPANYPROFILES.MAIN.CONTACT_DATA' },
                    { shopname: profile.name }
                  )}
                </CompanyName>
              </>
            )}
            <ContactInfo tooltipId={'contact-info-desktop'} />
          </CompanyDetailsAndContactInfoBox>
        </CompanyDetailsAndContactInfo>
      )}
      {isNonMemberProfile && reviews.metaData.totalReviewCount > 0 && (
        <ClaimProfile verificationDeepLink={profile.verificationDeepLink} />
      )}
      {isOpenProfile && (
        <SimilarCompaniesAsideWrapper>
          <SimilarCompanies similarCompanies={relatedShops.shops} />
        </SimilarCompaniesAsideWrapper>
      )}
    </aside>
  );
};
