import React from 'react';
import {
  Category as CategoryStyled,
  CategoryTag
} from 'components/Category/Category.styles';
import { useIntl } from 'react-intl';
import { Keyword, Category as CategoryType } from 'profile-api';

export const Category = ({
  category
}: {
  category: CategoryType | Keyword;
}) => {
  const { formatMessage } = useIntl();
  const companySearchLinkWithoutTrailingSlash = formatMessage({
    id: 'FOOTER.SEARCH.LINK'
  }).replace(/\/$/, '');

  return (
    <CategoryStyled
      href={`${companySearchLinkWithoutTrailingSlash}/${category.urlPath}/`}
    >
      <CategoryTag>
        <span>{category.label}</span>
      </CategoryTag>
    </CategoryStyled>
  );
};
