import { useIntl } from 'react-intl';
import {
  FeedbackInfoCircleIcon,
  ReviewAuthenticityTeaser as ReviewAuthenticityTeaserStyled,
  TeaserLink
} from 'components/ReviewArea/ReviewAuthenticityTeaser/ReviewAuthenticityTeaser.styles';
import { MouseEventHandler } from 'react';
import { REVIEW_AUTHENTICITY_TEASER } from 'services/data-test-attributes';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const ReviewAuthenticityTeaser = ({
  onItemClick
}: {
  onItemClick: MouseEventHandler;
}) => {
  const { formatMessage } = useIntl();

  return (
    <ReviewAuthenticityTeaserStyled>
      <FeedbackInfoCircleIcon
        iconName={'icon-feedback-info-circle'}
        color={colorsVariables.BLUE_500}
        iconSize={'24px'}
      />
      <div>
        <span>
          {formatMessage({ id: 'COMPANYPROFILES.LEGAL.AUTHENTICITY.TEASER_1' })}{' '}
        </span>
        <TeaserLink
          data-test={REVIEW_AUTHENTICITY_TEASER}
          onClick={onItemClick}
        >
          {formatMessage({
            id: 'COMPANYPROFILES.LEGAL.AUTHENTICITY.TEASER_LINK.LABEL'
          })}
        </TeaserLink>
        <span>
          {' '}
          {formatMessage({ id: 'COMPANYPROFILES.LEGAL.AUTHENTICITY.TEASER_2' })}
        </span>
      </div>
    </ReviewAuthenticityTeaserStyled>
  );
};
