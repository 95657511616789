import { Profile, ReviewWidgetState } from 'profile-api';
import { CertificationState } from 'models/CertificationState';

export function nullifyIfShopHasNoInfoToShow(profile: Profile) {
  // @ts-ignore -> Will be removed when overrides are moved to BE
  if (profile?.memberDetails?.reviewState !== ReviewWidgetState.Disabled) {
    return profile;
  }

  if (
    !profile?.memberDetails.certificationStateId ||
    profile?.memberDetails.certificationStateId ===
      CertificationState.Cancelled ||
    profile?.memberDetails.certificationStateId === CertificationState.NoAudit
  ) {
    return null;
  }

  return profile;
}
