import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';
import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  align-self: flex-start;
  width: 24px;
  height: 24px;
  color: ${colorsVariables.GRAY_500};
`;

const ContactFormIcon = ({ color }: { color?: string }) => (
  <StyledSvg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={color}
  >
    <path
      d="M12.0026 13.875C12.5186 13.875 12.9599 13.6913 13.3266 13.324C13.6932 12.9565 13.8766 12.5148 13.8766 11.999C13.8766 11.483 13.6929 11.0417 13.3256 10.675C12.9581 10.3083 12.5164 10.125 12.0006 10.125C11.4846 10.125 11.0432 10.3087 10.6766 10.676C10.3099 11.0435 10.1266 11.4852 10.1266 12.001C10.1266 12.517 10.3102 12.9583 10.6776 13.325C11.0451 13.6917 11.4867 13.875 12.0026 13.875ZM8.12656 17.75H15.8766V17.3C15.8766 16.9243 15.7749 16.58 15.5716 16.267C15.3681 15.9538 15.0864 15.719 14.7266 15.5625C14.3009 15.3832 13.863 15.2446 13.4128 15.1468C12.9626 15.0489 12.492 15 12.0008 15C11.5096 15 11.0391 15.0489 10.5893 15.1468C10.1395 15.2446 9.7019 15.3832 9.27656 15.5625C8.91673 15.719 8.63506 15.9538 8.43156 16.267C8.22823 16.58 8.12656 16.9243 8.12656 17.3V17.75ZM17.9016 21.75H6.10156C5.5859 21.75 5.14448 21.5664 4.77731 21.1992C4.41015 20.8321 4.22656 20.3907 4.22656 19.875V4.125C4.22656 3.60933 4.41015 3.16792 4.77731 2.80075C5.14448 2.43358 5.5859 2.25 6.10156 2.25H13.5123C13.7776 2.25 14.0319 2.35536 14.2195 2.54289L19.4837 7.80711C19.6712 7.99464 19.7766 8.249 19.7766 8.51421V19.875C19.7766 20.3907 19.593 20.8321 19.2258 21.1992C18.8586 21.5664 18.4172 21.75 17.9016 21.75Z"
      fill="currentColor"
    />
  </StyledSvg>
);

export default ContactFormIcon;
