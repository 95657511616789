import styled from 'styled-components';
import {
  Icon,
  gutterVariables,
  colorsVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { Chip } from './Chip.styles';
import { minWidth } from 'helpers/buildMediaQueries';
import Link from 'next/link';

export const ReviewFiltersActive = styled.div`
  display: block;

  ${Chip} {
    margin-right: 10px;
    margin-top: 16px;

    ${minWidth('md', 'margin-top: 10px;')};
  }

  label {
    display: block;
    padding-top: ${gutterVariables.GUTTER_ONE_QUARTER};
    font-weight: inherit;
  }
`;

export const CloseIcon = styled(Icon)`
  color: ${colorsVariables.BLUE_700};
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  &&&:before {
    line-height: ${fontVariables.LINE_HEIGHT};
  }
`;

export const ResetFiltersLink = styled(Link)`
  text-decoration-line: underline;
  text-underline-offset: 2px;
`;
