import { minWidth } from 'helpers/buildMediaQueries';
import styled from 'styled-components';
import {
  Heading,
  HeadingProps,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export const Headline = styled(Heading)<HeadingProps>`
  margin: ${gutterVariables.GUTTER_DOUBLE} 0;
`;

export const Subtext = styled.p`
  margin-top: 0.5rem;
`;

export const ReviewsNotFound = styled.div`
  text-align: center;
  line-height: 1.5;

  a {
    display: block;
    margin: 30px 0 50px 0;
    ${minWidth('sm', 'margin: 40px 0 50px 0;')}
  }
`;
