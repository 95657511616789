import styled from 'styled-components';
import { fontVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const StarCount = styled.div`
  line-height: ${fontVariables.LINE_HEIGHT_L};
  margin-left: 5px;
  display: inline-block;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  font-size: ${fontVariables.SIZE_TEXT_S};
`;
