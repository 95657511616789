import React from 'react';
import { RotateOnOpen } from 'helpers/RotateOnOpen';
import { DropdownChipOpener as DropdownChipOpenerStyled } from './DropdownChipOpener.styles';

const DropdownChipOpener = ({
  label,
  onClick,
  chevronToggleState,
  dataTestName
}: {
  label: string;
  onClick: () => void;
  chevronToggleState: boolean;
  dataTestName?: string;
}) => {
  return (
    <DropdownChipOpenerStyled
      data-test={dataTestName}
      onClick={
        /* istanbul ignore next: is covered in VRTs */
        () => onClick()
      }
      className="cur-pointer"
    >
      <span>{label}</span>
      <RotateOnOpen
        iconName="icon-arrow-chevron-down"
        open={chevronToggleState}
      />
    </DropdownChipOpenerStyled>
  );
};

export default DropdownChipOpener;
