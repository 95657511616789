export const TikTokIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12670_4220)">
        <path
          d="M0 4.375C0 1.95875 1.95875 0 4.375 0H23.625C26.0412 0 28 1.95875 28 4.375V23.625C28 26.0412 26.0412 28 23.625 28H4.375C1.95875 28 0 26.0412 0 23.625V4.375Z"
          fill="black"
        />
        <path
          d="M12.6861 12.5128V11.9678C12.4994 11.9428 12.3095 11.9272 12.1135 11.9272C9.7764 11.9242 7.875 13.8271 7.875 16.1693C7.875 17.6051 8.59075 18.8759 9.68616 19.6452C8.97974 18.8883 8.54718 17.8698 8.54718 16.7548C8.54718 14.4469 10.3957 12.5657 12.6861 12.5128Z"
          fill="#25F4EE"
        />
        <path
          d="M12.7886 18.6921C13.8311 18.6921 14.6838 17.8605 14.7212 16.8265L14.7243 7.58554H16.4109C16.3767 7.39555 16.358 7.19933 16.358 7H14.0552L14.0521 16.2409C14.0148 17.275 13.1621 18.1065 12.1196 18.1065C11.7959 18.1065 11.491 18.0256 11.2202 17.8823C11.5688 18.3713 12.1414 18.6921 12.7886 18.6921Z"
          fill="#25F4EE"
        />
        <path
          d="M19.5637 10.7219V10.208C18.9195 10.208 18.322 10.018 17.8179 9.68787C18.266 10.2018 18.8728 10.5756 19.5637 10.7219Z"
          fill="#25F4EE"
        />
        <path
          d="M17.8175 9.68786C17.3258 9.12414 17.0302 8.3891 17.0302 7.58554H16.4141C16.5727 8.46385 17.0956 9.21758 17.8175 9.68786Z"
          fill="#FE2C55"
        />
        <path
          d="M12.1168 14.2289C11.0494 14.2289 10.1812 15.0979 10.1812 16.1662C10.1812 16.9106 10.6044 17.5584 11.2205 17.8823C10.9903 17.5646 10.8533 17.1753 10.8533 16.7517C10.8533 15.6834 11.7216 14.8144 12.789 14.8144C12.9881 14.8144 13.1811 14.8487 13.3616 14.9048V12.5502C13.1748 12.5252 12.985 12.5096 12.789 12.5096C12.7547 12.5096 12.7236 12.5128 12.6894 12.5128V14.3192C12.5058 14.2631 12.3159 14.2289 12.1168 14.2289Z"
          fill="#FE2C55"
        />
        <path
          d="M19.5634 10.7219V12.5128C18.3684 12.5128 17.2605 12.1297 16.358 11.4818V16.1693C16.358 18.5083 14.4567 20.4145 12.1165 20.4145C11.214 20.4145 10.3738 20.1279 9.68604 19.6452C10.4609 20.4767 11.5656 21 12.7887 21C15.1257 21 17.0302 19.097 17.0302 16.7548V12.0674C17.9327 12.7152 19.0405 13.0983 20.2355 13.0983V10.7936C20.0021 10.7936 19.7781 10.7686 19.5634 10.7219Z"
          fill="#FE2C55"
        />
        <path
          d="M16.3583 16.1693V11.4818C17.2608 12.1297 18.3687 12.5128 19.5637 12.5128V10.7219C18.8728 10.5755 18.266 10.2018 17.8179 9.68786C17.0959 9.21759 16.5762 8.46385 16.4112 7.58554H14.7246L14.7215 16.8264C14.6841 17.8605 13.8314 18.6921 12.789 18.6921C12.1417 18.6921 11.5722 18.3713 11.2174 17.8854C10.6012 17.5584 10.178 16.9137 10.178 16.1693C10.178 15.101 11.0463 14.232 12.1137 14.232C12.3128 14.232 12.5058 14.2663 12.6863 14.3223V12.5159C10.3959 12.5657 8.54736 14.4469 8.54736 16.7548C8.54736 17.8698 8.97992 18.8852 9.68633 19.6452C10.3741 20.1279 11.2143 20.4145 12.1168 20.4145C14.4539 20.4145 16.3583 18.5083 16.3583 16.1693Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12670_4220">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
