import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ReviewsNotFound as ReviewsNotFoundStyled,
  Subtext,
  Headline
} from './ReviewsNotFound.styles';

export function ReviewsNotFound({
  activeFiltersSet,
  path
}: {
  activeFiltersSet: boolean;
  path: string;
}) {
  return (
    <ReviewsNotFoundStyled>
      <Headline as="p" lookAlike="h3">
        <FormattedMessage id="COMPANYPROFILES.RATINGS.NOT_FOUND.HEADLINE" />
      </Headline>
      {activeFiltersSet && (
        <>
          <Subtext>
            <FormattedMessage id="COMPANYPROFILES.RATINGS.NOT_FOUND.SUBTEXT" />
          </Subtext>
          <Link href={path}>
            <FormattedMessage id="COMPANYPROFILES.RATINGS.NOT_FOUND.REMOVE_ALL_FILTERS" />
          </Link>
        </>
      )}
    </ReviewsNotFoundStyled>
  );
}
