interface ShopProfilePaginationInfo {
  next?: number;
  previous?: number;
}

export function getPaginationMetaLinks({
  activePage,
  maxPage,
  pageFullUrl
}: {
  activePage?: number;
  maxPage: number;
  pageFullUrl: string;
}) {
  const paginationInfo = getPaginationInfo({ activePage, maxPage });
  if (!paginationInfo) {
    return;
  }

  const previousPageHref = getPreviousPageHref(
    paginationInfo,
    pageFullUrl,
    maxPage,
    activePage
  );
  const nextPageHref = getNextPageHref(
    paginationInfo,
    pageFullUrl,
    maxPage,
    activePage
  );

  return { nextPageHref, previousPageHref };
}

export function getPreviousPageHref(
  paginationInfo: ShopProfilePaginationInfo,
  pageFullUrl: string,
  maxPage: number,
  activePage?: number
) {
  if (activePage === paginationInfo.previous || maxPage === 1) {
    return;
  }

  return paginationInfo.previous && paginationInfo.previous === 1
    ? pageFullUrl.replace(/(\?|&)page=([^&]*)/g, '')
    : pageFullUrl.replace(
        /(\?|&)page=([^&]*)/g,
        '$1page=' + paginationInfo.previous
      );
}

export function getNextPageHref(
  paginationInfo: ShopProfilePaginationInfo,
  pageFullUrl: string,
  maxPage: number,
  activePage?: number
) {
  if (activePage === paginationInfo.next || maxPage === 1) {
    return;
  }

  return paginationInfo.next && paginationInfo.next === 2
    ? pageFullUrl + (pageFullUrl.indexOf('?') < 0 ? '?' : '&') + 'page=2'
    : pageFullUrl.replace(
        /(\?|&)page=([^&]*)/g,
        '$1page=' + paginationInfo.next
      );
}

export function getPaginationInfo({
  activePage,
  maxPage
}: {
  activePage?: number;
  maxPage?: number;
}): ShopProfilePaginationInfo {
  if (
    !maxPage ||
    !activePage ||
    maxPage < 0 ||
    activePage < 0 ||
    activePage > maxPage
  ) {
    return {};
  }

  let nextPage = activePage + 1;
  if (nextPage > maxPage) {
    nextPage = activePage;
  }
  const previousPage = activePage - 1 || 1;

  return {
    next: nextPage,
    previous: previousPage
  };
}
