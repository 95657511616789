import styled from 'styled-components';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const TextLink = styled.a`
  color: ${colorsVariables.BLUE_700};
  transition: color 0.2s;
  cursor: pointer;

  :hover {
    color: ${colorsVariables.BLUE_600};
  }
`;

export const TextOverflowEllipsis = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
