import styled from 'styled-components';
import {
  colorsVariables,
  fontVariables,
  gutterVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { TextOverflowEllipsis } from 'styles/utils/text.styles';

export const Category = styled.a`
  display: inline-block;
  background: ${colorsVariables.WHITE};
  border-radius: 4px;
  max-width: 100%;
  margin-right: 8px;

  ${TextOverflowEllipsis};
`;

export const CategoryTag = styled.span`
  padding: ${gutterVariables.GUTTER_ONE_QUARTER} ${gutterVariables.GUTTER_HALF};
  background-color: ${colorsVariables.BLUE_50};
  color: ${colorsVariables.BLUE_600};
  font-size: ${fontVariables.SIZE_TEXT_XS};
  line-height: ${fontVariables.LINE_HEIGHT_XS};
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
`;
