import styled from 'styled-components';
import reviewInbox from './reviewInbox.svg';

// Implementation inspired by: https://medium.com/@ians/rendering-svgs-as-images-directly-in-react-a26615c45770

const encodedReviewInbox = Buffer.from(reviewInbox).toString('base64');

export const ReviewInbox = styled.div({
  background: `url("data:image/svg+xml;base64,${encodedReviewInbox}")`,
  backgroundRepeat: 'no-repeat'
});
