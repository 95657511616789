import styled from 'styled-components';
import { MainContentWrapper } from 'styles/Page.styles';
import {
  minWidth as minWidthGenerator,
  minWidth
} from 'helpers/buildMediaQueries';
import columnSizes from 'styles/column-sizes.variables';

const gridTemplateRows = {
  mobile: 'auto',
  desktop: 'auto minmax(0, auto)'
};

const gridTemplateAreas = {
  buildMobile: (
    isCertificateVisible: boolean,
    isOpenProfile: boolean,
    hasNoReviews?: boolean
  ) =>
    `${
      isCertificateVisible || isOpenProfile ? "'certificate-info'" : ''
    } ${hasNoReviews && isOpenProfile ? "'similar-companies'" : "'review-area' 'aside' 'similar-companies'"}`,
  buildDesktop: () => `'review-area aside'`,
  buildDesktopNoReviews: () => `'. aside'`
};

const gridTemplateMediaQueries = columnSizes
  .map(({ minWidth, mainContainerWidth, asideContainerWidth }) => {
    if (minWidth === 'xxl') {
      return minWidthGenerator(
        minWidth,
        `grid-template-columns: ${mainContainerWidth}px ${asideContainerWidth}px`
      );
    }
  })
  .join('');
export const MainContentWrapperStyled = styled(MainContentWrapper)<{
  isCertificateInfoVisible: boolean;
  isSummaryVisible: boolean;
  isOpenProfile: boolean;
  hasNoReviews: boolean;
}>`
  display: grid;
  column-gap: 0;
  grid-template-columns: minmax(0, auto);
  grid-template-rows: ${gridTemplateRows.mobile};
  grid-template-areas: ${({
    isCertificateInfoVisible,
    isOpenProfile,
    hasNoReviews
  }) =>
    gridTemplateAreas.buildMobile(
      isCertificateInfoVisible,
      isOpenProfile,
      hasNoReviews
    )};

  ${(props) =>
    minWidth(
      'xxl',
      `
      column-gap: 32px;
      grid-template-rows: ${gridTemplateRows.desktop};
      grid-template-areas: ${props.hasNoReviews ? gridTemplateAreas.buildDesktopNoReviews() : gridTemplateAreas.buildDesktop()};`
    )}

  ${gridTemplateMediaQueries};

  aside {
    grid-area: aside;
    display: grid;
    grid-gap: 24px;
    align-content: flex-start;
    grid-template-columns: minmax(0, 1fr);
  }
`;
