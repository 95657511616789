export function mapTldToLanguageCode(
  topLevelDomain: string,
  firstContentfulSegment: string
): string {
  switch (topLevelDomain) {
    case 'be':
      return firstContentfulSegment;

    case 'ch':
      if (!firstContentfulSegment) {
        return 'de';
      }

      switch (firstContentfulSegment.toLowerCase()) {
        case 'bewertung':
          return 'de';
        case 'evaluation':
          return 'fr';
        case 'valutazione-del-negozio':
          return 'it';
        default:
          return 'de';
      }

    case 'eu':
    case 'co.uk':
    case 'com':
      return 'en';

    case 'at':
      return 'de';

    case 'se':
      return 'sv';

    case 'dk':
      return 'da';

    case 'cz':
      return 'cs';

    default:
      return topLevelDomain;
  }
}
