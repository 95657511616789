import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';
import { Profile, ReviewWidgetState } from 'profile-api';

export function reviewsStateIs(
  value?: Profile,
  expectedStatus?: ReviewWidgetState | ReviewsVisibilityState
): boolean {
  if (!value || !expectedStatus) {
    return false;
  }

  if (value.memberDetails?.reviewState === expectedStatus) {
    return true;
  }

  if (
    (value.profileType === 'non-member' ||
      value.profileType === 'free-account') &&
    value.reviewStatistic.yearlyReviewCount > 0
  ) {
    return true;
  }

  const isWidget =
    [ReviewWidgetState.Widget, ReviewWidgetState.WidgetSimplified].indexOf(
      value.memberDetails?.reviewState!
    ) !== -1;

  /** rr_averageRatingCount only includes verified ratings younger than one year */
  if (expectedStatus === ReviewsVisibilityState.SummaryIsInvisible) {
    return !isWidget || value.reviewStatistic.yearlyReviewCount === 0;
  }

  if (expectedStatus === ReviewsVisibilityState.SummaryIsVisible) {
    return isWidget && value.reviewStatistic.yearlyReviewCount !== 0;
  }

  /** rr_countStatements_all includes imported and verified ratings younger than one year */
  if (expectedStatus === ReviewsVisibilityState.ReviewsListIsInvisible) {
    return (
      !isWidget ||
      value.reviewStatistic?.yearlyReviewCount === 0 ||
      reviewsStateIs(value, ReviewsVisibilityState.SummaryIsInvisible)
    );
  }

  if (expectedStatus === ReviewsVisibilityState.ReviewsListIsVisible) {
    return (
      isWidget &&
      value.reviewStatistic?.yearlyReviewCount !== 0 &&
      reviewsStateIs(value, ReviewsVisibilityState.SummaryIsVisible)
    );
  }

  /** rr_overallRatingCount includes imported and verified ratings no matter how old they are */
  if (expectedStatus === ReviewsVisibilityState.OverallCountIsInvisible) {
    return (
      !isWidget ||
      value.reviewStatistic?.allTimeReviewCount === 0 ||
      reviewsStateIs(value, ReviewsVisibilityState.SummaryIsInvisible)
    );
  }

  if (expectedStatus === ReviewsVisibilityState.OverallCountIsVisible) {
    return (
      isWidget &&
      value.reviewStatistic?.allTimeReviewCount !== 0 &&
      reviewsStateIs(value, ReviewsVisibilityState.SummaryIsVisible)
    );
  }

  return true;
}
